import ActiveSwitch from "components/Inputs/ActiveSwitch";
import { CardLoading } from "components/Inputs/Loading";
import { alerts } from "helpers/Alerts";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import TitleService from "services/title.service";

// components
import { h6style } from "../Cards/style";

export default function CardTitleEdit(props) {
  const [titleInputs, setTitleInput] = useState([]);
  const [titleId, setTitleId] = useState("");
  const [message, setMessage] = useState("");
  const [active, setActive] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const mountedRef = useRef(true);

  let { owner, company } = useParams();

  useEffect(() => {
    TitleService.getTitle(owner).then(
      (res) => {
        if (!mountedRef.current) return null;
        setTitleInput(res.data.title);
        setTitleId(res.data.id);
        setActive(res.data.active);
        setDisabled(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setDisabled(false);
      }
    );
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);

  let handleChange = (i, e) => {
    let newTitles = [...titleInputs];
    newTitles[i].value = e.target.value;
    setTitleInput(newTitles);
  };

  let addFormFields = () => {
    if (titleInputs.length < 5) setTitleInput([...titleInputs, { value: "" }]);
    else setMessage(alerts.titleChangerMax);
  };

  const removeFormFields = (i) => {
    const newTitles = [...titleInputs];
    newTitles.splice(i, 1);
    setTitleInput(newTitles);
  };

  function titleChangerSubmit(e) {
    e.preventDefault();
    setDisabled(true);
    if (titleInputs.length < 2) {
      setMessage(alerts.titleChangerMin);
    } else {
      setDisabled(true);
      TitleService.updateTitle(titleInputs, active, owner, titleId).then(
        (res) => {
          setMessage(alerts.positive);
          setDisabled(false);
          setTimeout(() => {
            setDisabled(false);
            setMessage("");
          }, 2000);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setMessage(resMessage);
          setDisabled(false);
        }
      );
    }
  }
  return (
    <>
      <CardLoading isShow={disabled} />
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <h6 className="text-blueGray-700 text-xl font-bold" style={h6style}>
          {company}
        </h6>
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold" style={h6style}>
              <ActiveSwitch
                disabled={disabled}
                onColor="#0ea5e9"
                checked={active}
                handleToggle={(e) => setActive(e.target.checked)}
              />{" "}
              Title Changer
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={titleChangerSubmit}>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Titles
            </h6>
            <div className="flex flex-wrap">
              {titleInputs.map((title, index) => (
                <div key={index} className="w-full lg:w-8/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Title {index + 1}
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={title.value}
                      onChange={(e) => handleChange(index, e)}
                      required
                      maxLength={25}
                      disabled={disabled}
                    />
                    <span
                      className="h-full leading-snug font-normal absolute text-center text-red-700 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3"
                      onClick={() => removeFormFields(index)}
                      style={{ cursor: "pointer", color: "red" }}
                    >
                      <i className="fas fa-minus"></i>
                    </span>
                  </div>
                </div>
              ))}
            </div>

            <button
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => addFormFields()}
            >
              <i className="fas fa-plus"></i>
            </button>
            <div style={{ display: "flex", alignItems: "flex-end" }}>
              <button
                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="submit"
                disabled={disabled}
              >
                Save
              </button>
              <div
                style={{ height: "32px" }}
                dangerouslySetInnerHTML={{ __html: message }}
              ></div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
