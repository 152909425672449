import api from "./api";

const create = (
  iframeHtmlHome,
  iframeHtmlCollection,
  iframeHtmlProductDetail,
  iframeHtmlCart,
  active,
  owner
) => {
  return api.post("/preview-options", {
    iframeHtmlHome,
    iframeHtmlCollection,
    iframeHtmlProductDetail,
    iframeHtmlCart,
    active,
    owner,
  });
};

const update = (
  iframeHtmlHome,
  iframeHtmlCollection,
  iframeHtmlProductDetail,
  iframeHtmlCart,
  active,
  owner,
  id
) => {
  return api.patch(`/preview-options/${owner}`, {
    iframeHtmlHome,
    iframeHtmlCollection,
    iframeHtmlProductDetail,
    iframeHtmlCart,
    active,
    owner,
    id,
  });
};
const updateActive = (active, owner, id) => {
  return api.patch(`/preview-options/status/${owner}`, {
    active,
    id,
  });
};
const get = (owner) => {
  return api.get(`/preview-options/${owner}`, {
    onDownloadProgress(progress) {
      console.log("download progress:", progress);
    },
  });
};
const getAll = (limit, page, sortBy, active, company, isEmailVerified) => {
  return api.get(`/preview-options`, {
    params: { limit, page, sortBy, active, company, isEmailVerified },
  });
};
const Services = {
  create,
  update,
  get,
  getAll,
  updateActive,
};

export default Services;
