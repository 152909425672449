import React from "react";

// components

import CardSnowEffects from "components/AdminCards/CardSnowEffects";

export default function SnowEffects() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <CardSnowEffects />
        </div>
      </div>
    </>
  );
}
