export const alerts = {
  positive: "Updated!",
  negative: "Error ! The update is not complete.",
  addedUser: "New company added!",
  password: "Passwords do not match.",
  opportunityMax: "You can add max 7 banners.",
  opportunityMin: "You must add min 1 banner",
  spinWheelMax: "You can add max 8 pieces",
  spinWheelMin: "You must add min 4 pieces",
  spinWheelProbability: "Total probability must 100",
  storiesMax: "You can add max 30 stories.",
  storiesMin: "You must add min 2 stories.",
  titleChangerMax: "You can add max 5 titles.",
  titleChangerMin: "You must add min 2 titles.",
  surveryAnswersMax: "You can add max 20 answers.",
  loginSuccess: "Login successful. You are being redirected to the panel...",
};
