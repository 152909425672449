import api from "./api";

const createOpeningPopup = (
  url,
  image,
  time,
  visitor,
  blockedURLInputs,
  urlAllowOrBlock,
  active,
  owner
) => {
  return api.post("/openingpopup", {
    url,
    active,
    image,
    time,
    visitor,
    blockedURLInputs,
    urlAllowOrBlock,
    owner,
  });
};

const updateOpeningPopup = (
  url,
  image,
  time,
  visitor,
  blockedURLInputs,
  urlAllowOrBlock,
  active,
  owner,
  id
) => {
  return api.patch(`/openingpopup/${owner}`, {
    url,
    active,
    image,
    time,
    visitor,
    blockedURLInputs,
    urlAllowOrBlock,
    owner,
    id,
  });
};
const updateActive = (active, owner, id) => {
  return api.patch(`/openingpopup/status/${owner}`, {
    active,
    id,
  });
};
const getOpeningPopup = (owner) => {
  return api.get(`/openingpopup/${owner}`);
};
const getAll = (limit, page, sortBy, active, company, isEmailVerified) => {
  return api.get(`/openingpopup`, {
    params: { limit, page, sortBy, active, company, isEmailVerified },
  });
};
const OpeningPopupService = {
  createOpeningPopup,
  updateOpeningPopup,
  getOpeningPopup,
  getAll,
  updateActive,
};

export default OpeningPopupService;
