import React from "react";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";

function MyComponent({ val, handleChange }) {
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };
  const { quill, quillRef } = useQuill({ modules });
  React.useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(val);
      quill.on("text-change", (delta, oldDelta, source) => {
        handleChange(quillRef.current.firstChild.innerHTML);
      });
    }
    // eslint-disable-next-line
  }, [quill]);
  return <div ref={quillRef} />;
}
export default React.memo(MyComponent);
