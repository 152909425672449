import React from "react";
import PropTypes from "prop-types";
import "./style.css";
import decodeHTMLEntities from "helpers/htmlDecode";

const PreviewIframe = ({ onChange, srcDoc }) => {
  return (
    <div style={{ display: "flex", width: "100%" }}>
      <textarea
        style={{ width: "25%", height: "500px" }}
        onChange={onChange}
        value={srcDoc}
      ></textarea>
      <iframe
        className="preview"
        id="preview"
        width={"75%"}
        height={"500px"}
        srcDoc={decodeHTMLEntities(srcDoc)}
        title="preview"
        loading="lazy"
      ></iframe>
    </div>
  );
};

export default PreviewIframe;
PreviewIframe.defaultProps = {};
PreviewIframe.propTypes = {
  onChange: PropTypes.func,
  srcDoc: PropTypes.string,
};
