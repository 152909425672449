import ActiveSwitch from "components/Inputs/ActiveSwitch";
import ImageRadioButton from "components/Inputs/ImageRadioButton";
import { CardLoading } from "components/Inputs/Loading";
import { alerts } from "helpers/Alerts";
import React, { useEffect, useRef, useState } from "react";
import { ChromePicker } from "react-color";
import CssFilterConverter from "css-filter-converter";

import AuthService from "services/auth.service";
import WatchedBoughtService from "services/watchedbougth.service";

// components
import { h6style } from "./style";
import { socialProofStyle } from "components/previewStyle";
export default function CardWatchedBought(props) {
  const [title, setTitle] = useState("");
  const [fontSize, setFontSize] = useState("");
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const [location, setLocation] = useState(false);
  const [watchedBoughtId, setWatchedBoughtId] = useState("");
  const [popupDelay, setPopupDelay] = useState("");
  const [selector, setSelector] = useState("");
  const [position, setPosition] = useState("");
  const [textIcon, setTextIcon] = useState("");
  const [textColor, setTextColor] = useState("");
  const [iconColor, setIconColor] = useState({ hex: "", filter: "" });
  const [bgColor, setBgColor] = useState("");
  const [message, setMessage] = useState("");
  const [active, setActive] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const currentUser = AuthService.getCurrentUser();
  const mountedRef = useRef(true);

  useEffect(() => {
    WatchedBoughtService.getWatchedBought(currentUser.user.id).then(
      (res) => {
        if (!mountedRef.current) return null;
        setTitle(res.data.title);
        setFontSize(res.data.title);
        setMin(res.data.min);
        setMax(res.data.max);
        setLocation(res.data.location);
        setFontSize(res.data.fontSize);
        setPopupDelay(res.data.popupDelay);
        setSelector(res.data.selector);
        setPosition(res.data.position);
        setIconColor(res.data.iconColor);
        setTextIcon(res.data.textIcon);
        setTextColor(res.data.textColor);
        setBgColor(res.data.bgColor);
        setWatchedBoughtId(res.data.id);
        setActive(res.data.active);
        setDisabled(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setDisabled(false);
      }
    );
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);

  function watchedBoughtSubmit(e) {
    e.preventDefault();
    setDisabled(true);
    WatchedBoughtService.updateWatchedBought(
      title,
      fontSize,
      min,
      max,
      location,
      popupDelay,
      selector,
      position,
      iconColor,
      textIcon,
      textColor,
      bgColor,
      active,
      currentUser.user.id,
      watchedBoughtId
    ).then(
      (res) => {
        setMessage(alerts.positive);
        setDisabled(false);
        setTimeout(() => {
          setMessage("");
        }, 2000);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setDisabled(false);
        WatchedBoughtService.createWatchedBought(
          title,
          fontSize,
          min,
          max,
          location,
          popupDelay,
          selector,
          position,
          iconColor,
          textIcon,
          textColor,
          bgColor,
          active,
          currentUser.user.id
        ).then(
          (res) => {
            setMessage(alerts.positive);
            setDisabled(false);
            setWatchedBoughtId(res.data.id);
            setTimeout(() => {
              setMessage("");
            }, 2000);
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            setMessage(resMessage);
            setDisabled(false);
          }
        );
      }
    );
  }
  function setHexToFilter(hex) {
    const result = CssFilterConverter.hexToFilter(hex);
    setIconColor({ hex, filter: result.color });
  }

  const srcDoc = `
    <style>
      ${socialProofStyle}
    </style>
    <div class="iyosa-sp-box" style="background-color: ${bgColor}; font-size: ${fontSize}px; color: ${textColor};">
      <img src="https://cdn.iyosa.com/src/assets/img/${textIcon}" width="20" height="20" style="margin-right: 5px; filter:${
    iconColor ? iconColor.filter : ""
  };" />
      ${title}
    </div>
  `;
  return (
    <>
      <CardLoading isShow={disabled} />
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold" style={h6style}>
              <ActiveSwitch
                disabled={disabled}
                onColor="#0ea5e9"
                checked={active}
                handleToggle={(e) => setActive(e.target.checked)}
              />{" "}
              How Many People Viewed/Bought
            </h6>
          </div>
        </div>

        <div
          className="w-full lg:w-12/12 px-4"
          style={{ position: "sticky", top: 0, zIndex: "999999" }}
        >
          <iframe
            srcDoc={srcDoc}
            title="Preview"
            width={"100%"}
            height={"80px"}
          />
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={watchedBoughtSubmit}>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Settings
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-8/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Selector
                  </label>
                  <input
                    type="text"
                    className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={selector}
                    onChange={(e) => setSelector(e.target.value)}
                    name="selector"
                    required
                    disabled={disabled}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Position
                  </label>
                  <select
                    type="text"
                    className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                    name="position"
                    required
                    disabled={disabled}
                  >
                    <option value="beforebegin">beforebegin</option>
                    <option value="afterbegin">afterbegin</option>
                    <option value="afterend">afterend</option>
                    <option value="beforeend">beforeend</option>
                  </select>
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    How many people viewed/bought
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                    disabled={disabled}
                  />
                  <span className="input-description">
                    "%b" is random number.
                  </span>
                </div>
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Text Icon
                  </label>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    <ImageRadioButton
                      name={"sales_growth.png"}
                      size={"64px"}
                      image="https://iyosa.com/img/sales_growth.png"
                      value="sales_growth.png"
                      checked={textIcon === "sales_growth.png"}
                      handleToggle={(e) => setTextIcon(e.target.value)}
                      filter={iconColor ? iconColor.filter : ""}
                    />
                    <ImageRadioButton
                      name={"daylight.png"}
                      size={"64px"}
                      image="https://iyosa.com/img/daylight.png"
                      value="daylight.png"
                      checked={textIcon === "daylight.png"}
                      handleToggle={(e) => setTextIcon(e.target.value)}
                      filter={iconColor ? iconColor.filter : ""}
                    />
                    <ImageRadioButton
                      name={"fire.png"}
                      size={"64px"}
                      image="https://iyosa.com/img/fire.png"
                      value="fire.png"
                      checked={textIcon === "fire.png"}
                      handleToggle={(e) => setTextIcon(e.target.value)}
                      filter={iconColor ? iconColor.filter : ""}
                    />
                    <ImageRadioButton
                      name={"eye.png"}
                      size={"64px"}
                      image="https://iyosa.com/img/eye.png"
                      value="eye.png"
                      checked={textIcon === "eye.png"}
                      handleToggle={(e) => setTextIcon(e.target.value)}
                      filter={iconColor ? iconColor.filter : ""}
                    />
                    <ImageRadioButton
                      name={"discountRed.svg"}
                      size={"64px"}
                      image="https://iyosa.com/img/discountRed.svg"
                      value="discountRed.svg"
                      checked={textIcon === "discountRed.svg"}
                      handleToggle={(e) => setTextIcon(e.target.value)}
                      filter={iconColor ? iconColor.filter : ""}
                    />
                    <ImageRadioButton
                      name={"free-shipping.png"}
                      size={"64px"}
                      image="https://iyosa.com/img/free-shipping.png"
                      value="free-shipping.png"
                      checked={textIcon === "free-shipping.png"}
                      handleToggle={(e) => setTextIcon(e.target.value)}
                      filter={iconColor ? iconColor.filter : ""}
                    />
                    <ImageRadioButton
                      name={"none"}
                      size={"64px"}
                      image="https://iyosa.com/img/no-icon.png"
                      value="none"
                      checked={textIcon === "none"}
                      handleToggle={(e) => setTextIcon(e.target.value)}
                      filter={iconColor ? iconColor.filter : ""}
                      text={"No Icon"}
                    />
                  </div>
                </div>
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Icon Color
                  </label>
                  <ChromePicker
                    color={iconColor ? iconColor.hex : ""}
                    onChangeComplete={(color, event) =>
                      setHexToFilter(color.hex)
                    }
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Text Color
                  </label>
                  <ChromePicker
                    color={textColor}
                    onChangeComplete={(color, event) => setTextColor(color.hex)}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Background Color
                  </label>
                  <ChromePicker
                    color={bgColor}
                    onChangeComplete={(color, event) => setBgColor(color.hex)}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Font Size (px)
                  </label>
                  <input
                    type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={fontSize}
                    onChange={(e) => setFontSize(e.target.value)}
                    required
                    disabled={disabled}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Minimum Random Number
                  </label>
                  <input
                    type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={min}
                    onChange={(e) => setMin(e.target.value)}
                    required
                    disabled={disabled}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Maximum Random Number
                  </label>
                  <input
                    type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={max}
                    onChange={(e) => setMax(e.target.value)}
                    required
                    disabled={disabled}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Location
                  </label>
                  <input
                    type="radio"
                    id="true"
                    name="location"
                    value="true"
                    checked={location}
                    onChange={(e) => setLocation(true)}
                  />
                  <label htmlFor="true"> Text</label>
                  <br />
                  <input
                    type="radio"
                    id="false"
                    name="location"
                    value="false"
                    checked={!location}
                    onChange={(e) => setLocation(false)}
                  />
                  <label htmlFor="false"> Popup</label>
                </div>
                {location === false ? (
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Popup Delay
                    </label>
                    <input
                      type="number"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={popupDelay}
                      onChange={(e) => setPopupDelay(e.target.value)}
                      required
                      disabled={disabled}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <button
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="submit"
              disabled={disabled}
            >
              Save
            </button>
            {message}
          </form>
        </div>
      </div>
    </>
  );
}
