import { CDN } from "./assets/services.js";

//import css from "./style.css" assert { type: "css" };
const freeShipping = (doc, props) => {
  //doc.contentWindow.document.adoptedStyleSheets = [...doc.contentWindow.document.adoptedStyleSheets, css];

  const css = doc.contentWindow.document.createElement("link");
  css.href = CDN + "/src/scenarios/freeshipping/style.css";
  css.rel = "stylesheet";
  css.type = "text/css";
  css.media = "all";
  doc.contentWindow.document.head.appendChild(css);
  if (doc.contentWindow.document.querySelector(".iyosa-freeshipping-bar"))
    return;
  let bar = doc.contentWindow.document.createElement("div");
  bar.className = "iyosa-freeshipping-bar";
  bar.style.borderRadius = props.borderRadius + "px";
  bar.style.color = props.color;
  bar.style.fontSize = props.fontSize + "px";
  bar.style.backgroundColor = props.backgroundColor;
  let level = doc.contentWindow.document.createElement("div");
  level.className = "iyosa-freeshipping-level";
  level.style.backgroundColor = props.barBgColor;
  level.style.display = "block";
  let text = doc.contentWindow.document.createElement("div");
  text.className = "iyosa-freeshipping-text";

  let fsAlert = doc.contentWindow.document.createElement("strong");
  fsAlert.textContent =
    props.freeShippingMsg !== undefined
      ? props.freeShippingMsg
      : "Kargon Bedava!";

  let selector = doc.contentWindow.document.querySelector(props.selector);
  if (selector !== null) {
    setInterval(() => {
      let total = searchInClassName(props.totalSelector, props.totalFind, doc);
      let msgReplace = props.title.replace(
        "%b",
        " <strong>&nbsp;" +
          parseFloat(props.limit - total).toFixed(2) +
          "&nbsp;</strong> "
      );
      let percent = (total * 100) / props.limit;
      level.style.width = `${percent}%`;
      var message = props.limit - total < 0 ? fsAlert.textContent : msgReplace;
      text.innerHTML = message;
    }, 1000);

    bar.appendChild(text);
    bar.appendChild(level);

    selector.insertAdjacentElement(props.position, bar);
  }
};
const searchInClassName = (selector, search, doc) => {
  if (selector && search && doc) {
    var filter = search.toLowerCase();
    var nodes = doc.contentWindow.document.querySelectorAll(selector);
    // eslint-disable-next-line
    var regex = /[\d|,|.|\+]+/g;
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].innerText.toLowerCase().includes(filter)) {
        var a = nodes[i].innerText.match(regex)[0];
        var matches;
        if (a[a.length - 3] === ",")
          matches = nodes[i].innerText
            .replace(".", "")
            .replace(",", ".")
            .match(regex);
        else {
          matches = nodes[i].innerText.replace(",", "").match(regex);
        }
        return parseFloat(matches[0]);
      }
    }
  }
  return 0;
};
export { freeShipping };
