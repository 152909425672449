import React from "react";

// components

import CardPreviewOptionsEdit from "components/AdminCards/CardPreviewOptionsEdit";

export default function PreviewOptionsEdit() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <CardPreviewOptionsEdit />
        </div>
      </div>
    </>
  );
}
