import api from "./api";

const createTopBanner = (
  title,
  countdown,
  color,
  bgColor,
  countColor,
  countBgColor,
  countBorderColor,
  countBorderRadius,
  countdownMsgLocation,
  countdownLocation,
  url,
  btnText,
  btnLocation,
  btnDistance,
  btnBorderColor,
  btnBgColor,
  btnColor,
  titleFontSize,
  countdownFontSize,
  btnDisplay,
  btnBorderRadius,
  btnFontSize,
  countdownDisplay,
  dayBox,
  visitor,
  visibilityStart,
  visibilityEnd,
  cbNextDay,
  alwaysShow,
  showBodyTop,
  selector,
  blockedUrl,
  position,
  showAllPage,
  startTime,
  countdownTexts,
  allowURLInputs,
  sliderDuration,
  bgImgDesktop,
  bgImgMobile,
  bgPosition,
  bgRepeat,
  bgSize,
  pAbsolute,
  active,
  owner
) => {
  return api.post("/topbanners", {
    title,
    countdown,
    color,
    bgColor,
    countColor,
    countBgColor,
    countBorderColor,
    countBorderRadius,
    countdownMsgLocation,
    countdownLocation,
    url,
    btnText,
    btnLocation,
    btnDistance,
    btnBorderColor,
    btnBgColor,
    btnColor,
    titleFontSize,
    countdownFontSize,
    btnDisplay,
    btnBorderRadius,
    btnFontSize,
    countdownDisplay,
    dayBox,
    visitor,
    visibilityStart,
    visibilityEnd,
    cbNextDay,
    alwaysShow,
    showBodyTop,
    selector,
    blockedUrl,
    position,
    showAllPage,
    startTime,
    countdownTexts,
    allowURLInputs,
    sliderDuration,
    bgImgDesktop,
    bgImgMobile,
    bgPosition,
    bgRepeat,
    bgSize,
    pAbsolute,
    active,
    owner,
  });
};

const updateTopBanner = (
  title,
  countdown,
  color,
  bgColor,
  countColor,
  countBgColor,
  countBorderColor,
  countBorderRadius,
  countdownMsgLocation,
  countdownLocation,
  url,
  btnText,
  btnLocation,
  btnDistance,
  btnBorderColor,
  btnBgColor,
  btnColor,
  titleFontSize,
  countdownFontSize,
  btnDisplay,
  btnBorderRadius,
  btnFontSize,
  countdownDisplay,
  dayBox,
  visitor,
  visibilityStart,
  visibilityEnd,
  cbNextDay,
  alwaysShow,
  showBodyTop,
  selector,
  blockedUrl,
  position,
  showAllPage,
  startTime,
  countdownTexts,
  allowURLInputs,
  sliderDuration,
  bgImgDesktop,
  bgImgMobile,
  bgPosition,
  bgRepeat,
  bgSize,
  pAbsolute,
  active,
  owner,
  id
) => {
  return api.patch(`/topbanners/${owner}`, {
    title,
    countdown,
    color,
    bgColor,
    countColor,
    countBgColor,
    countBorderColor,
    countBorderRadius,
    countdownMsgLocation,
    countdownLocation,
    url,
    btnText,
    btnLocation,
    btnDistance,
    btnBorderColor,
    btnBgColor,
    btnColor,
    titleFontSize,
    countdownFontSize,
    btnDisplay,
    btnBorderRadius,
    btnFontSize,
    countdownDisplay,
    dayBox,
    visitor,
    visibilityStart,
    visibilityEnd,
    cbNextDay,
    alwaysShow,
    showBodyTop,
    selector,
    blockedUrl,
    position,
    showAllPage,
    startTime,
    countdownTexts,
    allowURLInputs,
    sliderDuration,
    bgImgDesktop,
    bgImgMobile,
    bgPosition,
    bgRepeat,
    bgSize,
    pAbsolute,
    active,
    owner,
    id,
  });
};

const updateActive = (active, owner, id) => {
  return api.patch(`/topbanners/status/${owner}`, {
    active,
    id,
  });
};

const getTopBanner = (owner) => {
  return api.get(`/topbanners/${owner}`);
};
const getAll = (limit, page, sortBy, active, company, isEmailVerified) => {
  return api.get(`/topbanners`, {
    params: { limit, page, sortBy, active, company, isEmailVerified },
  });
};
const TopBannerService = {
  createTopBanner,
  updateTopBanner,
  getTopBanner,
  getAll,
  updateActive,
};

export default TopBannerService;
