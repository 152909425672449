export const freeShippingStyle = `
.iyosa-freeshipping-bar {
  position: relative;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  overflow: hidden;
  margin: 5px 0px;
  padding: 0 !important;
  flex-direction: column;
  flex-wrap: nowrap;
}
.iyosa-freeshipping-text {
  margin: auto;
  position: relative;
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.iyosa-freeshipping-text strong{
  font-weight: 600;
}
.iyosa-freeshipping-level {
  position: relative;
  display: block;
  height: 10px;
  animation: run-progress 1s;
  max-width: 100%;
  transition: all 0.8s ease-in-out;
  opacity: 0.6;
  margin:0 20px 10px;
}

.iyosa-freeshipping-level:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /*
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.3) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0.3) 75%,
    transparent 75%
  );
  background-size: 75px 75px;
  */
}

@keyframes run-progress {
  0% {
    width: 0;
  }
}

/*Mobile devices*/
@media screen and (max-width: 480px) {
  .iyosa-freeshipping-text {
    font-size: 10px;
    line-height: normal;
  }
}
/*Tablet devices*/
@media screen and (min-width: 481px) and (max-width: 768px) {
  .iyosa-freeshipping-text {
    font-size: 10px;
  }
}
`;
export const smartAppBannerStyle = `
body{
  margin:0;
  padding:0;
  font-family:calibri;
}
a{
  text-decoration:none;
}
*{
  box-sizing:border-box;
}
.iyosa-smart-app-banner-container{
  background-color: #f2f2f2;
  height: 100px;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding: 8px;
  display: flex;
  justify-content: space-around;
  z-index: 999999999;
}
.iyosa-stars {
  display: inline-block;
  font-size: 20px;
  font-family: Times;
  line-height: 1;
  align-items: center;
}
.iyosa-stars::before {
  content: "★★★★★";
  letter-spacing: 3px;
  background: linear-gradient(90deg, #fc0 100%, #fc0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.iyosa-smart-app-close,
.iyosa-smart-app-logo,
.iyosa-smart-app-button{
  display: flex;
  align-items: center;
}
.iyosa-smart-app-logo{
  width: 75px;
}
.iyosa-smart-app-logo img{
  width: 100%;
}
.iyosa-smart-app-close{
  padding: 10px;
  font-size: 30px;
  font-weight: 600;
  color: #adadad;
  cursor:pointer;
}
.iyosa-smart-app-button a {
  width: 100%;
  padding: 6px;
  color: #ffffff;
  font-weight: 600;
  border-radius: 3px;
  font-size: 15px;
}
.iyosa-smart-app-desc {
  font-size: 12px;
}
.iyosa-smart-app-content{
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  justify-content:center;
}
.iyosa-smart-app-title{
  font-weight: 600;
  font-size: 15px;
}
`;
export const socialProofStyle = `
.iyosa-sp-box {
  font-family:Calibri;
  display: inline-flex;
  padding: 8px 18px;
  border-radius: 5px;
  align-items: center;
}
.iyosa-sp-popup {
  z-index: 999;
  width: 250px;
  padding: 10px;
  position: fixed;
  bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 10px;
  text-align: center;
  -webkit-animation: popup 3s forwards;
  -webkit-animation-iteration-count: 1;
  animation: popup 3s forwards;
  animation-iteration-count: 1;
  -webkit-box-shadow: -4px -2px 15px -6px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: -4px -2px 15px -6px rgba(0, 0, 0, 0.5);
  box-shadow: -4px -2px 15px -6px rgba(0, 0, 0, 0.5);
  right: 0px;
}
@keyframes popup {
  0% {
    right: 0px;
  }
  100% {
    right: -250px;
  }
}
@-webkit-keyframes popup {
  0% {
    right: 0px;
  }
  100% {
    right: -250px;
  }
}
/*Mobile devices*/
@media screen and (max-width: 480px) {
  .iyosa-sp-box,
  .iyosa-sp-popup {
    font-size: 12px !important;
  }
}

`;
export const storiesStyle = `
.splide__slide {
  text-align: center;
}
.splide__slide a img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.splide__arrow--prev {
  height: 100% !important;
  left: -40px !important;
  border-radius: 0 !important;
  background: linear-gradient(
    -90deg,
    rgba(255, 255, 255, 0.01) 10%,
    white
  ) !important;
}
.splide__arrow--next {
  height: 100% !important;
  right: -40px !important;
  border-radius: 0 !important;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.01) 10%,
    white
  ) !important;
}
.splide__arrow {
  width: 6em !important;
}
@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%,
  100% {
    background-position: 140px;
  }
}
@keyframes shine-avatar {
  0% {
    background-position: -32px;
  }
  40%,
  100% {
    background-position: 208px;
  }
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}
.splide__list {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
  transform-style: preserve-3d;
  justify-content: center;
}
.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}
.splide__pagination {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  pointer-events: none;
}
.splide__pagination li {
  display: inline-block;
  line-height: 1;
  list-style-type: none;
  margin: 0;
  pointer-events: auto;
}
.splide__progress__bar {
  width: 0;
}
.splide {
  outline: none;
  position: relative;
  visibility: hidden;
}
.splide.is-initialized,
.splide.is-rendered {
  visibility: visible;
}
.splide__slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-sizing: border-box;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  list-style-type: none !important;
  margin: 0;
  outline: none;
  position: relative;
}
.splide__slide img {
  vertical-align: bottom;
}
.splide__slider {
  position: relative;
}
.splide__spinner {
  border: 2px solid #999;
  border-left-color: transparent;
  border-radius: 50%;
  bottom: 0;
  display: inline-block;
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}
.splide__slide.is-loading .splide__spinner {
  animation: splide-loading 1s linear infinite;
}
.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0;
}
@keyframes splide-loading {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}
.splide--draggable > .splide__slider > .splide__track,
.splide--draggable > .splide__track {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.splide--fade > .splide__slider > .splide__track > .splide__list,
.splide--fade > .splide__track > .splide__list {
  display: block;
}
.splide--fade
  > .splide__slider
  > .splide__track
  > .splide__list
  > .splide__slide,
.splide--fade > .splide__track > .splide__list > .splide__slide {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 0;
}
.splide--fade
  > .splide__slider
  > .splide__track
  > .splide__list
  > .splide__slide.is-active,
.splide--fade > .splide__track > .splide__list > .splide__slide.is-active {
  opacity: 1;
  position: relative;
  z-index: 1;
}
.splide--rtl {
  direction: rtl;
}
.splide--ttb.is-active > .splide__slider > .splide__track > .splide__list,
.splide--ttb.is-active > .splide__track > .splide__list {
  display: block;
}
.splide__arrow {
  -ms-flex-align: center;
  align-items: center;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  height: 2em;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0.7;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2em;
  z-index: 1;
}
.splide__arrow svg {
  fill: #000;
  height: 1.2em;
  width: 1.2em;
}
.splide__arrow:hover {
  opacity: 0.9;
}
.splide__arrow:focus {
  outline: none;
}
.splide__arrow--prev {
  left: 1em;
}
.splide__arrow--prev svg {
  transform: scaleX(-1);
}
.splide__arrow--next {
  right: 1em;
}
.splide__pagination {
  bottom: 0.5em;
  left: 0;
  padding: 0 1em;
  position: absolute;
  right: 0;
  z-index: 1;
}
.splide__pagination__page {
  background: #ccc;
  border: 0;
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  margin: 3px;
  opacity: 0.7;
  padding: 0;
  transition: transform 0.2s linear;
  width: 8px;
}
.splide__pagination__page.is-active {
  background: #fff;
  transform: scale(1.4);
}
.splide__pagination__page:hover {
  cursor: pointer;
  opacity: 0.9;
}
.splide__pagination__page:focus {
  outline: none;
}
.splide__progress__bar {
  background: #ccc;
  height: 3px;
}
.splide--nav
  > .splide__slider
  > .splide__track
  > .splide__list
  > .splide__slide,
.splide--nav > .splide__track > .splide__list > .splide__slide {
  border: 3px solid transparent;
  cursor: pointer;
}
.splide--nav
  > .splide__slider
  > .splide__track
  > .splide__list
  > .splide__slide.is-active,
.splide--nav > .splide__track > .splide__list > .splide__slide.is-active {
  border: 3px solid #000;
}
.splide--nav
  > .splide__slider
  > .splide__track
  > .splide__list
  > .splide__slide:focus,
.splide--nav > .splide__track > .splide__list > .splide__slide:focus {
  outline: none;
}
.splide--rtl > .splide__arrows .splide__arrow--prev,
.splide--rtl
  > .splide__slider
  > .splide__track
  > .splide__arrows
  .splide__arrow--prev,
.splide--rtl > .splide__track > .splide__arrows .splide__arrow--prev {
  left: auto;
  right: 1em;
}
.splide--rtl > .splide__arrows .splide__arrow--prev svg,
.splide--rtl
  > .splide__slider
  > .splide__track
  > .splide__arrows
  .splide__arrow--prev
  svg,
.splide--rtl > .splide__track > .splide__arrows .splide__arrow--prev svg {
  transform: scaleX(1);
}
.splide--rtl > .splide__arrows .splide__arrow--next,
.splide--rtl
  > .splide__slider
  > .splide__track
  > .splide__arrows
  .splide__arrow--next,
.splide--rtl > .splide__track > .splide__arrows .splide__arrow--next {
  left: 1em;
  right: auto;
}
.splide--rtl > .splide__arrows .splide__arrow--next svg,
.splide--rtl
  > .splide__slider
  > .splide__track
  > .splide__arrows
  .splide__arrow--next
  svg,
.splide--rtl > .splide__track > .splide__arrows .splide__arrow--next svg {
  transform: scaleX(-1);
}
.splide--ttb > .splide__arrows .splide__arrow,
.splide--ttb
  > .splide__slider
  > .splide__track
  > .splide__arrows
  .splide__arrow,
.splide--ttb > .splide__track > .splide__arrows .splide__arrow {
  left: 50%;
  transform: translate(-50%);
}
.splide--ttb > .splide__arrows .splide__arrow--prev,
.splide--ttb
  > .splide__slider
  > .splide__track
  > .splide__arrows
  .splide__arrow--prev,
.splide--ttb > .splide__track > .splide__arrows .splide__arrow--prev {
  top: 1em;
}
.splide--ttb > .splide__arrows .splide__arrow--prev svg,
.splide--ttb
  > .splide__slider
  > .splide__track
  > .splide__arrows
  .splide__arrow--prev
  svg,
.splide--ttb > .splide__track > .splide__arrows .splide__arrow--prev svg {
  transform: rotate(-90deg);
}
.splide--ttb > .splide__arrows .splide__arrow--next,
.splide--ttb
  > .splide__slider
  > .splide__track
  > .splide__arrows
  .splide__arrow--next,
.splide--ttb > .splide__track > .splide__arrows .splide__arrow--next {
  bottom: 1em;
  top: auto;
}
.splide--ttb > .splide__arrows .splide__arrow--next svg,
.splide--ttb
  > .splide__slider
  > .splide__track
  > .splide__arrows
  .splide__arrow--next
  svg,
.splide--ttb > .splide__track > .splide__arrows .splide__arrow--next svg {
  transform: rotate(90deg);
}
.splide--ttb > .splide__pagination,
.splide--ttb > .splide__slider > .splide__pagination {
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  left: auto;
  padding: 1em 0;
  right: 0.5em;
  top: 0;
}

`;
export const spinWheelStyle = `
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
.iyosa-spinwheel-underlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  visibility: visible;
  opacity: 1;
  z-index: 10000000000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iyosa-spinwheel-canvas {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 300px;
  display: flex;
  justify-content: center;
}
.iyosa-spinwheel-pointer {
  z-index: 9999;
  position: absolute;
  top: 10px;
  margin-left: 3px;
}
.iyosa-spinwheel-popup {
  font-family:Poppins;
  display: flex;
  width: auto;
  max-width: 740px;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
#iyosa-spinwheel-popup-code{
  border: 1px dashed;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
}
div#iyosa-spinwheel-popup-code div {
  margin: 5px 0px;
}
.iyosa-spinwheel-close {
  position: absolute;
  right: 15px;
  top: 5px;
  font-size: 30px;
  cursor: pointer;
}
.iyosa-spinwheel-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: 400px;
  word-break: break-word;
}
.iyosa-spinwheel-codedescription {
  text-align: center;
  font-weight: 600;
}
.iyosa-spinwheel-content div {
  margin-bottom: 15px;
}
.iyosa-spinwheel-title {
  font-weight: 600;
  font-size: 23px;
}
.iyosa-spinwheel-description {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
}
.iyosa-spinwheel-btn {
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 30px !important;
  outline: none;
  border: none;
  border-radius: 10px;
}
.iyosa-spinwheel-codecontainer {
  display: none;
  position: fixed;
  display: flex;
  transition: all 1s ease-in-out;
  z-index: 1000;
  max-width: 300px;
}
.iyosa-spinwheel-vertical{
  flex-direction: column;
  align-items: flex-end;
}
.iyosa-spinwheel-horizontal{
  flex-direction: row;
  align-items: center;
}
.iyosa-spinwheel-horizontal .iyosa-spinwheel-openclose{
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  border-radius: 0px 10px 10px 0px;
  padding: 5px;
  cursor: pointer;
}
.iyosa-spinwheel-codecontent {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: antiquewhite;
  padding: 15px;
  width: 250px;
}
.iyosa-spinwheel-codecontent div {
  padding: 3px 0px;
}
.iyosa-spinwheel-vertical .iyosa-spinwheel-openclose {
  padding: 5px;
  border-radius: 10px 10px 0px 0px;
  cursor: pointer;
}
.iyosa-spinwheel-countdown {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.iyosa-spinwheel-countdown div {
  border: 1px solid;
  padding: 3px 5px !important;
  font-size: 20px;
  margin: 0px 5px;
}
.iyosa-spinwheel-codediv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.iyosa-spinwheel-codearea {
  border: 1px dashed black;
  width: 100%;
  text-align: center;
}
.iyosa-spinwheel-btn-div {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* begin new coupon code */
.iyosa-coupon-code-countdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.iyosa-coupon-code-countdown div {
  padding: 5px 1px !important;
  font-size: 15px !important;
  margin: 0px 2.5px;
  font-weight: 600;
  text-align: center;
  border: 1px dotted #666;
  width: 25px;
}
.iyosa-coupon-code-card {
  /*height: 175px;*/
  border-radius: 5px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  padding: 10px 10px;
  position: fixed;
  font-family: "Poppins", sans-serif;
  z-index: 999;
  text-align: center;
  transition: all 1s ease-in-out;
  max-width: 300px;
}

.iyosa-coupon-code-main,
.iyosa-coupon-code-copy-button {
  display: flex;
  padding: 0 10px;
  align-items: center;
}

.iyosa-coupon-code-co-img {
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
}
.iyosa-coupon-code-content {
  padding: 0px;
  margin: 0px;
}

.iyosa-coupon-code-content .iyosa-coupon-code-h1 {
  font-size: 30px;
  font-weight: 600;
}

.iyosa-coupon-code-content div span {
  font-size: 18px;
}
.iyosa-coupon-code-content h2 {
  font-size: 18px;
  text-transform: uppercase;
}

.iyosa-coupon-code-content strong {
  font-size: 10px;
  color: #696969;
}

.iyosa-coupon-code-copy-button {
  height: 40px;
  border-radius: 4px;
  padding: 0 5px;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
}

.iyosa-coupon-code-copy-button input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 12px;
  padding: 5px;
  font-family: monospace;
  color: #000 !important;
}

.iyosa-coupon-code-copy-button button {
  padding: 2px 10px;
  color: #fff;
  border: 1px solid transparent;
  font-size: 12px;
  cursor: pointer;
}
.iyosa-coupon-code-text{
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.iyosa-coupon-code-text > div {
  margin:5px 0px !important;
}
.iyosa-spinwheel-blink {
  animation: blinker 0.9s linear infinite;
  font-size: 12px;
  font-weight: bold;
  font-family: sans-serif;
  }
  @keyframes blinker {  
  50% { opacity: 0; }
  }
/* end new coupon code */
/*Mobile devices*/
@media screen and (max-width: 480px) {
  .iyosa-spinwheel-underlay {
    align-items: flex-end;
  }
}
`;
