import ActiveSwitch from "components/Inputs/ActiveSwitch";
import { alerts } from "helpers/Alerts";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import TopBannerService from "services/topbanner.service";

// components
import { h6style } from "../Cards/style";
import { CardLoading } from "components/Inputs/Loading";
import { useParams } from "react-router";
import DraftTextEditor from "components/Inputs/DraftTextEditor";
import decodeHTMLEntities from "helpers/htmlDecode";
import { ChromePicker } from "react-color";

export default function CardTopBannersEdit(props) {
  const [title, setTitle] = useState("");
  const [titleFontSize, setTitleFontSize] = useState(20);
  const [countdown, setCountdown] = useState("");
  const [countdownFontSize, setCountdownFontSize] = useState(15);
  const [topBannerId, setTopBannerId] = useState("");
  const [color, setColor] = useState("#ffffff");
  const [bgColor, setBgColor] = useState("#333333");
  const [countColor, setCountColor] = useState("#333333");
  const [countBgColor, setCountBgColor] = useState("#ffffff");
  const [countBorderColor, setCountBorderColor] = useState("#ffffff");
  const [countBorderRadius, setCountBorderRadius] = useState(5);
  const [countdownMsgLocation, setCountdownMsgLocation] = useState("flex-end");
  const [countdownLocation, setCountdownLocation] = useState("center");
  const [url, setUrl] = useState("");
  const [btnText, setBtnText] = useState("");
  const [btnLocation, setBtnLocation] = useState("");
  const [btnDistance, setBtnDistance] = useState(0);
  const [btnBorderColor, setBtnBorderColor] = useState("");
  const [btnBgColor, setBtnBgColor] = useState("");
  const [btnColor, setBtnColor] = useState("");
  const [btnDisplay, setBtnDisplay] = useState("");
  const [btnBorderRadius, setBtnBorderRadius] = useState("");
  const [btnFontSize, setBtnFontSize] = useState("");
  const [countdownDisplay, setCountdownDisplay] = useState("");
  const [dayBox, setDayBox] = useState("");
  const [visitor, setVisitor] = useState("");
  const [visibilityStart, setVisibilityStart] = useState("");
  const [visibilityEnd, setVisibilityEnd] = useState("");
  const [cbNextDay, setCbNextDay] = useState(false);
  const [alwaysShow, setAlwaysShow] = useState(true);
  const [showBodyTop, setShowBodyTop] = useState(true);
  const [selector, setSelector] = useState("");
  const [blockedUrl, setBlockedUrl] = useState("");
  const [position, setPosition] = useState("");
  const [allowURLInputs, setAllowURLInputs] = useState([{ value: "" }]);
  const [showAllPage, setShowAllPage] = useState(true);
  const [startTime, setStartTime] = useState("");
  const [countdownTexts, setCountdownTexts] = useState({
    day: "gn",
    hours: "sa",
    minute: "dk",
    second: "sn",
  });
  const [sliderDuration, setSliderDuration] = useState(0);
  const [bgImgDesktop, setBgImgDesktop] = useState("");
  const [bgImgMobile, setBgImgMobile] = useState("");
  const [bgPosition, setBgPosition] = useState("");
  const [bgRepeat, setBgRepeat] = useState("");
  const [bgSize, setBgSize] = useState("");
  const [pAbsolute, setPAbsolute] = useState(false);
  const [message, setMessage] = useState("");
  const [active, setActive] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const mountedRef = useRef(true);
  let { owner, company } = useParams();

  useEffect(() => {
    TopBannerService.getTopBanner(owner).then(
      (res) => {
        if (!mountedRef.current) return null;
        setTitle(decodeHTMLEntities(res.data.title));
        setCountdown(moment(res.data.countdown).format("YYYY-MM-DDTHH:mm:ss"));
        setTopBannerId(res.data.id);
        setColor(res.data.color);
        setBgColor(res.data.bgColor);
        setCountColor(res.data.countColor);
        setCountBgColor(res.data.countBgColor);
        setCountBorderColor(res.data.countBorderColor);
        setCountBorderRadius(res.data.countBorderRadius);
        setCountdownMsgLocation(res.data.countdownMsgLocation);
        setCountdownLocation(res.data.countdownLocation);
        setBtnText(res.data.btnText);
        setBtnLocation(res.data.btnLocation);
        setBtnDistance(res.data.btnDistance);
        setBtnBorderColor(res.data.btnBorderColor);
        setBtnBgColor(res.data.btnBgColor);
        setBtnColor(res.data.btnColor);
        setUrl(res.data.url);
        setTitleFontSize(res.data.titleFontSize);
        setCountdownFontSize(res.data.countdownFontSize);
        setBtnDisplay(res.data.btnDisplay);
        setBtnBorderRadius(res.data.btnBorderRadius);
        setBtnFontSize(res.data.btnFontSize);
        setCountdownDisplay(res.data.countdownDisplay);
        setDayBox(res.data.dayBox);
        setVisitor(res.data.visitor);
        setVisibilityStart(res.data.visibilityStart);
        setVisibilityEnd(res.data.visibilityEnd);
        setCbNextDay(res.data.cbNextDay);
        setAlwaysShow(res.data.alwaysShow);
        setShowBodyTop(res.data.showBodyTop);
        setSelector(res.data.selector);
        setBlockedUrl(res.data.blockedUrl);
        setPosition(res.data.position);
        setActive(res.data.active);
        setAllowURLInputs(res.data.allowURLInputs);
        setShowAllPage(res.data.showAllPage);
        setStartTime(moment(res.data.startTime).format("YYYY-MM-DDTHH:mm:ss"));
        setSliderDuration(res.data.sliderDuration);
        setBgImgDesktop(res.data.bgImgDesktop);
        setBgImgMobile(res.data.bgImgMobile);
        setBgPosition(res.data.bgPosition);
        setBgRepeat(res.data.bgRepeat);
        setBgSize(res.data.bgSize);
        setPAbsolute(res.data.pAbsolute);
        setCountdownTexts(
          res.data.countdownTexts
            ? res.data.countdownTexts
            : { day: "gn", hour: "sa", minute: "dk", second: "sn" }
        );
        setDisabled(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setDisabled(false);
      }
    );
    return () => {
      // cancel the subscription
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);
  let handleChangeURL = (i, e) => {
    let newURL = [...allowURLInputs];
    newURL[i].value = e.target.value;
    setAllowURLInputs(newURL);
  };

  let addFormFieldsURL = () => {
    if (allowURLInputs.length < 10)
      setAllowURLInputs([...allowURLInputs, { value: "" }]);
    else setMessage(alerts.titleChangerMax);
  };

  const removeFormFieldsURL = (i) => {
    const newURL = [...allowURLInputs];
    newURL.splice(i, 1);
    setAllowURLInputs(newURL);
  };

  function formOnSubmit(e) {
    e.preventDefault();
    setDisabled(true);
    let date = new Date(countdown).getTime();
    let start = new Date(startTime).getTime();
    TopBannerService.updateTopBanner(
      title,
      date,
      color,
      bgColor,
      countColor,
      countBgColor,
      countBorderColor,
      countBorderRadius,
      countdownMsgLocation,
      countdownLocation,
      url,
      btnText,
      btnLocation,
      btnDistance,
      btnBorderColor,
      btnBgColor,
      btnColor,
      titleFontSize,
      countdownFontSize,
      btnDisplay,
      btnBorderRadius,
      btnFontSize,
      countdownDisplay,
      dayBox,
      visitor,
      visibilityStart,
      visibilityEnd,
      cbNextDay,
      alwaysShow,
      showBodyTop,
      selector,
      blockedUrl,
      position,
      showAllPage,
      start,
      countdownTexts,
      allowURLInputs,
      sliderDuration,
      bgImgDesktop,
      bgImgMobile,
      bgPosition,
      bgRepeat,
      bgSize,
      pAbsolute,
      active,
      owner,
      topBannerId
    ).then(
      (res) => {
        setMessage(alerts.positive);
        setDisabled(false);
        setTimeout(() => {
          setMessage("");
        }, 2000);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setDisabled(false);
        let date = new Date(countdown).getTime();
        let start = new Date(startTime).getTime();
        TopBannerService.createTopBanner(
          title,
          date,
          color,
          bgColor,
          countColor,
          countBgColor,
          countBorderColor,
          countBorderRadius,
          countdownMsgLocation,
          countdownLocation,
          url,
          btnText,
          btnLocation,
          btnDistance,
          btnBorderColor,
          btnBgColor,
          btnColor,
          titleFontSize,
          countdownFontSize,
          btnDisplay,
          btnBorderRadius,
          btnFontSize,
          countdownDisplay,
          dayBox,
          visitor,
          visibilityStart,
          visibilityEnd,
          cbNextDay,
          alwaysShow,
          showBodyTop,
          selector,
          blockedUrl,
          position,
          showAllPage,
          start,
          countdownTexts,
          allowURLInputs,
          sliderDuration,
          bgImgDesktop,
          bgImgMobile,
          bgPosition,
          bgRepeat,
          bgSize,
          pAbsolute,
          false,
          owner
        ).then(
          (res) => {
            setTopBannerId(res.data.id);
            setDisabled(false);
            setMessage("Updated!");
          },
          (error) => {
            console.log(error);
            setMessage(error.toString());
          }
        );
      }
    );
  }
  function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  function rgbToHex(r, g, b, a) {
    var a_ = ((a * 255) | (1 << 8)).toString(16).slice(1);
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b) + a_;
  }
  const preview = `
  <link href='https://fonts.googleapis.com/css?family=Lato' rel='stylesheet' type='text/css'>
  <style>
    .iys-notice-widget{
      width:100%;
      background-color:${bgColor};
      height: 60px;
      padding: 6px 60px 6px 30px;
      box-sizing: border-box;
      z-index: 99999;
      color: #ffec00;
      -webkit-font-smoothing: antialiased;
      display: -webkit-flex;
      display: flex;
      transition: all 0.3s ease;
      z-index: 2;
      position: relative;
      margin-top: 0;
      margin-bottom: 0;
      width: 100%;
      box-sizing: border-box;
      font-family:'Lato', sans-serif;
    }
    .iys-notice-container{
      width: 100%;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-align-items: center;
      align-items: center;
      text-decoration: none;
    }
    .iys-notice-close{
      color: #fff;
      width: 26px;
      height: 26px;
      font-size: 26px;
      position: absolute;
      right: 17px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.3s ease;
      display: block;
      opacity: 0.8; 
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .iys-notice-close:hover{
      background-color: rgba(255, 255, 255, 0.2);
    }
    .iys-notice-inner{
      -webkit-flex-direction: row;
      flex-direction: row;
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      justify-content: center;
      width: 100%;
      outline: 0;
      cursor:pointer;
      text-decoration:none;
      display:flex;
      -webkit-font-smoothing: antialiased;
    }
    .iys-notice-inner * {
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }
    .iys-notice-title{
      font-size: ${titleFontSize}px;
      color: ${color};
      line-height: 24px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
      justify-content: ${countdownMsgLocation};
    }
    .iys-notice-text{
      display: -webkit-box;
      overflow: hidden;
      word-wrap: break-word;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      max-height: 2.9em;
      color:#efefef;
    }
    .iys-notice-text p{
      margin:0px;
    }
    .iys-notice-timer-items{
      margin: 4px 20px 0 20px;
      justify-content: center;
      display: -webkit-flex;
      display: ${countdownDisplay};
      -webkit-justify-content: center;
      justify-content: ${countdownLocation};
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }
    .iys-notice-timer-items > * {
      flex: 1;
    }
    .iys-notice-timer-item{
      margin: 0 6px !important;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      flex-direction: column;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-align-items: center;
      align-items: center;
      margin: 0 24px;
    }
    .iys-notice-timer-value{
      font-size: ${countdownFontSize}px;
      font-weight: 700;
      padding: 3px 5px;
      border-radius:5px;
      -webkit-perspective: 3.2em;
      perspective: 3.2em;
      min-width: 1.125em;
      text-align: center;
      font-feature-settings: "tnum";
      font-variant-numeric: tabular-nums;
      background-color: ${countBgColor};
      color:${countColor};
      transition:0.3s ease all;
      border: 1px solid ${countBorderColor};
    }
    .iys-notice-timer-label{
      font-size: 11px;
      max-width: 46px;
      color:${countColor};
    }
    .iys-notice-btn-container{
      margin-top: 0 !important;
      -webkit-justify-content: ${btnLocation};
      justify-content: ${btnLocation};
      display:${btnDisplay};
      display:-webkit-${btnDisplay};
    }
    .iys-notice-button{
      font-size:${btnFontSize}px;
      padding:10px 15px;
      border-radius:${btnBorderRadius}px;
      background-color:${btnBgColor};
      color:${btnColor};
      border:1px solid ${btnBorderColor};
      font-weight:700;
    }
    .iys-notice-title > div {
      margin: auto;
      overflow: hidden;
      padding: 5px;
      position: relative;
      width: auto;
      max-width: 600px;
    }
    .iys-notice-title div ol {
      list-style: none;
      position: relative;
      left: 0px;
      top: -8px;
      width: 9000px;
      transition: left .2s linear;
      -moz-transition: left .2s linear;
      -o-transition: left .2s linear;
      -webkit-transition: left .2s linear;
      margin-left: -25px;
    }
    .iys-notice-title div li{
      float: left;
      position: relative;
      width: 600px;
      display: inline-block;
      margin: 0 auto;
      padding: 0;
      text-align: center;
    }
    @-webkit-keyframes slide-animation {
      0% {opacity:0;}
      2% {opacity:1;}
      20% {left:0px; opacity:1;}
      25% {left:-600px; opacity:1;}
      55% {left:-600px; opacity:1;}
      60% {left:-1200px; opacity:1;}
      90% {left:-1200px; opacity:1;}
      98% {left:-1800px; opacity:0;} 
      100% {left:0px; opacity:0;}
    }
    
    .iys-notice-title div ol {
      -webkit-animation: slide-animation 10s infinite;
    }
  </style>
  <script>
    var countDownDate = new Date("${countdown}").getTime();
    var x = setInterval(function () {
      var distance = countDownDate - new Date().getTime();
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      var timerValue = document.querySelectorAll(".iys-notice-timer-value");
      timerValue[0].innerText = days;
      timerValue[1].textContent = hours;
      timerValue[2].textContent = minutes;
      timerValue[3].textContent = seconds;
      if (distance < 0) {
        clearInterval(x);
        container.style.display = "none";
      }
    }, 1000);
  </script>
  <div class="iys-notice-widget">
    <div class="iys-notice-container">
      <a href="${url}" class="iys-notice-inner" >
        <div class="iys-notice-title">
          <div class="iys-notice-text">
            ${title}
          </div>
        </div>
        <div class="iys-notice-timer-container">
          <div class="iys-notice-timer-items">
            <div class="iys-notice-timer-item" style="display:${
              dayBox === "show" ? "flex" : "none"
            }">
              <div class="iys-notice-timer-value">01</div>
              <div class="iys-notice-timer-label">${countdownTexts.day}</div>
            </div>
            <div class="iys-notice-timer-item">
              <div class="iys-notice-timer-value">10</div>
              <div class="iys-notice-timer-label">${countdownTexts.hours}</div>
            </div>
            <div class="iys-notice-timer-item">
              <div class="iys-notice-timer-value">34</div>
              <div class="iys-notice-timer-label">${countdownTexts.minute}</div>
            </div>
            <div class="iys-notice-timer-item">
              <div class="iys-notice-timer-value">23</div>
              <div class="iys-notice-timer-label">${countdownTexts.second}</div>
            </div>
          </div>
        </div>
        <div class="iys-notice-btn-container">
          <div class="iys-notice-button">
            ${btnText}
          </div>
        </div>
      </a>
      <div class="iys-notice-close">
        &times;
      </div>
    </div>
  </div>
  `;
  return (
    <>
      <CardLoading isShow={disabled} />
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <h6 className="text-blueGray-700 text-xl font-bold" style={h6style}>
          {company}
        </h6>
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold" style={h6style}>
              <ActiveSwitch
                disabled={disabled}
                onColor="#0ea5e9"
                checked={active}
                handleToggle={(e) => setActive(e.target.checked)}
              />{" "}
              Top Banner & Countdown
            </h6>
          </div>
        </div>
        <div
          className="w-full lg:w-12/12 px-4"
          style={{ position: "sticky", top: 0, zIndex: "999999" }}
        >
          <iframe
            srcDoc={preview}
            title="Preview"
            width={"100%"}
            height={"100px"}
          />
        </div>
        <div className="w-full lg:w-12/12 px-4 flex  px-4 lg:px-10 py-10 pt-0">
          <div className="w-full lg:w-12/12 px-4">
            <form onSubmit={(e) => formOnSubmit(e)}>
              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                Top Banner & Countdown
              </h6>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Show Body Top
                    </label>
                    <input
                      type="checkbox"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                      value={showBodyTop}
                      checked={showBodyTop}
                      onChange={(e) => setShowBodyTop(e.target.checked)}
                      disabled={disabled}
                    />
                  </div>
                  {showBodyTop === false ? (
                    <>
                      <div className="relative w-full mb-3">
                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                          Selector
                        </label>
                        <input
                          type="text"
                          className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          value={selector}
                          onChange={(e) => setSelector(e.target.value)}
                          name="selector"
                          required
                          disabled={disabled}
                        />
                      </div>
                      <div className="relative w-full mb-3">
                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                          Position
                        </label>
                        <select
                          type="text"
                          className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          value={position}
                          onChange={(e) => setPosition(e.target.value)}
                          name="position"
                          required
                          disabled={disabled}
                        >
                          <option value="beforebegin">beforebegin</option>
                          <option value="afterbegin">afterbegin</option>
                          <option value="afterend">afterend</option>
                          <option value="beforeend">beforeend</option>
                        </select>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Position Absolute
                    </label>
                    <input
                      type="checkbox"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                      value={pAbsolute}
                      checked={pAbsolute}
                      onChange={(e) => setPAbsolute(e.target.checked)}
                      disabled={disabled}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Block URLs containing:
                    </label>
                    <input
                      type="text"
                      className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={blockedUrl}
                      onChange={(e) => setBlockedUrl(e.target.value)}
                      name="blockedUrl"
                      disabled={disabled}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Show All Page
                    </label>
                    <input
                      type="checkbox"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                      value={showAllPage}
                      checked={showAllPage}
                      onChange={(e) => setShowAllPage(e.target.checked)}
                      disabled={disabled}
                    />
                  </div>
                  {showAllPage === false && (
                    <>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Allow URL
                        </label>
                        <button
                          className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          onClick={() => addFormFieldsURL()}
                        >
                          <i className="fas fa-plus"></i>
                        </button>
                      </div>
                      <div className="flex flex-wrap">
                        {allowURLInputs.map((allowURL, index) => (
                          <div key={index} className="w-full lg:w-8/12 px-4">
                            <div className="relative w-full mb-3">
                              <label
                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                                URL {index + 1}
                              </label>
                              <input
                                type="text"
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                value={allowURL.value}
                                onChange={(e) => handleChangeURL(index, e)}
                                required
                              />
                              <span
                                className="z-10 h-full leading-snug font-normal absolute text-center text-red-700 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3"
                                onClick={() => removeFormFieldsURL(index)}
                                style={{ cursor: "pointer", color: "red" }}
                              >
                                <i className="fas fa-minus"></i>
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}

                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Background Image URL (Desktop):
                    </label>
                    <input
                      type="text"
                      className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={bgImgDesktop}
                      onChange={(e) => setBgImgDesktop(e.target.value)}
                      name="bgImgDesktop"
                      disabled={disabled}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Background Image URL (Mobile):
                    </label>
                    <input
                      type="text"
                      className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={bgImgMobile}
                      onChange={(e) => setBgImgMobile(e.target.value)}
                      name="bgImgMobile"
                      disabled={disabled}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Background Position
                    </label>
                    <select
                      type="text"
                      className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={bgPosition}
                      onChange={(e) => setBgPosition(e.target.value)}
                      name="bgPosition"
                      disabled={disabled}
                    >
                      <option value="">Select...</option>
                      <option value="bottom">Bottom</option>
                      <option value="center">Center</option>
                      <option value="left">Left</option>
                      <option value="right">Right</option>
                      <option value="top">Top</option>
                    </select>
                  </div>

                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Background Size
                    </label>
                    <select
                      type="text"
                      className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={bgSize}
                      onChange={(e) => setBgSize(e.target.value)}
                      name="bgSize"
                      disabled={disabled}
                    >
                      <option value="">Select...</option>
                      <option value="auto">auto</option>
                      <option value="contain">contain</option>
                      <option value="cover">cover</option>
                    </select>
                  </div>

                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Background Repeat
                    </label>
                    <select
                      type="text"
                      className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={bgRepeat}
                      onChange={(e) => setBgRepeat(e.target.value)}
                      name="bgRepeat"
                      disabled={disabled}
                    >
                      <option value="">Select...</option>
                      <option value="no-repeat">No Repeat</option>
                      <option value="repeat">Repeat</option>
                      <option value="repeat-x">Repeat X</option>
                      <option value="repeat-y">Repeat Y</option>
                    </select>
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Title
                    </label>
                    {title !== "" && (
                      <DraftTextEditor
                        val={title}
                        handleChange={(x) => setTitle(x)}
                      />
                    )}

                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      required
                      disabled={disabled}
                      hidden
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Slider Duration (second)
                    </label>
                    <input
                      type="number"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={sliderDuration}
                      onChange={(e) => setSliderDuration(e.target.value)}
                      required
                      disabled={disabled}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Title Font Size (px)
                    </label>
                    <input
                      type="number"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={titleFontSize}
                      onChange={(e) => setTitleFontSize(e.target.value)}
                      required
                      disabled={disabled}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      htmlFor="color"
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                      Title Color
                    </label>
                    <input
                      disabled={disabled}
                      type="color"
                      id="color"
                      name="color"
                      value={color}
                      onChange={(e) => setColor(e.target.value)}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Title Location
                    </label>
                    <input
                      type="radio"
                      id="left"
                      name="location"
                      value="left"
                      checked={countdownMsgLocation === "left"}
                      onChange={(e) => setCountdownMsgLocation("left")}
                    />
                    <label htmlFor="left"> Left</label>
                    <br />
                    <input
                      type="radio"
                      id="center"
                      name="location"
                      value="center"
                      checked={countdownMsgLocation === "center"}
                      onChange={(e) => setCountdownMsgLocation("center")}
                    />
                    <label htmlFor="center"> Center</label>
                    <br />
                    <input
                      type="radio"
                      id="right"
                      name="location"
                      value="right"
                      checked={countdownMsgLocation === "right"}
                      onChange={(e) => setCountdownMsgLocation("right")}
                    />
                    <label htmlFor="right"> Right</label>
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      htmlFor="bgColor"
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                      Background Color
                    </label>
                    <ChromePicker
                      color={bgColor}
                      onChangeComplete={(color, event) =>
                        setBgColor(
                          rgbToHex(
                            color.rgb.r,
                            color.rgb.g,
                            color.rgb.b,
                            color.rgb.a
                          )
                        )
                      }
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Visitor
                    </label>
                    <input
                      type="radio"
                      id="visitor-new"
                      name="visitor"
                      value="new"
                      checked={visitor === "new"}
                      onChange={(e) => setVisitor("new")}
                    />
                    <label htmlFor="visitor-new"> New</label>
                    <br />
                    <input
                      type="radio"
                      id="visitor-all"
                      name="visitor"
                      value="all"
                      checked={visitor === "all"}
                      onChange={(e) => setVisitor("all")}
                    />
                    <label htmlFor="visitor-all"> All</label>
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      URL
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={url}
                      onChange={(e) => setUrl(e.target.value)}
                      required
                      disabled={disabled}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Countdown Show/Hide
                    </label>
                    <input
                      type="radio"
                      id="countdown-flex"
                      name="countdownDisplay"
                      value="flex"
                      checked={countdownDisplay === "flex"}
                      onChange={(e) => setCountdownDisplay("flex")}
                    />
                    <label htmlFor="countdown-flex"> Show</label>
                    <br />
                    <input
                      type="radio"
                      id="countdown-none"
                      name="countdownDisplay"
                      value="none"
                      checked={countdownDisplay === "none"}
                      onChange={(e) => setCountdownDisplay("none")}
                    />
                    <label htmlFor="countdown-none"> Hide</label>
                  </div>
                  {countdownDisplay === "flex" ? (
                    <>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Countdown Location
                        </label>
                        <input
                          type="radio"
                          id="countdown-left"
                          name="countdownLocation"
                          value="flex-start"
                          checked={countdownLocation === "flex-start"}
                          onChange={(e) => setCountdownLocation("flex-start")}
                        />
                        <label htmlFor="countdown-left"> Left</label>
                        <br />
                        <input
                          type="radio"
                          id="countdown-center"
                          name="countdownLocation"
                          value="center"
                          checked={countdownLocation === "center"}
                          onChange={(e) => setCountdownLocation("center")}
                        />
                        <label htmlFor="countdown-center"> Center</label>
                        <br />
                        <input
                          type="radio"
                          id="countdown-right"
                          name="countdownLocation"
                          value="flex-end"
                          checked={countdownLocation === "flex-end"}
                          onChange={(e) => setCountdownLocation("flex-end")}
                        />
                        <label htmlFor="countdown-right"> Right</label>
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Start Time
                        </label>
                        <input
                          type="datetime-local"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          value={startTime}
                          onChange={(e) => setStartTime(e.target.value)}
                          required
                          disabled={disabled}
                        />
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Finish Time
                        </label>
                        <input
                          type="datetime-local"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          value={countdown}
                          onChange={(e) => setCountdown(e.target.value)}
                          required
                          disabled={disabled}
                        />
                      </div>
                      <div className="relative w-full mb-3">
                        <div className="flex flex-wrap">
                          <div className="w-full px-4 flex-1">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              Day Text
                            </label>
                            <input
                              type="text"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              value={countdownTexts.day && countdownTexts.day}
                              onChange={(e) =>
                                setCountdownTexts((countdownTexts) => ({
                                  ...countdownTexts,
                                  ...{ day: e.target.value },
                                }))
                              }
                              required
                              disabled={disabled}
                            />
                          </div>
                          <div className="w-full px-4 flex-1">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              Hour Text
                            </label>
                            <input
                              type="text"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              value={
                                countdownTexts.hours && countdownTexts.hours
                              }
                              onChange={(e) =>
                                setCountdownTexts((countdownTexts) => ({
                                  ...countdownTexts,
                                  ...{ hours: e.target.value },
                                }))
                              }
                              required
                              disabled={disabled}
                            />
                          </div>
                          <div className="w-full px-4 flex-1">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              Minute Text
                            </label>
                            <input
                              type="text"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              value={
                                countdownTexts.minute && countdownTexts.minute
                              }
                              onChange={(e) =>
                                setCountdownTexts((countdownTexts) => ({
                                  ...countdownTexts,
                                  ...{ minute: e.target.value },
                                }))
                              }
                              required
                              disabled={disabled}
                            />
                          </div>
                          <div className="w-full px-4 flex-1">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              Second Text
                            </label>
                            <input
                              type="text"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              value={
                                countdownTexts.second && countdownTexts.second
                              }
                              onChange={(e) =>
                                setCountdownTexts((countdownTexts) => ({
                                  ...countdownTexts,
                                  ...{ second: e.target.value },
                                }))
                              }
                              required
                              disabled={disabled}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Always Show
                        </label>
                        <input
                          type="checkbox"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                          value={alwaysShow}
                          checked={alwaysShow}
                          onChange={(e) => setAlwaysShow(e.target.checked)}
                          disabled={disabled}
                        />
                      </div>
                      {alwaysShow === false ? (
                        <>
                          <div className="relative w-full mb-3">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              Visibility Start
                            </label>
                            <input
                              type="time"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              value={visibilityStart}
                              onChange={(e) =>
                                setVisibilityStart(e.target.value)
                              }
                              disabled={disabled}
                            />
                          </div>
                          <div className="relative w-full mb-3">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              Visibility End
                            </label>
                            <input
                              type="time"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring  ease-linear transition-all duration-150"
                              value={visibilityEnd}
                              onChange={(e) => setVisibilityEnd(e.target.value)}
                              disabled={disabled}
                            />
                            <label
                              className=" uppercase text-blueGray-600 text-xs font-bold mb-2"
                              htmlFor="cbNextDay"
                            >
                              &nbsp;&nbsp;End is next day&nbsp;&nbsp;&nbsp;
                            </label>
                            <input
                              id="cbNextDay"
                              type="checkbox"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring  ease-linear transition-all duration-150"
                              value={cbNextDay}
                              checked={cbNextDay}
                              onChange={(e) => setCbNextDay(e.target.checked)}
                              disabled={disabled}
                            />
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Day Box Show/Hide
                        </label>
                        <input
                          type="radio"
                          id="daybox-show"
                          name="dayBox"
                          value="show"
                          checked={dayBox === "show"}
                          onChange={(e) => setDayBox("show")}
                        />
                        <label htmlFor="daybox-show"> Show</label>
                        <br />
                        <input
                          type="radio"
                          id="daybox-hide"
                          name="dayBox"
                          value="hide"
                          checked={dayBox === "hide"}
                          onChange={(e) => setDayBox("hide")}
                        />
                        <label htmlFor="daybox-hide"> Hide</label>
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Countdown Font Size (px)
                        </label>
                        <input
                          type="number"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          value={countdownFontSize}
                          onChange={(e) => setCountdownFontSize(e.target.value)}
                          required
                          disabled={disabled}
                        />
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          htmlFor="countColor"
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        >
                          Countdown Color
                        </label>
                        <input
                          disabled={disabled}
                          type="color"
                          id="countColor"
                          name="countColor"
                          value={countColor}
                          onChange={(e) => setCountColor(e.target.value)}
                        />
                        <input
                          type="text"
                          value={countColor}
                          onChange={(e) => setCountColor(e.target.value)}
                        />
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          htmlFor="countBgColor"
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        >
                          Countdown Background Color
                        </label>
                        <input
                          disabled={disabled}
                          type="color"
                          id="countBgColor"
                          name="countBgColor"
                          value={countBgColor}
                          onChange={(e) => setCountBgColor(e.target.value)}
                        />
                        <input
                          type="text"
                          value={countBgColor}
                          onChange={(e) => setCountBgColor(e.target.value)}
                        />
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          htmlFor="countBgColor"
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        >
                          Countdown Border Color
                        </label>
                        <input
                          disabled={disabled}
                          type="color"
                          id="countBorderColor"
                          name="countBorderColor"
                          value={countBorderColor}
                          onChange={(e) => setCountBorderColor(e.target.value)}
                        />
                        <input
                          type="text"
                          value={countBorderColor}
                          onChange={(e) => setCountBorderColor(e.target.value)}
                        />
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          htmlFor="countBorderRadius"
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        >
                          Countdown Border Radius (px)
                        </label>
                        <input
                          disabled={disabled}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          type="number"
                          id="countBorderRadius"
                          name="countBorderRadius"
                          value={countBorderRadius}
                          onChange={(e) => setCountBorderRadius(e.target.value)}
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Button Show/Hide
                    </label>
                    <input
                      type="radio"
                      id="flex"
                      name="btnDisplay"
                      value="flex"
                      checked={btnDisplay === "flex"}
                      onChange={(e) => setBtnDisplay("flex")}
                    />
                    <label htmlFor="flex"> Show</label>
                    <br />
                    <input
                      type="radio"
                      id="none"
                      name="btnDisplay"
                      value="none"
                      checked={btnDisplay === "none"}
                      onChange={(e) => setBtnDisplay("none")}
                    />
                    <label htmlFor="none"> Hide</label>
                  </div>
                  {btnDisplay === "flex" ? (
                    <>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Button Text
                        </label>
                        <input
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          value={btnText}
                          onChange={(e) => setBtnText(e.target.value)}
                          required
                          disabled={disabled}
                        />
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          htmlFor="btnBorderRadius"
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        >
                          Button Border Radius (px)
                        </label>
                        <input
                          disabled={disabled}
                          type="number"
                          id="btnBorderRadius"
                          name="btnBorderRadius"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          value={btnBorderRadius}
                          onChange={(e) => setBtnBorderRadius(e.target.value)}
                        />
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          htmlFor="btnBorderRadius"
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        >
                          Button Font Size (px)
                        </label>
                        <input
                          disabled={disabled}
                          type="number"
                          id="btnFontSize"
                          name="btnFontSize"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          value={btnFontSize}
                          onChange={(e) => setBtnFontSize(e.target.value)}
                        />
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Button Location
                        </label>

                        <input
                          type="radio"
                          id="btn-left"
                          name="btnLocation"
                          value="left"
                          checked={btnLocation === "left"}
                          onChange={(e) => setBtnLocation("left")}
                        />
                        <label htmlFor="btn-left"> Left</label>
                        <br />

                        <input
                          type="radio"
                          id="btn-center"
                          name="btnLocation"
                          value="center"
                          checked={btnLocation === "center"}
                          onChange={(e) => setBtnLocation("center")}
                        />
                        <label htmlFor="btn-center"> Center</label>
                        <br />

                        <input
                          type="radio"
                          id="btn-right"
                          name="btnLocation"
                          value="right"
                          checked={btnLocation === "right"}
                          onChange={(e) => setBtnLocation("right")}
                        />
                        <label htmlFor="btn-right"> Right</label>
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          htmlFor="btnBorderColor"
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        >
                          Button Border Color
                        </label>
                        <input
                          disabled={disabled}
                          type="color"
                          id="btnBorderColor"
                          name="btnBorderColor"
                          value={btnBorderColor}
                          onChange={(e) => setBtnBorderColor(e.target.value)}
                        />
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          htmlFor="btnBgColor"
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        >
                          Button Background Color
                        </label>
                        <input
                          disabled={disabled}
                          type="color"
                          id="btnBgColor"
                          name="btnBgColor"
                          value={btnBgColor}
                          onChange={(e) => setBtnBgColor(e.target.value)}
                        />
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          htmlFor="btnColor"
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        >
                          Button Text Color
                        </label>
                        <input
                          disabled={disabled}
                          type="color"
                          id="btnColor"
                          name="btnColor"
                          value={btnColor}
                          onChange={(e) => setBtnColor(e.target.value)}
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <button
                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="submit"
                disabled={disabled}
              >
                Save
              </button>
              {message}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
