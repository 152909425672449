import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { alerts } from "helpers/Alerts";
import ActiveSwitch from "components/Inputs/ActiveSwitch";
import AuthService from "services/auth.service";
import ExitPopupService from "services/exitpopup.service";
import FreeShippingService from "services/freeshipping.service";
import OpeningPopupService from "services/openingpopup.service";
import OpportunityService from "services/opportunity.service";
import SpinWheelService from "services/spinwheel.service";
import StoriesService from "services/stories.service";
import TitleService from "services/title.service";
import TopBannerService from "services/topbanner.service";
import WatchedBoughtService from "services/watchedbougth.service";
import PopularSearchService from "services/popularsearch.service";

// components
import { h6style } from "./style";

export default function CardTitleChanger(props) {
  const [title, setTitle] = useState({ active: false });
  const [countdown, setCountdown] = useState({ active: false });
  const [shipping, setShipping] = useState({ active: false });
  const [watch, setWatch] = useState({ active: false });
  const [opening, setOpening] = useState({ active: false });
  const [exitPopup, setExitPopup] = useState({ active: false });
  const [story, setStory] = useState({ active: false });
  const [oppor, setOppor] = useState({ active: false });
  const [spinWheel, setSpinWheel] = useState({ active: false });
  const [popularSearch, setPopularSearch] = useState({ active: false });
  const [message, setMessage] = useState("");
  const [disabled] = useState(false);
  const currentUser = AuthService.getCurrentUser();
  const mountedRef = useRef(true);

  useEffect(() => {
    FreeShippingService.getFreeShipping(currentUser.user.id).then(
      (res) => {
        if (!mountedRef.current) return null;
        setShipping(res.data);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
      }
    );
    OpeningPopupService.getOpeningPopup(currentUser.user.id).then(
      (res) => {
        if (!mountedRef.current) return null;
        setOpening(res.data);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
      }
    );
    ExitPopupService.getExitPopup(currentUser.user.id).then(
      (res) => {
        if (!mountedRef.current) return null;
        setExitPopup(res.data);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
      }
    );
    OpportunityService.getOpportunity(currentUser.user.id).then(
      (res) => {
        if (!mountedRef.current) return null;
        setOppor(res.data);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
      }
    );
    StoriesService.getStories(currentUser.user.id).then(
      (res) => {
        if (!mountedRef.current) return null;
        setStory(res.data);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
      }
    );
    TitleService.getTitle(currentUser.user.id).then(
      (res) => {
        if (!mountedRef.current) return null;
        setTitle(res.data);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
      }
    );
    TopBannerService.getTopBanner(currentUser.user.id).then(
      (res) => {
        if (!mountedRef.current) return null;
        setCountdown(res.data);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
      }
    );
    WatchedBoughtService.getWatchedBought(currentUser.user.id).then(
      (res) => {
        if (!mountedRef.current) return null;
        setWatch(res.data);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
      }
    );
    SpinWheelService.getSpinWheel(currentUser.user.id).then(
      (res) => {
        if (!mountedRef.current) return null;
        setSpinWheel(res.data);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
      }
    );
    PopularSearchService.get(currentUser.user.id).then(
      (res) => {
        if (!mountedRef.current) return null;
        setPopularSearch(res.data);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
      }
    );
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);
  function handleExit(active) {
    let newObj = { ...exitPopup };
    newObj.active = active;
    ExitPopupService.updateActive(
      active,
      currentUser.user.id,
      exitPopup.id
    ).then(
      (res) => {
        setMessage(alerts.positive);
        setExitPopup(newObj);
        setTimeout(() => {
          setMessage("");
        }, 2000);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
      }
    );
  }
  function handleShipping(active) {
    let newObj = { ...shipping };
    newObj.active = active;
    FreeShippingService.updateActive(
      active,
      currentUser.user.id,
      shipping.id
    ).then(
      (res) => {
        setMessage(alerts.positive);
        setShipping(newObj);
        setTimeout(() => {
          setMessage("");
        }, 2000);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
      }
    );
  }
  function handleOpening(active) {
    let newObj = { ...opening };
    newObj.active = active;
    OpeningPopupService.updateActive(
      active,
      currentUser.user.id,
      opening.id
    ).then(
      (res) => {
        setMessage(alerts.positive);
        setOpening(newObj);
        setTimeout(() => {
          setMessage("");
        }, 2000);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
      }
    );
  }
  function handleOppor(active) {
    let newObj = { ...oppor };
    newObj.active = active;
    OpportunityService.updateActive(active, currentUser.user.id, oppor.id).then(
      (res) => {
        setMessage(alerts.positive);
        setOppor(newObj);
        setTimeout(() => {
          setMessage("");
        }, 2000);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
      }
    );
  }
  function handleStory(active) {
    let newObj = { ...story };
    newObj.active = active;
    StoriesService.updateActive(active, currentUser.user.id, story.id).then(
      (res) => {
        setMessage(alerts.positive);
        setStory(newObj);
        setTimeout(() => {
          setMessage("");
        }, 2000);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
      }
    );
  }
  function handleTitle(active) {
    let newObj = { ...title };
    newObj.active = active;
    TitleService.updateActive(active, currentUser.user.id, title.id).then(
      (res) => {
        setMessage(alerts.positive);
        setTitle(newObj);
        setTimeout(() => {
          setMessage("");
        }, 2000);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
      }
    );
  }
  function handleCountdown(active) {
    let newObj = { ...countdown };
    newObj.active = active;
    TopBannerService.updateActive(
      active,
      currentUser.user.id,
      countdown.id
    ).then(
      (res) => {
        setMessage(alerts.positive);
        setCountdown(newObj);
        setTimeout(() => {
          setMessage("");
        }, 2000);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
      }
    );
  }
  function handleWatch(active) {
    let newObj = { ...watch };
    newObj.active = active;
    WatchedBoughtService.updateActive(
      active,
      currentUser.user.id,
      watch.id
    ).then(
      (res) => {
        setMessage(alerts.positive);
        setWatch(newObj);
        setTimeout(() => {
          setMessage("");
        }, 2000);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
      }
    );
  }
  function handleSpinWheel(active) {
    let newObj = { ...spinWheel };
    newObj.active = active;
    SpinWheelService.updateActive(
      active,
      currentUser.user.id,
      spinWheel.id
    ).then(
      (res) => {
        setMessage(alerts.positive);
        setSpinWheel(newObj);
        setTimeout(() => {
          setMessage("");
        }, 2000);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
      }
    );
  }
  function handlePopularSearch(active) {
    let newObj = { ...popularSearch };
    newObj.active = active;
    PopularSearchService.updateActive(
      active,
      currentUser.user.id,
      popularSearch.id
    ).then(
      (res) => {
        setMessage(alerts.positive);
        setPopularSearch(newObj);
        setTimeout(() => {
          setMessage("");
        }, 2000);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
      }
    );
  }
  function handleAllOff() {
    handleCountdown(false);
    handleExit(false);
    handleOpening(false);
    handleOppor(false);
    handleShipping(false);
    handleStory(false);
    handleTitle(false);
    handleWatch(false);
    handleSpinWheel(false);
    handlePopularSearch(false);
  }
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold" style={h6style}>
              Scenario Management
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Scenario Management
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-8/12 px-4">
                <div
                  className="relative w-full mb-3 script-mngmt"
                  style={{ justifyContent: "flex-end" }}
                >
                  <button
                    className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                    disabled={disabled}
                    onClick={handleAllOff}
                  >
                    Close All
                  </button>
                </div>
                <div className="relative w-full mb-3 script-mngmt">
                  <h6
                    className="text-blueGray-700 text-xl font-bold mr-5"
                    style={h6style}
                  >
                    <Link to="/admin/title-changer">Title Changer </Link>
                  </h6>
                  <ActiveSwitch
                    disabled={disabled}
                    onColor="#0ea5e9"
                    checked={title.active}
                    name="title"
                    handleToggle={(e) => handleTitle(e.target.checked)}
                  />
                </div>
                <div className="relative w-full mb-3 script-mngmt">
                  <h6
                    className="text-blueGray-700 text-xl font-bold mr-5"
                    style={h6style}
                  >
                    <Link to="/admin/top-banner-changer">
                      Top Banner & Countdown{" "}
                    </Link>
                  </h6>
                  <ActiveSwitch
                    disabled={disabled}
                    onColor="#0ea5e9"
                    checked={countdown.active}
                    name="countdown"
                    handleToggle={(e) => handleCountdown(e.target.checked)}
                  />
                </div>
                <div className="relative w-full mb-3 script-mngmt">
                  <h6
                    className="text-blueGray-700 text-xl font-bold mr-5"
                    style={h6style}
                  >
                    <Link to="/admin/free-shipping">Shipping Free Notice </Link>
                  </h6>
                  <ActiveSwitch
                    disabled={disabled}
                    onColor="#0ea5e9"
                    checked={shipping.active}
                    name="shipping"
                    handleToggle={(e) => handleShipping(e.target.checked)}
                  />
                </div>
                <div className="relative w-full mb-3 script-mngmt">
                  <h6
                    className="text-blueGray-700 text-xl font-bold mr-5"
                    style={h6style}
                  >
                    <Link to="/admin/watched-bought">Viewed/Bought </Link>
                  </h6>
                  <ActiveSwitch
                    disabled={disabled}
                    onColor="#0ea5e9"
                    checked={watch.active}
                    name="watch"
                    handleToggle={(e) => handleWatch(e.target.checked)}
                  />
                </div>
                <div className="relative w-full mb-3 script-mngmt">
                  <h6
                    className="text-blueGray-700 text-xl font-bold mr-5"
                    style={h6style}
                  >
                    <Link to="/admin/opening-popup">Opening Popup </Link>
                  </h6>
                  <ActiveSwitch
                    disabled={disabled}
                    onColor="#0ea5e9"
                    checked={opening.active}
                    name="opening"
                    handleToggle={(e) => handleOpening(e.target.checked)}
                  />
                </div>
                <div className="relative w-full mb-3 script-mngmt">
                  <h6
                    className="text-blueGray-700 text-xl font-bold mr-5"
                    style={h6style}
                  >
                    <Link to="/admin/exit-popup">Exit Popup </Link>
                  </h6>
                  <ActiveSwitch
                    disabled={disabled}
                    onColor="#0ea5e9"
                    checked={exitPopup.active}
                    name="exit"
                    handleToggle={(e) => handleExit(e.target.checked)}
                  />
                </div>
                <div className="relative w-full mb-3 script-mngmt">
                  <h6
                    className="text-blueGray-700 text-xl font-bold mr-5"
                    style={h6style}
                  >
                    <Link to="/admin/stories">Stories </Link>
                  </h6>
                  <ActiveSwitch
                    disabled={disabled}
                    onColor="#0ea5e9"
                    checked={story.active}
                    name="story"
                    handleToggle={(e) => handleStory(e.target.checked)}
                  />
                </div>
                <div className="relative w-full mb-3 script-mngmt">
                  <h6
                    className="text-blueGray-700 text-xl font-bold mr-5"
                    style={h6style}
                  >
                    <Link to="/admin/opportunity">Opportunity </Link>
                  </h6>
                  <ActiveSwitch
                    disabled={disabled}
                    onColor="#0ea5e9"
                    checked={oppor.active}
                    name="oppor"
                    handleToggle={(e) => handleOppor(e.target.checked)}
                  />
                </div>
                <div className="relative w-full mb-3 script-mngmt">
                  <h6
                    className="text-blueGray-700 text-xl font-bold mr-5"
                    style={h6style}
                  >
                    <Link to="/admin/spinwheel">Spin Wheel </Link>
                  </h6>
                  <ActiveSwitch
                    disabled={disabled}
                    onColor="#0ea5e9"
                    checked={spinWheel.active}
                    name="spinWheel"
                    handleToggle={(e) => handleSpinWheel(e.target.checked)}
                  />
                </div>
                <div className="relative w-full mb-3 script-mngmt">
                  <h6
                    className="text-blueGray-700 text-xl font-bold mr-5"
                    style={h6style}
                  >
                    <Link to="/admin/popularsearches"> Popular Search</Link>{" "}
                  </h6>
                  <ActiveSwitch
                    disabled={disabled}
                    onColor="#0ea5e9"
                    checked={popularSearch.active}
                    name="popularSearch"
                    handleToggle={(e) => handlePopularSearch(e.target.checked)}
                  />
                </div>
              </div>
            </div>
            {message}
          </form>
        </div>
      </div>
    </>
  );
}
