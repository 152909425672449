import ActiveSwitch from "components/Inputs/ActiveSwitch";
import { CardLoading } from "components/Inputs/Loading";
import { alerts } from "helpers/Alerts";
import React, { useEffect, useRef, useState } from "react";
import AuthService from "services/auth.service";
import Services from "services/bufooter.service";

// components
import { h6style } from "./style";

export default function CardBuFooter(props) {
  const [id, setId] = useState("");
  const [selector, setSelector] = useState("id or classname");
  const [position, setPosition] = useState("afterend");
  const [textColor, setTextColor] = useState("#232323");
  const [bgColor, setBgColor] = useState("#efefef");
  const [fontSize, setFontSize] = useState(12);
  const [text, setText] = useState("Performance Marketing");
  const [viewStyle, setViewStyle] = useState("banner");
  const [url, setUrl] = useState(
    "https://businessup.com.tr/?utm_source=%b&amp;utm_medium=footer&amp;utm_campaign=footer"
  );
  const [message, setMessage] = useState("");
  const [active, setActive] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const currentUser = AuthService.getCurrentUser();
  const mountedRef = useRef(true);

  useEffect(() => {
    Services.get(currentUser.user.id).then(
      (res) => {
        if (!mountedRef.current) return null;
        setId(res.data.id);
        setSelector(res.data.selector);
        setPosition(res.data.position);
        setTextColor(res.data.textColor);
        setBgColor(res.data.bgColor);
        setFontSize(res.data.fontSize);
        setText(res.data.text);
        setViewStyle(res.data.viewStyle || "banner");
        setUrl(res.data.url);
        setActive(res.data.active);
        setDisabled(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        Services.create(
          selector,
          position,
          textColor,
          bgColor,
          fontSize,
          text,
          viewStyle,
          url,
          false,
          currentUser.user.id
        ).then((res) => {
          setId(res.data.id);
          setDisabled(false);
        });
      }
    );
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);

  function submit(e) {
    e.preventDefault();
    setDisabled(true);
    Services.update(
      selector,
      position,
      textColor,
      bgColor,
      fontSize,
      text,
      viewStyle,
      url,
      active,
      currentUser.user.id,
      id
    ).then(
      (res) => {
        setMessage(alerts.positive);
        setDisabled(false);
        setTimeout(() => {
          setMessage("");
        }, 2000);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setDisabled(false);
      }
    );
  }
  return (
    <>
      <CardLoading isShow={disabled} />
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold" style={h6style}>
              <ActiveSwitch
                disabled={disabled}
                onColor="#0ea5e9"
                checked={active}
                handleToggle={(e) => setActive(e.target.checked)}
              />{" "}
              BusinessUp! Footer
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={submit}>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Settings
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-8/12 px-4 py-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    View Style
                  </label>
                  <input
                    type="radio"
                    id="banner"
                    name="viewStyle"
                    value="banner"
                    checked={viewStyle === "banner"}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                    onChange={(e) => setViewStyle(e.target.value)}
                  />
                  <label htmlFor="banner"> Banner</label>
                  &nbsp;
                  <input
                    type="radio"
                    id="text"
                    name="viewStyle"
                    value="text"
                    checked={viewStyle === "text"}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                    onChange={(e) => setViewStyle(e.target.value)}
                  />
                  <label htmlFor="text"> Text</label>
                </div>
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Selector
                  </label>
                  <input
                    type="text"
                    className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={selector}
                    onChange={(e) => setSelector(e.target.value)}
                    name="selector"
                    required
                    disabled={disabled}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Position
                  </label>
                  <select
                    type="text"
                    className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                    name="position"
                    required
                    disabled={disabled}
                  >
                    <option value="beforebegin">beforebegin</option>
                    <option value="afterbegin">afterbegin</option>
                    <option value="afterend">afterend</option>
                    <option value="beforeend">beforeend</option>
                  </select>
                </div>

                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Background Color
                  </label>
                  <input
                    type="color"
                    value={bgColor}
                    onChange={(e) => setBgColor(e.target.value)}
                    name="bgColor"
                    required
                    disabled={disabled}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Text Color
                  </label>
                  <input
                    type="color"
                    value={textColor}
                    onChange={(e) => setTextColor(e.target.value)}
                    name="textColor"
                    required
                    disabled={disabled}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Text Font Size (px)
                  </label>
                  <input
                    type="number"
                    className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={fontSize}
                    onChange={(e) => setFontSize(e.target.value)}
                    name="fontSize"
                    required
                    disabled={disabled}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Text
                  </label>
                  <input
                    type="text"
                    className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    name="text"
                    required
                    disabled={disabled}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    URL
                  </label>
                  <input
                    type="text"
                    className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    name="url"
                    required
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>
            <button
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="submit"
              disabled={disabled}
            >
              Save
            </button>
            {message}
          </form>
        </div>
      </div>
    </>
  );
}
