import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

//helpers

import RouteAuthenticated from "./helpers/AuthenticatedRoute";
import RouteUnauthenticated from "./helpers/UnauthenticatedRoute";

// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

// views without layouts

import Landing from "views/Landing.js";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Switch>
      {/* add routes with layouts */}
      <RouteAuthenticated path="/admin" component={Admin} />
      <RouteUnauthenticated path="/auth" component={Auth} />
      {/* add routes without layouts */}
      <Route path="/" exact component={Landing} />
      {/* add redirect for first page */}
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>
);
