import ActiveSwitch from "components/Inputs/ActiveSwitch";
import { CardLoading } from "components/Inputs/Loading";
import { alerts } from "helpers/Alerts";
import { uuidv4 } from "helpers/uuidv4";
import React, { useEffect, useRef, useState } from "react";
import AuthService from "services/auth.service";
import ExitPopupService from "services/exitpopup.service";

// components
import { h6style } from "./style";

export default function CardExitPopup(props) {
  const [answers, setAnswers] = useState([]);
  const [type, setType] = useState("");
  const [url, setUrl] = useState("");
  const [image, setImage] = useState("");
  const [exitPopupId, setExitPopupId] = useState("");
  const [message, setMessage] = useState("");
  const [day, setDay] = useState(1);
  const [containerBgColor, setContainerBgColor] = useState("");
  const [containerBorderRadius, setContainerBorderRadius] = useState(5);
  const [sendBtnBgColor, setSendBtnBgColor] = useState("");
  const [sendBtnColor, setSendBtnColor] = useState("");
  const [sendBtnBorderRadius, setSendBtnBorderRadius] = useState(5);
  const [sendBtnValue, setSendBtnValue] = useState("Gönder");
  const [question, setQuestion] = useState("");
  const [active, setActive] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const currentUser = AuthService.getCurrentUser();
  const mountedRef = useRef(true);

  let handleChange = (i, e) => {
    let newTitles = [...answers];
    newTitles[i].id = uuidv4();
    newTitles[i].value = e.target.value;
    setAnswers(newTitles);
  };

  let addFormFields = () => {
    if (answers.length < 20) setAnswers([...answers, { value: "" }]);
    else setMessage(alerts.surveryAnswersMax);
  };

  const removeFormFields = (i) => {
    const newTitles = [...answers];
    newTitles.splice(i, 1);
    setAnswers(newTitles);
  };
  useEffect(() => {
    ExitPopupService.getExitPopup(currentUser.user.id).then(
      (res) => {
        if (!mountedRef.current) return null;
        setUrl(res.data.url);
        setImage(res.data.image);
        setDay(res.data.day);
        setType(res.data.type);
        setContainerBgColor(res.data.containerBgColor);
        setContainerBorderRadius(res.data.containerBorderRadius);
        setSendBtnBgColor(res.data.sendBtnBgColor);
        setSendBtnColor(res.data.sendBtnColor);
        setSendBtnBorderRadius(res.data.sendBtnBorderRadius);
        setSendBtnValue(res.data.sendBtnValue);
        setAnswers(res.data.answers || []);
        setQuestion(res.data.question);
        setActive(res.data.active);
        setExitPopupId(res.data.id);
        setDisabled(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setDisabled(false);
      }
    );
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);

  function exitPopupSubmit(e) {
    e.preventDefault();
    setDisabled(true);
    ExitPopupService.updateExitPopup(
      url,
      image,
      day,
      type,
      containerBgColor,
      containerBorderRadius,
      sendBtnBgColor,
      sendBtnColor,
      sendBtnBorderRadius,
      sendBtnValue,
      answers,
      question,
      active,
      currentUser.user.id,
      exitPopupId
    ).then(
      (res) => {
        setMessage(alerts.positive);
        setDisabled(false);
        setTimeout(() => {
          setMessage("");
        }, 2000);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setDisabled(false);
        ExitPopupService.createExitPopup(
          url,
          image,
          day,
          type,
          containerBgColor,
          containerBorderRadius,
          sendBtnBgColor,
          sendBtnColor,
          sendBtnBorderRadius,
          sendBtnValue,
          answers,
          question,
          active,
          currentUser.user.id
        ).then(
          (res) => {
            setMessage(alerts.positive);
            setDisabled(false);
            setExitPopupId(res.data.id);
            setTimeout(() => {
              setMessage("");
            }, 2000);
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            setMessage(resMessage);
            setDisabled(false);
          }
        );
      }
    );
  }
  return (
    <>
      <CardLoading isShow={disabled} />
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold" style={h6style}>
              <ActiveSwitch
                disabled={disabled}
                onColor="#0ea5e9"
                checked={active}
                handleToggle={(e) => setActive(e.target.checked)}
              />{" "}
              Exit Popup
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={exitPopupSubmit} encType="multipart/form-data">
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Exit Popup
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-8/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Type
                  </label>
                  <input
                    type="radio"
                    id="image"
                    name="type"
                    value="image"
                    checked={type === "image"}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                    onChange={(e) => setType(e.target.value)}
                  />
                  <label htmlFor="image"> Image</label>
                  &nbsp;
                  <input
                    type="radio"
                    id="survey"
                    name="type"
                    value="survey"
                    checked={type === "survey"}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                    onChange={(e) => setType(e.target.value)}
                  />
                  <label htmlFor="survey"> Survey</label>
                </div>
                {type === "image" ? (
                  <>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        URL
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        required
                        disabled={disabled}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Image
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        onChange={(e) => setImage(e.target.value)}
                        disabled={disabled}
                        value={image}
                      />
                      <span>
                        <img src={image} alt="uploaded" width="200px" />
                      </span>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {type === "survey" ? (
                  <>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Background Color
                      </label>
                      <input
                        type="color"
                        onChange={(e) => setContainerBgColor(e.target.value)}
                        disabled={disabled}
                        value={containerBgColor}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Border Radius
                      </label>
                      <input
                        type="number"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        onChange={(e) =>
                          setContainerBorderRadius(e.target.value)
                        }
                        disabled={disabled}
                        value={containerBorderRadius}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Button Background Color
                      </label>
                      <input
                        type="color"
                        onChange={(e) => setSendBtnBgColor(e.target.value)}
                        disabled={disabled}
                        value={sendBtnBgColor}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Button Color
                      </label>
                      <input
                        type="color"
                        onChange={(e) => setSendBtnColor(e.target.value)}
                        disabled={disabled}
                        value={sendBtnColor}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Button Border Radius
                      </label>
                      <input
                        type="number"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        onChange={(e) => setSendBtnBorderRadius(e.target.value)}
                        disabled={disabled}
                        value={sendBtnBorderRadius}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Button Text
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        onChange={(e) => setSendBtnValue(e.target.value)}
                        disabled={disabled}
                        value={sendBtnValue}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Question
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        required
                        disabled={disabled}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Answers
                      </label>

                      <button
                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => addFormFields()}
                      >
                        <i className="fas fa-plus"></i>
                      </button>
                    </div>
                    {answers.map((title, index) => (
                      <div key={index} className="w-full lg:w-12/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Answer {index + 1}
                          </label>
                          <input
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={title.value}
                            onChange={(e) => handleChange(index, e)}
                            required
                            disabled={disabled}
                          />
                          <span
                            className="z-10 h-full leading-snug font-normal absolute text-center text-red-700 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3"
                            onClick={() => removeFormFields(index)}
                            style={{ cursor: "pointer", color: "red" }}
                          >
                            <i className="fas fa-minus"></i>
                          </span>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  ""
                )}
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Expired Day
                  </label>
                  <input
                    type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={(e) => setDay(e.target.value)}
                    disabled={disabled}
                    value={day}
                  />
                </div>
              </div>
            </div>
            <button
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="submit"
              disabled={disabled}
            >
              Save
            </button>
            {message}
          </form>
        </div>
      </div>
    </>
  );
}
