/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import NotificationDropdown from "components/Dropdowns/NotificationDropdown.js";
import UserDropdown from "components/Dropdowns/UserDropdown.js";
import AuthService from "services/auth.service";

export default function AdminSidebar() {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm  font-bold p-4 px-0"
            to="/"
          >
            Administration Panel
          </Link>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <NotificationDropdown />
            </li>
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm  font-bold p-4 px-0"
                    to="/"
                  >
                    Administration Panel
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            <form className="mt-6 mb-4 md:hidden">
              <div className="mb-3 pt-0">
                <input
                  type="text"
                  placeholder="Search"
                  className="border-0 px-3 py-2 h-12 border border-solid  border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                />
              </div>
            </form>

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            <h6 className="md:min-w-full text-blueGray-500 text-sm  font-bold block pt-1 pb-4 no-underline">
              Admin Layout Pages
            </h6>
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              {/*
              <li className="items-center">
                <Link
                  className={
                    "text-sm  py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/dashboard") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/dashboard"
                >
                  <i
                    className={
                      "fas fa-tv mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/dashboard") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Dashboard
                </Link>
              </li>
              
              <li className="items-center">
                <Link
                  className={
                    "text-sm  py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/settings") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/settings"
                >
                  <i
                    className={
                      "fas fa-tools mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/settings") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Settings
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-sm  py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/tables") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/tables"
                >
                  <i
                    className={
                      "fas fa-table mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/tables") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Tables
                </Link>
              </li>      
              */}

              <li className="items-center">
                <Link
                  className={
                    "text-sm  py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/users") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/users"
                >
                  <i
                    className={
                      "fas fa-users mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/users") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Customers
                </Link>
              </li>
            </ul>
            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            <h6 className="md:min-w-full text-blueGray-500 text-sm  font-bold block pt-1 pb-4 no-underline">
              Scenarios
            </h6>
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
              <li className="items-center">
                <Link
                  className={
                    "text-sm  py-3 font-bold block " +
                    (window.location.href.indexOf(
                      "/admin/title-changer-list"
                    ) !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/title-changer-list"
                >
                  <i
                    className={
                      "fas fa-heading mr-2 text-sm " +
                      (window.location.href.indexOf(
                        "/admin/title-changer-list"
                      ) !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Title Changer List
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-sm  py-3 font-bold block " +
                    (window.location.href.indexOf(
                      "/admin/top-banner-changer-list"
                    ) !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/top-banner-changer-list"
                >
                  <i
                    className={
                      "far fa-clock mr-2 text-sm " +
                      (window.location.href.indexOf(
                        "/admin/top-banner-changer-list"
                      ) !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Top Banner & Countdown List
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-sm  py-3 font-bold block " +
                    (window.location.href.indexOf(
                      "/admin/free-shipping-list"
                    ) !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/free-shipping-list"
                >
                  <i
                    className={
                      "fas fa-truck-moving mr-2 text-sm " +
                      (window.location.href.indexOf(
                        "/admin/free-shipping-list"
                      ) !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Shipping Free Notice List
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-sm  py-3 font-bold block " +
                    (window.location.href.indexOf(
                      "/admin/watched-bought-list"
                    ) !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/watched-bought-list"
                >
                  <i
                    className={
                      "far fa-eye mr-2 text-sm " +
                      (window.location.href.indexOf(
                        "/admin/watched-bought-list"
                      ) !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Social Proof List
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-sm  py-3 font-bold block " +
                    (window.location.href.indexOf(
                      "/admin/opening-popup-list"
                    ) !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/opening-popup-list"
                >
                  <i
                    className={
                      "far fa-window-restore mr-2 text-sm " +
                      (window.location.href.indexOf(
                        "/admin/opening-popup-list"
                      ) !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Opening Popup List
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-sm  py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/exit-popup-list") !==
                    -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/exit-popup-list"
                >
                  <i
                    className={
                      "far fa-window-restore mr-2 text-sm " +
                      (window.location.href.indexOf(
                        "/admin/exit-popup-list"
                      ) !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Exit Intent List
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-sm  py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/stories-list") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/stories-list"
                >
                  <i
                    className={
                      "far fa-circle mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/stories-list") !==
                      -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Stories List
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-sm  py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/opportunity-list") !==
                    -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/opportunity-list"
                >
                  <i
                    className={
                      "fas fa-bell mr-2 text-sm " +
                      (window.location.href.indexOf(
                        "/admin/opportunity-list"
                      ) !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Opportunity List
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-sm py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/spinwheel-list") !==
                    -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/spinwheel-list"
                >
                  <i
                    className={
                      "far fa-life-ring mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/spinwheel-list") !==
                      -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Spin Wheel List
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-sm py-3 font-bold block " +
                    (window.location.href.indexOf(
                      "/admin/popularsearches-list"
                    ) !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/popularsearches-list"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <i
                    className={
                      "fas fa-fire mr-2 text-sm " +
                      (window.location.href.indexOf(
                        "/admin/popularsearches-list"
                      ) !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Populer Searches List
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-sm py-3 font-bold block " +
                    (window.location.href.indexOf(
                      "/admin/trendyolcomments-list"
                    ) !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/trendyolcomments-list"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <i
                    className={
                      "far fa-comments mr-2 text-sm " +
                      (window.location.href.indexOf(
                        "/admin/trendyolcomments-list"
                      ) !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Trendyol Comments List
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-sm py-3 font-bold block " +
                    (window.location.href.indexOf(
                      "/admin/smart-app-banner-list"
                    ) !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/smart-app-banner-list"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <i
                    className={
                      "fas fa-mobile mr-2 text-sm " +
                      (window.location.href.indexOf(
                        "/admin/smart-app-banner-list"
                      ) !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Smart App Banner List
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-sm py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/snow-effect-list") !==
                    -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/snow-effect-list"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <i
                    aria-hidden="true"
                    className={
                      "fa fa-snowflake mr-2 text-sm " +
                      (window.location.href.indexOf(
                        "/admin/snow-effect-list"
                      ) !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Snow Effect List
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-sm py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/whatsapp-list") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/whatsapp-list"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <i
                    className={
                      "fa fa-comment mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/whatsapp-list") !==
                      -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Order via WhatsApp List
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-sm py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/bufooter-list") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/bufooter-list"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <i
                    className={
                      "fa fa-window-minimize mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/bufooter-list") !==
                      -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  BusinessUp! Footer List
                </Link>
              </li>
            </ul>
            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Options
            </h6>
            {/* Navigation */}
            <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
              <li className="items-center">
                <Link
                  className={
                    "text-sm py-3 font-bold block " +
                    (window.location.href.indexOf(
                      "/admin/preview-options-list"
                    ) !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/preview-options-list"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <i
                    className={
                      "fa fa-desktop mr-2 text-sm " +
                      (window.location.href.indexOf(
                        "/admin/preview-options-list"
                      ) !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Preview List
                </Link>
              </li>
            </ul>
            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading 
            <h6 className="md:min-w-full text-blueGray-500 text-sm  font-bold block pt-1 pb-4 no-underline">
              Auth Layout Pages
            </h6>
             Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
              {/**
              <li className="items-center">
                <Link
                  className="text-blueGray-700 hover:text-blueGray-500 text-sm  py-3 font-bold block"
                  to="/auth/login"
                >
                  <i className="fas fa-fingerprint text-blueGray-400 mr-2 text-sm"></i>{" "}
                  Login
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className="text-blueGray-700 hover:text-blueGray-500 text-sm  py-3 font-bold block"
                  to="/auth/register"
                >
                  <i className="fas fa-clipboard-list text-blueGray-300 mr-2 text-sm"></i>{" "}
                  Register
                </Link>
              </li>
               
              <li className="items-center" onClick={() => AuthService.logout()}>
                <Link
                  className="text-blueGray-700 hover:text-blueGray-500 text-sm  py-3 font-bold block"
                  to="/auth/login"
                >
                  <i className="fas fa-sign-out-alt text-blueGray-400 mr-2 text-sm"></i>{" "}
                  Logout
                </Link>
              </li>
              */}
            </ul>

            {/* Divider 
            <hr className="my-4 md:min-w-full" />
            {/* Heading 
            <h6 className="md:min-w-full text-blueGray-500 text-sm  font-bold block pt-1 pb-4 no-underline">
              No Layout Pages
            </h6>
            */}
            {/* Navigation 

            <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
              <li className="items-center">
                <Link
                  className="text-blueGray-700 hover:text-blueGray-500 text-sm  py-3 font-bold block"
                  to="/landing"
                >
                  <i className="fas fa-newspaper text-blueGray-400 mr-2 text-sm"></i>{" "}
                  Landing Page
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className="text-blueGray-700 hover:text-blueGray-500 text-sm  py-3 font-bold block"
                  to="/profile"
                >
                  <i className="fas fa-user-circle text-blueGray-400 mr-2 text-sm"></i>{" "}
                  Profile Page
                </Link>
              </li>
            </ul>
            */}
          </div>
        </div>
      </nav>
    </>
  );
}
