import React from "react";

// components

import CardPopulerSearches from "components/Cards/CardPopulerSearches";

export default function PopularSearches() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <CardPopulerSearches />
        </div>
      </div>
    </>
  );
}
