import ActiveSwitch from "components/Inputs/ActiveSwitch";
import { CardLoading } from "components/Inputs/Loading";
import { alerts } from "helpers/Alerts";
import React, { useEffect, useRef, useState } from "react";
import AuthService from "services/auth.service";
import StoriesService from "services/stories.service";
import UsersService from "services/users.service";

// components
import { h6style } from "./style";
export default function CardStories(props) {
  const [story, setStory] = useState([]);
  const [selector, setSelector] = useState("");
  const [storyName, setStoryName] = useState("");
  const [storiesId, setStoriesId] = useState("");
  const [allowURLInputs, setAllowURLInputs] = useState([{ value: "" }]);
  const [position, setPosition] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [message, setMessage] = useState("");
  const [active, setActive] = useState(false);
  const currentUser = AuthService.getCurrentUser();
  const [ownerId, setOwnerId] = useState("");
  const [users, setUsers] = useState([]);
  const [options, setOptions] = useState({
    limit: null,
    page: null,
    sortBy: "company:asc",
    company: null,
    role: "user",
    isEmailVerified: true,
  });
  const mountedRef = useRef(true);

  const dragItem = useRef();
  const dragOverItem = useRef();
  const dragStart = (e, position) => {
    dragItem.current = position;
    console.log(e.target.innerHTML);
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    console.log(e.target.innerHTML);
  };
  const drop = (e) => {
    const copyListItems = [...story];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setStory(copyListItems);
  };

  const getId = ownerId.length > 0 ? ownerId : currentUser.user.id;

  useEffect(() => {
    const { limit, page, sortBy, role, isEmailVerified, company } = options;
    UsersService.getUsers(
      limit,
      page,
      sortBy,
      role,
      isEmailVerified,
      company
    ).then(
      (res) => {
        console.log(res.data);
        setUsers(res.data.results);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(resMessage);
      }
    );
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, [options]);

  function setSearch(value) {
    let updated = { ...options };
    updated.company = value.length === 0 ? null : value;
    setOptions(updated);
    setOwnerId(value);
  }

  function handleChangeStory(value, name, index) {
    let newStory = [...story];
    newStory[index][name] = value;
    setStory(newStory);
  }
  let addFormFields = () => {
    if (story.length < 30)
      setStory([...story, { title: "", url: "", image: "" }]);
    else setMessage(alerts.storiesMax);
  };
  const removeFormFields = (i) => {
    const newTitles = [...story];
    newTitles.splice(i, 1);
    setStory(newTitles);
  };

  let handleChangeURL = (i, e) => {
    let newURL = [...allowURLInputs];
    newURL[i].value = e.target.value;
    setAllowURLInputs(newURL);
  };

  let addFormFieldsURL = () => {
    if (allowURLInputs.length < 10)
      setAllowURLInputs([...allowURLInputs, { value: "" }]);
    else setMessage(alerts.titleChangerMax);
  };

  const removeFormFieldsURL = (i) => {
    const newURL = [...allowURLInputs];
    newURL.splice(i, 1);
    setAllowURLInputs(newURL);
  };
  function storiesSubmit(e) {
    e.preventDefault();
    if (story.length < 2) {
      setMessage(alerts.storiesMin);
    } else {
      if (storiesId === "") {
        StoriesService.createStories(
          storyName,
          allowURLInputs,
          story,
          selector,
          position,
          active,
          getId
        ).then(
          (res) => {
            setMessage(alerts.positive);
            setStoriesId(res.data.id);
            setTimeout(() => {
              setMessage("");
            }, 2000);
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            setMessage(resMessage);
          }
        );
      } else {
        setDisabled(true);
        StoriesService.updateStories(
          storyName,
          allowURLInputs,
          story,
          selector,
          position,
          active,
          getId,
          storiesId
        ).then(
          (res) => {
            setMessage(alerts.positive);
            setDisabled(false);
            setTimeout(() => {
              setMessage("");
            }, 2000);
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            setMessage(resMessage);
            setDisabled(false);
          }
        );
      }
    }
  }
  return (
    <>
      <CardLoading isShow={disabled} />
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold" style={h6style}>
              <ActiveSwitch
                onColor="#0ea5e9"
                checked={active}
                handleToggle={(e) => setActive(e.target.checked)}
              />{" "}
              Stories
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={storiesSubmit}>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Settings
            </h6>
            <div className="flex flex-wrap">
              {currentUser.user.role === "adminisko" && (
                <div className="w-full lg:w-8/12 px-4 py-4">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Select Company
                  </label>
                  <input
                    type="search"
                    className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={options.name}
                    onChange={(e) => setSearch(e.target.value)}
                    name="search"
                    required
                    list="userList"
                  />
                  <datalist id="userList">
                    {users.map((user, index) => (
                      <option key={index} value={user.id}>
                        {user.company}
                      </option>
                    ))}
                  </datalist>
                </div>
              )}

              <div className="w-full lg:w-8/12 px-4 py-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Story Name
                  </label>
                  <input
                    type="text"
                    className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={storyName}
                    onChange={(e) => setStoryName(e.target.value)}
                    name="storyName"
                    required
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Allow URL
                  </label>
                  <button
                    className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => addFormFieldsURL()}
                  >
                    <i className="fas fa-plus"></i>
                  </button>
                </div>
                <div className="flex flex-wrap">
                  {allowURLInputs.map((allowURL, index) => (
                    <div key={index} className="w-full lg:w-8/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          URL {index + 1}
                        </label>
                        <input
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          value={allowURL.value}
                          onChange={(e) => handleChangeURL(index, e)}
                          required
                        />
                        <span
                          className="z-10 h-full leading-snug font-normal absolute text-center text-red-700 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3"
                          onClick={() => removeFormFieldsURL(index)}
                          style={{ cursor: "pointer", color: "red" }}
                        >
                          <i className="fas fa-minus"></i>
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Selector
                  </label>
                  <input
                    type="text"
                    className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={selector}
                    onChange={(e) => setSelector(e.target.value)}
                    name="selector"
                    required
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Position
                  </label>
                  <select
                    type="text"
                    className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                    name="position"
                    required
                  >
                    <option value="beforebegin">beforebegin</option>
                    <option value="afterbegin">afterbegin</option>
                    <option value="afterend">afterend</option>
                    <option value="beforeend">beforeend</option>
                  </select>
                </div>
              </div>
              {story.map((s, index) => (
                <div
                  className="w-full lg:w-8/12 px-4 card-stories"
                  onDragStart={(e) => dragStart(e, index)}
                  onDragEnter={(e) => dragEnter(e, index)}
                  onDragEnd={drop}
                  key={index}
                  draggable
                >
                  <span className="card-stories-title">
                    {index + 1}. Story{" "}
                    <i
                      onClick={() => removeFormFields(index)}
                      className="fas fa-minus"
                    ></i>
                  </span>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Title
                    </label>
                    <input
                      type="text"
                      className="border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={s.title}
                      onChange={(e) =>
                        handleChangeStory(e.target.value, e.target.name, index)
                      }
                      name="title"
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      URL
                    </label>
                    <input
                      type="text"
                      className="border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={s.url}
                      onChange={(e) =>
                        handleChangeStory(e.target.value, e.target.name, index)
                      }
                      name="url"
                      required
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Image
                    </label>
                    <input
                      type="text"
                      className="border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={s.image}
                      onChange={(e) =>
                        handleChangeStory(e.target.value, e.target.name, index)
                      }
                      name="image"
                      required
                    />
                  </div>
                </div>
              ))}
            </div>
            <button
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-3 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => addFormFields()}
            >
              <i className="fas fa-plus"></i>
            </button>
            <button
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="submit"
            >
              Save
            </button>
            {message}
          </form>
        </div>
      </div>
    </>
  );
}
