import React from "react";

// components

import CardSmartAppBannersEdit from "components/AdminCards/CardSmartAppBannersEdit";

export default function SmartAppBannersEdit() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <CardSmartAppBannersEdit />
        </div>
      </div>
    </>
  );
}
