import React from "react";

// components

import CardSurvey from "components/Cards/CardSurvey";

export default function Survey() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <CardSurvey />
        </div>
      </div>
    </>
  );
}
