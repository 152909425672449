import ActiveSwitch from "components/Inputs/ActiveSwitch";
import { CardLoading } from "components/Inputs/Loading";
import snowEffect from "components/Scenarios/snowEffect";
import { alerts } from "helpers/Alerts";
import React, { useEffect, useRef, useState } from "react";
import AuthService from "services/auth.service";
import Services from "services/snoweffect.service";
import CardPreview from "./CardPreview";

// components
import { h6style } from "./style";

export default function CardSnowEffect(props) {
  const [id, setId] = useState("");
  const [urlAllowOrBlock, setUrlAllowOrBlock] = useState(false);
  const [urls, setUrls] = useState([]);
  const [message, setMessage] = useState("");
  const [active, setActive] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const currentUser = AuthService.getCurrentUser();
  const mountedRef = useRef(true);
  const [ri, setRi] = useState(0);
  function refreshIframe() {
    let i = ri + 1;
    setRi(i);
    console.log(ri);
  }
  useEffect(() => {
    const doc = document.getElementById("preview");
    const obj = { urls, urlAllowOrBlock, active };
    snowEffect(doc, obj);
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, [ri]);

  useEffect(() => {
    Services.get(currentUser.user.id).then(
      (res) => {
        if (!mountedRef.current) return null;
        setId(res.data.id);
        setUrls(res.data.urls || []);
        setUrlAllowOrBlock(res.data.urlAllowOrBlock);
        setActive(res.data.active);
        setDisabled(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        Services.create(urls, urlAllowOrBlock, false, currentUser.user.id).then(
          (res) => {
            setDisabled(false);
          }
        );
      }
    );
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);

  function submit(e) {
    e.preventDefault();
    setDisabled(true);
    Services.update(
      urls,
      urlAllowOrBlock,
      active,
      currentUser.user.id,
      id
    ).then(
      (res) => {
        setMessage(alerts.positive);
        setDisabled(false);
        setTimeout(() => {
          setMessage("");
        }, 2000);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setDisabled(false);
      }
    );
  }

  let handleChangeURL = (i, e) => {
    let newURL = [...urls];
    newURL[i].value = e.target.value;
    setUrls(newURL);
  };

  let addFormFieldsURL = () => {
    if (urls.length < 10) setUrls([...urls, { value: "" }]);
    else setMessage(alerts.titleChangerMax);
  };

  const removeFormFieldsURL = (i) => {
    const newURL = [...urls];
    newURL.splice(i, 1);
    setUrls(newURL);
  };

  return (
    <>
      <CardLoading isShow={disabled} />
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold" style={h6style}>
              <ActiveSwitch
                disabled={disabled}
                onColor="#0ea5e9"
                checked={active}
                handleToggle={(e) => setActive(e.target.checked)}
              />{" "}
              Snow Effect
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={submit}>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Settings
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-8/12 px-4 py-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    {urlAllowOrBlock === true ? "Allow" : "Block"}
                  </label>
                  <input
                    type="checkbox"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                    value={urlAllowOrBlock}
                    checked={urlAllowOrBlock}
                    onChange={(e) => setUrlAllowOrBlock(e.target.checked)}
                    disabled={disabled}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    URL
                  </label>
                  <button
                    className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => addFormFieldsURL()}
                  >
                    <i className="fas fa-plus"></i>
                  </button>
                </div>
                <div className="flex flex-wrap">
                  {urls.map((blockedURL, index) => (
                    <div key={index} className="w-full lg:w-8/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          URL {index + 1}
                        </label>
                        <input
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          value={blockedURL.value}
                          onChange={(e) => handleChangeURL(index, e)}
                          required
                          disabled={disabled}
                        />
                        <span
                          className="z-10 h-full leading-snug font-normal absolute text-center text-red-700 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3"
                          onClick={() => removeFormFieldsURL(index)}
                          style={{ cursor: "pointer", color: "red" }}
                        >
                          <i className="fas fa-minus"></i>
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4 py-4">
                <CardPreview refresh={refreshIframe} />
              </div>
            </div>

            <button
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="submit"
              disabled={disabled}
            >
              Save
            </button>
            {message}
          </form>
        </div>
      </div>
    </>
  );
}
