const CookieService = {
  setCookie(name, value, days) {
    let expires = "";

    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }

    document.cookie = name + "=" + (value || "") + expires + ";";
  },

  getCookie(name) {
    const cookies = document.cookie.split(";");

    for (const cookie of cookies) {
      if (cookie.indexOf(name + "=") > -1) {
        return cookie.split("=")[1];
      }
    }

    return null;
  },
};
const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};
const API = "https://api.iyosa.com/v1";
const CDN = "https://cdn.iyosa.com";

const stringToHTML = (str) => {
  var parser = new DOMParser();
  var doc = parser.parseFromString(str, "text/html");
  return doc;
};

export { CookieService, uuidv4, API, CDN, stringToHTML };
