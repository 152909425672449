import api from "./api";

const createFreeShipping = (
  title,
  limit,
  fontSize,
  backgroundColor,
  borderRadius,
  color,
  textAlign,
  barBgColor,
  freeShippingMsg,
  freeShippingMsgs,
  selector,
  position,
  totalSelector,
  totalFind,
  active,
  owner
) => {
  return api.post("/freeshipping", {
    title,
    limit,
    fontSize,
    backgroundColor,
    borderRadius,
    color,
    textAlign,
    barBgColor,
    freeShippingMsg,
    freeShippingMsgs,
    selector,
    position,
    totalSelector,
    totalFind,
    active,
    owner,
  });
};

const updateFreeShipping = (
  title,
  limit,
  fontSize,
  backgroundColor,
  borderRadius,
  color,
  textAlign,
  barBgColor,
  freeShippingMsg,
  freeShippingMsgs,
  selector,
  position,
  totalSelector,
  totalFind,
  active,
  owner,
  id
) => {
  return api.patch(`/freeshipping/${owner}`, {
    title,
    limit,
    fontSize,
    backgroundColor,
    borderRadius,
    color,
    textAlign,
    barBgColor,
    freeShippingMsg,
    freeShippingMsgs,
    selector,
    position,
    totalSelector,
    totalFind,
    active,
    owner,
    id,
  });
};
const updateActive = (active, owner, id) => {
  return api.patch(`/freeshipping/status/${owner}`, {
    active,
    id,
  });
};
const getFreeShipping = (owner) => {
  return api.get(`/freeshipping/${owner}`);
};
const getAll = (limit, page, sortBy, active, company, isEmailVerified) => {
  return api.get(`/freeshipping`, {
    params: { limit, page, sortBy, active, company, isEmailVerified },
  });
};
const FreeShippingService = {
  createFreeShipping,
  updateFreeShipping,
  getFreeShipping,
  getAll,
  updateActive,
};

export default FreeShippingService;
