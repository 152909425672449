import ActiveSwitch from "components/Inputs/ActiveSwitch";
import ImageRadioButton from "components/Inputs/ImageRadioButton";
import { CardLoading } from "components/Inputs/Loading";
import { spinWheelStyle } from "components/previewStyle";
import { alerts } from "helpers/Alerts";
import React, { useEffect, useRef, useState } from "react";
import AuthService from "services/auth.service";
import SpinWheelService from "services/spinwheel.service";

// components
import { h6style } from "./style";
export default function CardSpinWheel(props) {
  const [segments, setSegments] = useState([]);
  const [spinWheelId, setSpinWheelId] = useState("");
  const [title, setTitle] = useState("");
  const [bgColor, setBgColor] = useState("");
  const [titleColor, setTitleColor] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionColor, setDescriptionColor] = useState("");
  const [btnText, setBtnText] = useState("");
  const [btnBgColor, setBtnBgColor] = useState("");
  const [btnColor, setBtnColor] = useState("");
  const [spinWheelSize, setSpinWheelSize] = useState(0);
  const [spinWheelTextColor, setSpinWheelTextColor] = useState("");
  const [countdownLimitless, setCountdownLimitless] = useState(false);
  const [countdown, setCountdown] = useState(10);
  const [bootTime, setBootTime] = useState(1);
  const [bottomDistance, setBottomDistance] = useState(0);
  const [rightDistance, setRightDistance] = useState(0);
  const [closeCount, setCloseCount] = useState(1);
  const [days, setDays] = useState(1);
  const [blockedURLInputs, setBlockedURLInputs] = useState([]);
  const [visitor, setVisitor] = useState();
  const [visibilityStart, setVisibilityStart] = useState("");
  const [visibilityEnd, setVisibilityEnd] = useState("");
  const [cbNextDay, setCbNextDay] = useState(false);
  const [alwaysShow, setAlwaysShow] = useState(true);
  const [urlAllowOrBlock, setUrlAllowOrBlock] = useState(false);
  const [location, setLocation] = useState("");
  const [message, setMessage] = useState("");
  const [active, setActive] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [swFrame, setSwFrame] = useState("");
  const [spinWheelCenterText, setSpinWheelCenterText] = useState("");
  const [pinsNumber, setPinsNumber] = useState(0);
  const [spinWheelBorderColor, setSpinWheelBorderColor] = useState("");
  const currentUser = AuthService.getCurrentUser();
  const mountedRef = useRef(true);

  useEffect(() => {
    SpinWheelService.getSpinWheel(currentUser.user.id).then(
      (res) => {
        if (!mountedRef.current) return null;
        setSegments(res.data.segments);
        setBgColor(res.data.bgColor);
        setTitle(res.data.title);
        setTitleColor(res.data.titleColor);
        setDescription(res.data.description);
        setDescriptionColor(res.data.descriptionColor);
        setBtnText(res.data.btnText);
        setBtnBgColor(res.data.btnBgColor);
        setBtnColor(res.data.btnColor);
        setSpinWheelSize(res.data.spinWheelSize);
        setSpinWheelTextColor(res.data.spinWheelTextColor);
        setCountdownLimitless(res.data.countdownLimitless);
        setCountdown(res.data.countdown);
        setBootTime(res.data.bootTime);
        setCloseCount(res.data.closeCount);
        setDays(res.data.days);
        setBlockedURLInputs(res.data.blockedURLInputs || []);
        setVisitor(res.data.visitor);
        setBottomDistance(res.data.bottomDistance);
        setRightDistance(res.data.rightDistance);
        setVisibilityStart(res.data.visibilityStart);
        setVisibilityEnd(res.data.visibilityEnd);
        setCbNextDay(res.data.cbNextDay);
        setAlwaysShow(res.data.alwaysShow);
        setUrlAllowOrBlock(res.data.urlAllowOrBlock);
        setLocation(res.data.location);
        setSpinWheelId(res.data.id);
        setActive(res.data.active);
        setDisabled(false);
        setSwFrame(res.data.swFrame);
        setSpinWheelBorderColor(res.data.spinWheelBorderColor);
        setSpinWheelCenterText(res.data.spinWheelCenterText);
        setPinsNumber(res.data.pinsNumber);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setDisabled(false);
      }
    );
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);
  function handleChange(value, name, index) {
    let newObj = [...segments];
    newObj[index][name] = value;
    setSegments(newObj);
  }
  let addFormFields = () => {
    if (segments.length < 8)
      setSegments([
        ...segments,
        { text: "", fillStyle: "", description: "", probability: 0, code: "" },
      ]);
    else setMessage(alerts.spinWheelMax);
  };
  const removeFormFields = (i) => {
    const newObj = [...segments];
    newObj.splice(i, 1);
    setSegments(newObj);
  };
  let handleChangeURL = (i, e) => {
    let newURL = [...blockedURLInputs];
    newURL[i].value = e.target.value;
    setBlockedURLInputs(newURL);
  };

  let addFormFieldsURL = () => {
    if (blockedURLInputs.length < 10)
      setBlockedURLInputs([...blockedURLInputs, { value: "" }]);
    else setMessage(alerts.titleChangerMax);
  };

  const removeFormFieldsURL = (i) => {
    const newURL = [...blockedURLInputs];
    newURL.splice(i, 1);
    setBlockedURLInputs(newURL);
  };
  function formSubmit(e) {
    e.preventDefault();
    if (segments.length < 4) {
      setMessage(alerts.spinWheelMin);
    } else {
      let prob = segments.reduce(
        (a, v) => (a = parseInt(a) + parseInt(v.probability)),
        0
      );
      if (prob === 100) {
        setDisabled(true);
        SpinWheelService.updateSpinWheel(
          segments,
          bgColor,
          title,
          titleColor,
          description,
          descriptionColor,
          btnText,
          btnBgColor,
          btnColor,
          spinWheelSize,
          spinWheelTextColor,
          countdownLimitless,
          countdown,
          bootTime,
          closeCount,
          days,
          blockedURLInputs,
          visitor,
          bottomDistance,
          rightDistance,
          visibilityStart,
          visibilityEnd,
          cbNextDay,
          alwaysShow,
          urlAllowOrBlock,
          location,
          swFrame,
          spinWheelBorderColor,
          spinWheelCenterText,
          pinsNumber,
          active,
          currentUser.user.id,
          spinWheelId
        ).then(
          (res) => {
            setMessage(alerts.positive);
            setDisabled(false);
            setTimeout(() => {
              setMessage("");
            }, 2000);
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            setMessage(resMessage);
            setDisabled(false);
            SpinWheelService.createSpinWheel(
              segments,
              bgColor,
              title,
              titleColor,
              description,
              descriptionColor,
              btnText,
              btnBgColor,
              btnColor,
              spinWheelSize,
              spinWheelTextColor,
              countdownLimitless,
              countdown,
              bootTime,
              closeCount,
              days,
              blockedURLInputs,
              visitor,
              bottomDistance,
              rightDistance,
              visibilityStart,
              visibilityEnd,
              cbNextDay,
              alwaysShow,
              urlAllowOrBlock,
              location,
              swFrame,
              spinWheelBorderColor,
              spinWheelCenterText,
              pinsNumber,
              active,
              currentUser.user.id
            ).then(
              (res) => {
                setMessage(alerts.positive);
                setDisabled(false);
                setSpinWheelId(res.data.id);
                setTimeout(() => {
                  setMessage("");
                }, 2000);
              },
              (error) => {
                const resMessage =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
                setMessage(resMessage);
                setDisabled(false);
              }
            );
          }
        );
      } else setMessage(alerts.spinWheelProbability);
    }
  }
  const srcDoc = `
  <style>${spinWheelStyle}</style>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/latest/TweenMax.min.js"></script>
  <script src="https://cdn.jsdelivr.net/npm/winwheel@1.0.1/dist/Winwheel.min.js"></script>
  <div class="iyosa-spinwheel-popup" style="background-color: ${bgColor}; float:right">
    <div class="iyosa-spinwheel-close">×</div>
    <div width="300" height="300" class="iyosa-spinwheel-canvas" style="background-image: url('https://cdn.iyosa.com/src/assets/img/${swFrame}.png');">
      <img src="https://cdn.iyosa.com/src/assets/img/basic_pointer.png" alt="V" width="25" class="iyosa-spinwheel-pointer" />
      <canvas id="canvas" width="300" height="300"></canvas>
    </div>
    <div class="iyosa-spinwheel-content">
      <div class="iyosa-spinwheel-title" style="color:${titleColor} ;">
        ${title}
      </div>
      <div class="iyosa-spinwheel-description" style="color: ${descriptionColor};">
        ${description}
      </div>
      <div class="iyosa-spinwheel-btn-div">
        <button class="iyosa-spinwheel-btn" style="color: ${btnColor}; background-color:${btnBgColor};" onclick="startSpin()">
          ${btnText}
        </button>
      </div>
    </div>
  </div>
  <script>
            let theWheel = new Winwheel({
                'outerRadius'     : 128,        // Set outer radius so wheel fits inside the background.
                'innerRadius'     : 20,         // Make wheel hollow so segments dont go all way to center.
                'textFontSize'    : "${spinWheelSize}",         // Set default font size for the segments.
                'textFillStyle'   : "${spinWheelTextColor}",
                'textOrientation' : 'horizontal', // Make text vertial so goes down from the outside of wheel.
                'textAlignment'   : 'center',    // Align text to outside of wheel.
                'numSegments'     : ${
                  segments.length
                },         // Specify number of segments.
                'strokeStyle'     : "transparent",
                'segments'        : ${JSON.stringify(
                  segments
                )},        // Define segments including colour and text.
                
                'animation' :           // Specify the animation to use.
                {
                    'type'     : 'spinToStop',
                    'duration' : 5,
                    'spins'    : 8,
                    'callbackFinished' : alertPrize,  // Function to call whent the spinning has stopped.
                    'callbackSound'    : playSound,   // Called when the tick sound is to be played.
                    'soundTrigger'     : 'pin'        // Specify pins are to trigger the sound.
                }
            });
 
            // Loads the tick audio sound in to an audio object.
            let audio = new Audio('tick.mp3');
 
            // This function is called when the sound is to be played.
            function playSound()
            {
                // Stop and rewind the sound if it already happens to be playing.
                audio.pause();
                audio.currentTime = 0;
 
                // Play the sound.
                audio.play();
            }
            function startSpin()
            {
                // Stop any current animation.
                theWheel.stopAnimation(false);
        
                // Reset the rotation angle to less than or equal to 360 so spinning again works as expected.
                // Setting to modulus (%) 360 keeps the current position.
                theWheel.rotationAngle = theWheel.rotationAngle % 360;
        
                // Start animation.
                theWheel.startAnimation();
            }
            // Called when the animation has finished.
            function alertPrize(indicatedSegment)
            {
                // Display different message if win/lose/backrupt.
                if (indicatedSegment.text == 'LOOSE TURN') {
                    alert('Sorry but you loose a turn.');
                } else if (indicatedSegment.text == 'BANKRUPT') {
                    alert('Oh no, you have gone BANKRUPT!');
                } else {
                    alert("You have won " + indicatedSegment.text);
                }
            }
        </script>
  `;
  return (
    <>
      <CardLoading isShow={disabled} />
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold" style={h6style}>
              <ActiveSwitch
                disabled={disabled}
                onColor="#0ea5e9"
                checked={active}
                handleToggle={(e) => setActive(e.target.checked)}
              />{" "}
              Spin Wheel
            </h6>
          </div>
        </div>

        <div
          className="w-full lg:w-12/12 px-4"
          style={{ position: "sticky", top: 0, zIndex: "999999" }}
        >
          <iframe
            srcDoc={srcDoc}
            title="Preview"
            width={"100%"}
            height={"360px"}
          />
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={formSubmit}>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              View Settings
            </h6>
            <div className="w-full lg:w-8/12 px-4">
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Always Show
                </label>
                <input
                  type="checkbox"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                  value={alwaysShow}
                  checked={alwaysShow}
                  onChange={(e) => setAlwaysShow(e.target.checked)}
                  disabled={disabled}
                />
              </div>
              {alwaysShow === false ? (
                <>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Visibility Start
                    </label>
                    <input
                      type="time"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={visibilityStart}
                      onChange={(e) => setVisibilityStart(e.target.value)}
                      disabled={disabled}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Visibility End
                    </label>
                    <input
                      type="time"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring  ease-linear transition-all duration-150"
                      value={visibilityEnd}
                      onChange={(e) => setVisibilityEnd(e.target.value)}
                      disabled={disabled}
                    />
                    <label
                      className=" uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="cbNextDay"
                    >
                      &nbsp;&nbsp;End is next day&nbsp;&nbsp;&nbsp;
                    </label>
                    <input
                      id="cbNextDay"
                      type="checkbox"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring  ease-linear transition-all duration-150"
                      value={cbNextDay}
                      checked={cbNextDay}
                      onChange={(e) => setCbNextDay(e.target.checked)}
                      disabled={disabled}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
              <div className="relative w-full mb-3">
                <label
                  htmlFor="bgColor"
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                  Popup Background Color
                </label>
                <input
                  disabled={disabled}
                  type="color"
                  id="bgColor"
                  name="bgColor"
                  value={bgColor}
                  onChange={(e) => setBgColor(e.target.value)}
                />
              </div>
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Title
                </label>
                <input
                  type="text"
                  className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  name="title"
                  required
                  disabled={disabled}
                />
              </div>
              <div className="relative w-full mb-3">
                <label
                  htmlFor="titleColor"
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                  Title Color
                </label>
                <input
                  disabled={disabled}
                  type="color"
                  id="titleColor"
                  name="titleColor"
                  value={titleColor}
                  onChange={(e) => setTitleColor(e.target.value)}
                />
              </div>
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Description
                </label>
                <input
                  type="text"
                  className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  name="description"
                  maxLength={200}
                  required
                  disabled={disabled}
                />
              </div>
              <div className="relative w-full mb-3">
                <label
                  htmlFor="descriptionColor"
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                  Description Color
                </label>
                <input
                  disabled={disabled}
                  type="color"
                  id="descriptionColor"
                  name="descriptionColor"
                  value={descriptionColor}
                  onChange={(e) => setDescriptionColor(e.target.value)}
                />
              </div>
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Button Text
                </label>
                <input
                  type="text"
                  className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  value={btnText}
                  onChange={(e) => setBtnText(e.target.value)}
                  name="btnText"
                  required
                  disabled={disabled}
                />
              </div>
              <div className="relative w-full mb-3">
                <label
                  htmlFor="btnBgColor"
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                  Button Background Color
                </label>
                <input
                  disabled={disabled}
                  type="color"
                  id="btnBgColor"
                  name="btnBgColor"
                  value={btnBgColor}
                  onChange={(e) => setBtnBgColor(e.target.value)}
                />
              </div>
              <div className="relative w-full mb-3">
                <label
                  htmlFor="btnColor"
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                  Button Text Color
                </label>
                <input
                  disabled={disabled}
                  type="color"
                  id="btnColor"
                  name="btnColor"
                  value={btnColor}
                  onChange={(e) => setBtnColor(e.target.value)}
                />
              </div>
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Spin Wheel Canvas
                </label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <ImageRadioButton
                    name={"cark1"}
                    image="https://iyosa.com/img/cark1.png"
                    value="cark1"
                    checked={swFrame === "cark1"}
                    handleToggle={(e) => setSwFrame(e.target.value)}
                  />
                  <ImageRadioButton
                    name={"cark2"}
                    image="https://iyosa.com/img/cark2.png"
                    value="cark2"
                    checked={swFrame === "cark2"}
                    handleToggle={(e) => setSwFrame(e.target.value)}
                  />
                  <ImageRadioButton
                    name={"cark3"}
                    image="https://iyosa.com/img/cark3.png"
                    value="cark3"
                    checked={swFrame === "cark3"}
                    handleToggle={(e) => setSwFrame(e.target.value)}
                  />
                  <ImageRadioButton
                    name={"cark4"}
                    image="https://iyosa.com/img/cark4.png"
                    value="cark4"
                    checked={swFrame === "cark4"}
                    handleToggle={(e) => setSwFrame(e.target.value)}
                  />
                  <ImageRadioButton
                    name={"cark5"}
                    image="https://iyosa.com/img/cark5.png"
                    value="cark5"
                    checked={swFrame === "cark5"}
                    handleToggle={(e) => setSwFrame(e.target.value)}
                  />
                </div>
              </div>

              <div className="relative w-full mb-3">
                <label
                  htmlFor="spinWheelBorderColor"
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                  Spin Wheel Border Color
                </label>
                <input
                  disabled={disabled}
                  type="color"
                  id="spinWheelBorderColor"
                  name="spinWheelBorderColor"
                  value={spinWheelBorderColor}
                  onChange={(e) => setSpinWheelBorderColor(e.target.value)}
                />
              </div>
              <div className="relative w-full mb-3">
                <label
                  htmlFor="spinWheelCenterText"
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                  Spin Wheel Center Text
                </label>
                <input
                  disabled={disabled}
                  type="text"
                  id="spinWheelCenterText"
                  name="spinWheelCenterText"
                  value={spinWheelCenterText}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  onChange={(e) => setSpinWheelCenterText(e.target.value)}
                />
              </div>
              <div className="relative w-full mb-3">
                <label
                  htmlFor="pinsNumber"
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                  Spin Wheel Pin Number
                </label>
                <input
                  disabled={disabled}
                  type="number"
                  id="pinsNumber"
                  name="pinsNumber"
                  value={pinsNumber}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  onChange={(e) => setPinsNumber(e.target.value)}
                />
              </div>
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Spin Wheel Text Size
                </label>
                <input
                  type="number"
                  className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  value={spinWheelSize}
                  onChange={(e) => setSpinWheelSize(e.target.value)}
                  name="spinWheelSize"
                  required
                  disabled={disabled}
                />
              </div>
              <div className="relative w-full mb-3">
                <label
                  htmlFor="spinWheelTextColor"
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                  Spin Wheel Text Color
                </label>
                <input
                  disabled={disabled}
                  type="color"
                  id="spinWheelTextColor"
                  name="spinWheelTextColor"
                  value={spinWheelTextColor}
                  onChange={(e) => setSpinWheelTextColor(e.target.value)}
                />
              </div>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Coupon Duration (minutes)
                </label>
                <input
                  type="checkbox"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                  id="duration"
                  onChange={(e) => setCountdownLimitless(e.target.checked)}
                  value={countdownLimitless}
                  checked={countdownLimitless}
                />
                <label htmlFor="duration">Limitless</label>
                <input
                  type="number"
                  max={60}
                  min={10}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  value={countdown}
                  onChange={(e) => setCountdown(e.target.value)}
                  required
                  disabled={disabled || countdownLimitless}
                />
              </div>

              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Boot Time
                </label>
                <input
                  type="number"
                  max={100}
                  min={1}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  value={bootTime}
                  onChange={(e) => setBootTime(e.target.value)}
                  required
                  disabled={disabled}
                />
              </div>

              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Close Count
                </label>
                <input
                  type="number"
                  max={10}
                  min={1}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  value={closeCount}
                  onChange={(e) => setCloseCount(e.target.value)}
                  required
                  disabled={disabled}
                />
              </div>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Days
                </label>
                <input
                  type="number"
                  max={10}
                  min={1}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  value={days}
                  onChange={(e) => setDays(e.target.value)}
                  required
                  disabled={disabled}
                />
              </div>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  {urlAllowOrBlock === true ? "Allow" : "Block"}
                </label>
                <input
                  type="checkbox"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                  value={urlAllowOrBlock}
                  checked={urlAllowOrBlock}
                  onChange={(e) => setUrlAllowOrBlock(e.target.checked)}
                  disabled={disabled}
                />
              </div>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  URL
                </label>
                <button
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => addFormFieldsURL()}
                >
                  <i className="fas fa-plus"></i>
                </button>
              </div>
              <div className="flex flex-wrap">
                {blockedURLInputs.map((blockedURL, index) => (
                  <div key={index} className="w-full lg:w-8/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        URL {index + 1}
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={blockedURL.value}
                        onChange={(e) => handleChangeURL(index, e)}
                        required
                        disabled={disabled}
                      />
                      <span
                        className="z-10 h-full leading-snug font-normal absolute text-center text-red-700 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3"
                        onClick={() => removeFormFieldsURL(index)}
                        style={{ cursor: "pointer", color: "red" }}
                      >
                        <i className="fas fa-minus"></i>
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Visitor Type
                </label>
                <input
                  type="radio"
                  id="all"
                  name="visitor"
                  value="all"
                  checked={visitor === "all"}
                  onChange={(e) => setVisitor("all")}
                />
                <label htmlFor="all"> All</label>
                <br />
                <input
                  type="radio"
                  id="new"
                  name="visitor"
                  value="new"
                  checked={visitor === "new"}
                  onChange={(e) => setVisitor("new")}
                />
                <label htmlFor="new"> New</label>
              </div>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Location
                </label>
                <input
                  type="radio"
                  id="vertical"
                  name="location"
                  value="vertical"
                  checked={location === "vertical"}
                  onChange={(e) => setLocation("vertical")}
                />
                <label htmlFor="vertical"> Vertical</label>
                <br />
                <input
                  type="radio"
                  id="horizontal"
                  name="location"
                  value="horizontal"
                  checked={location === "horizontal"}
                  onChange={(e) => setLocation("horizontal")}
                />
                <label htmlFor="horizontal"> Horizontal</label>
              </div>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Code Area Bottom Distance (px)
                </label>
                <input
                  type="number"
                  max={500}
                  min={0}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  value={bottomDistance}
                  onChange={(e) => setBottomDistance(e.target.value)}
                  required
                  disabled={disabled}
                />
              </div>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Code Area Right Distance (px)
                </label>
                <input
                  type="number"
                  max={300}
                  min={0}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  value={rightDistance}
                  onChange={(e) => setRightDistance(e.target.value)}
                  required
                  disabled={disabled}
                />
              </div>
            </div>

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Spin Wheel Piece
            </h6>
            <div className="flex flex-wrap">
              {segments.map((s, index) => (
                <div className="w-full lg:w-8/12 px-4 card-stories" key={index}>
                  <span className="card-stories-title">
                    {index + 1}. Piece{" "}
                    <i
                      onClick={() => removeFormFields(index)}
                      className="fas fa-minus"
                    ></i>
                  </span>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Text
                    </label>
                    <input
                      type="text"
                      className="border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={s.text}
                      onChange={(e) =>
                        handleChange(e.target.value, e.target.name, index)
                      }
                      name="text"
                      disabled={disabled}
                      maxLength="15"
                    />
                    <small>Maximum 15 character.</small>
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      htmlFor="fillStyle"
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                      Background Color
                    </label>
                    <input
                      disabled={disabled}
                      type="color"
                      id="fillStyle"
                      name="fillStyle"
                      value={s.fillStyle}
                      onChange={(e) =>
                        handleChange(e.target.value, e.target.name, index)
                      }
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Description
                    </label>
                    <input
                      type="text"
                      className="border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={s.description}
                      onChange={(e) =>
                        handleChange(e.target.value, e.target.name, index)
                      }
                      name="description"
                      disabled={disabled}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Probability
                    </label>
                    <input
                      type="number"
                      className="border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={s.probability}
                      onChange={(e) =>
                        handleChange(e.target.value, e.target.name, index)
                      }
                      name="probability"
                      disabled={disabled}
                      min="0"
                      max="100"
                    />
                    <small>Write numbers between 0-100.</small>
                    <br />
                    <small>
                      <strong>
                        Remaining Probability:{" "}
                        {100 -
                          segments.reduce(
                            (a, v) =>
                              (a = parseInt(a) + parseInt(v.probability)),
                            0
                          )}
                      </strong>
                    </small>
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Discount Code
                    </label>
                    <input
                      type="text"
                      className="border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={s.code}
                      onChange={(e) =>
                        handleChange(e.target.value, e.target.name, index)
                      }
                      name="code"
                      disabled={disabled}
                    />
                    <small></small>
                  </div>
                </div>
              ))}
            </div>
            <button
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => addFormFields()}
            >
              <i className="fas fa-plus"></i>
            </button>
            <button
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="submit"
              disabled={disabled}
            >
              Save
            </button>
            {message}
          </form>
        </div>
      </div>
    </>
  );
}
