import ActiveSwitch from "components/Inputs/ActiveSwitch";
import { alerts } from "helpers/Alerts";
import React, { useEffect, useRef, useState } from "react";
import OpportunityService from "services/opportunity.service";
import { ChromePicker } from "react-color";
import CssFilterConverter from "css-filter-converter";

// components
import { h6style } from "components/Cards/style";
import { CardLoading } from "components/Inputs/Loading";
import { useParams } from "react-router";
export default function CardOpportunitiesEdit(props) {
  const [opportunity, setOpportunity] = useState([]);
  const [opportunityId, setOpportunityId] = useState("");
  const [menuType, setMenuType] = useState("dropdown");
  const [title, setTitle] = useState("");
  const [bgColor, setBgColor] = useState("");
  const [color, setColor] = useState("");
  const [closeColor, setCloseColor] = useState("");
  const [titleFontSize, setTitleFontSize] = useState("");
  const [btnBgColor, setBtnBgColor] = useState("");
  const [btnDisplay, setBtnDisplay] = useState("");
  const [btnText, setBtnText] = useState("");
  const [btnFontSize, setBtnFontSize] = useState("");
  const [bottomDistance, setBottomDistance] = useState("");
  const [selector, setSelector] = useState("id or classname");
  const [position, setPosition] = useState("afterend");
  const [mobileSelector, setMobileSelector] = useState("id or classname");
  const [mobilePosition, setMobilePosition] = useState("afterend");
  const [iconsWidth, setIconsWidth] = useState(25);
  const [badgeWidthHeight, setBadgeWidthHeight] = useState(20);
  const [badgeFontSize, setBadgeFontSize] = useState(10);
  const [badgeTop, setBadgeTop] = useState(-9);
  const [badgeRight, setBadgeRight] = useState(-10);
  const [listLeft, setListLeft] = useState(-100);
  const [margin, setMargin] = useState("0px 0px 0px 0px");
  const [marginForMobile, setMarginForMobile] = useState("0px 0px 0px 0px");
  const [mobileTop, setMobileTop] = useState(0);
  const [iconColor, setIconColor] = useState({ hex: "", filter: "" });

  const [message, setMessage] = useState("");
  const [active, setActive] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const mountedRef = useRef(true);
  const { owner, company } = useParams();

  const dragItem = useRef();
  const dragOverItem = useRef();
  const dragStart = (e, position) => {
    dragItem.current = position;
    console.log(e.target.innerHTML);
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    console.log(e.target.innerHTML);
  };
  const drop = (e) => {
    const copyListItems = [...opportunity];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setOpportunity(copyListItems);
  };

  useEffect(() => {
    OpportunityService.getOpportunity(owner).then(
      (res) => {
        if (!mountedRef.current) return null;
        setOpportunity(res.data.opportunity);
        setMenuType(res.data.menuType);
        setTitle(res.data.title);
        setBgColor(res.data.bgColor);
        setColor(res.data.color);
        setCloseColor(res.data.closeColor);
        setTitleFontSize(res.data.titleFontSize);
        setBtnBgColor(res.data.btnBgColor);
        setBtnDisplay(res.data.btnDisplay);
        setBtnText(res.data.btnText);
        setBtnFontSize(res.data.btnFontSize);
        setBottomDistance(res.data.bottomDistance);
        setSelector(res.data.selector);
        setPosition(res.data.position);
        setMobileSelector(res.data.mobileSelector);
        setMobilePosition(res.data.mobilePosition);
        setIconsWidth(res.data.iconsWidth);
        setBadgeWidthHeight(res.data.badgeWidthHeight);
        setBadgeFontSize(res.data.badgeFontSize);
        setBadgeTop(res.data.badgeTop);
        setBadgeRight(res.data.badgeRight);
        setListLeft(res.data.listLeft);
        setMargin(res.data.margin);
        setMarginForMobile(res.data.marginForMobile);
        setOpportunityId(res.data.id);
        setMobileTop(res.data.mobileTop);
        setIconColor(res.data.iconColor);
        setActive(res.data.active);
        setDisabled(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setDisabled(false);
      }
    );
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);
  function handleChangeOpportunity(value, name, index) {
    let newOpportunity = [...opportunity];
    newOpportunity[index][name] = value;
    setOpportunity(newOpportunity);
  }
  let addFormFields = () => {
    if (opportunity.length < 7)
      setOpportunity([...opportunity, { title: "", url: "", image: "" }]);
    else setMessage(alerts.opportunityMax);
  };
  const removeFormFields = (i) => {
    const newOpportunity = [...opportunity];
    newOpportunity.splice(i, 1);
    setOpportunity(newOpportunity);
  };
  function opportunitySubmit(e) {
    e.preventDefault();
    if (opportunity.length < 1) {
      setMessage(alerts.opportunityMin);
    } else {
      setDisabled(true);
      OpportunityService.updateOpportunity(
        opportunity,
        menuType,
        title,
        color,
        bgColor,
        closeColor,
        titleFontSize,
        btnBgColor,
        btnDisplay,
        btnText,
        btnFontSize,
        bottomDistance,
        selector,
        position,
        mobileSelector,
        mobilePosition,
        iconsWidth,
        badgeWidthHeight,
        badgeFontSize,
        badgeTop,
        badgeRight,
        listLeft,
        margin,
        marginForMobile,
        mobileTop,
        iconColor,
        active,
        owner,
        opportunityId
      ).then(
        (res) => {
          setMessage(alerts.positive);
          setDisabled(false);
          setTimeout(() => {
            setMessage("");
          }, 2000);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setMessage(resMessage);
          setDisabled(false);
          OpportunityService.createOpportunity(
            opportunity,
            menuType,
            title,
            color,
            bgColor,
            closeColor,
            titleFontSize,
            btnBgColor,
            btnDisplay,
            btnText,
            btnFontSize,
            bottomDistance,
            selector,
            position,
            mobileSelector,
            mobilePosition,
            iconsWidth,
            badgeWidthHeight,
            badgeFontSize,
            badgeTop,
            badgeRight,
            listLeft,
            margin,
            marginForMobile,
            mobileTop,
            iconColor,
            active,
            owner
          ).then(
            (res) => {
              setMessage(alerts.positive);
              setDisabled(false);
              setOpportunityId(res.data.id);
              setTimeout(() => {
                setMessage("");
              }, 2000);
            },
            (error) => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
              setMessage(resMessage);
              setDisabled(false);
            }
          );
        }
      );
    }
  }
  function setHexToFilter(hex) {
    const result = CssFilterConverter.hexToFilter(hex);
    setIconColor({ hex, filter: result.color });
  }
  return (
    <>
      <CardLoading isShow={disabled} />
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <h6 className="text-blueGray-700 text-xl font-bold" style={h6style}>
          {company}
        </h6>
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold" style={h6style}>
              <ActiveSwitch
                disabled={disabled}
                onColor="#0ea5e9"
                checked={active}
                handleToggle={(e) => setActive(e.target.checked)}
              />{" "}
              Opportunity
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={opportunitySubmit}>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              View Settings
            </h6>
            <div className="w-full lg:w-8/12 px-4">
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Menu Type
                </label>
                <input
                  type="radio"
                  id="sidebar"
                  name="menuType"
                  value="sidebar"
                  checked={menuType === "sidebar"}
                  onChange={(e) => setMenuType("sidebar")}
                />
                <label htmlFor="sidebar"> Sidebar</label>
                <br />
                <input
                  type="radio"
                  id="dropdown"
                  name="menuType"
                  value="dropdown"
                  checked={menuType === "dropdown"}
                  onChange={(e) => setMenuType("dropdown")}
                />
                <label htmlFor="dropdown"> Dropdown</label>
                <br />
              </div>
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Selector
                </label>
                <input
                  type="text"
                  className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  value={selector}
                  onChange={(e) => setSelector(e.target.value)}
                  name="selector"
                  required
                  disabled={disabled}
                />
              </div>
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Position
                </label>
                <select
                  type="text"
                  className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                  name="position"
                  required
                  disabled={disabled}
                >
                  <option value="beforebegin">beforebegin</option>
                  <option value="afterbegin">afterbegin</option>
                  <option value="afterend">afterend</option>
                  <option value="beforeend">beforeend</option>
                </select>
              </div>
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Mobile Selector
                </label>
                <input
                  type="text"
                  className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  value={mobileSelector}
                  onChange={(e) => setMobileSelector(e.target.value)}
                  name="mobileSelector"
                  required
                  disabled={disabled}
                />
              </div>
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Mobile Position
                </label>
                <select
                  type="text"
                  className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  value={mobilePosition}
                  onChange={(e) => setMobilePosition(e.target.value)}
                  name="mobilePosition"
                  required
                  disabled={disabled}
                >
                  <option value="beforebegin">beforebegin</option>
                  <option value="afterbegin">afterbegin</option>
                  <option value="afterend">afterend</option>
                  <option value="beforeend">beforeend</option>
                </select>
              </div>
              {menuType === "dropdown" && (
                <>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Outer Space (px)
                    </label>
                    <input
                      type="text"
                      className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={margin}
                      onChange={(e) => setMargin(e.target.value)}
                      name="margin"
                      required
                      disabled={disabled}
                    />
                    <span className="input-description">
                      Ex: 10px(top) 10px(right) 10px(bottom) 10px(left)
                    </span>
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Outer Space (px) for Mobile
                    </label>
                    <input
                      type="text"
                      className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={marginForMobile}
                      onChange={(e) => setMarginForMobile(e.target.value)}
                      name="marginForMobile"
                      required
                      disabled={disabled}
                    />
                    <span className="input-description">
                      Ex: 10px(top) 10px(right) 10px(bottom) 10px(left)
                    </span>
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Icon Width (px)
                    </label>
                    <input
                      type="number"
                      className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={iconsWidth}
                      onChange={(e) => setIconsWidth(e.target.value)}
                      name="iconsWidth"
                      required
                      disabled={disabled}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Badge Width-Height (px)
                    </label>
                    <input
                      type="number"
                      className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={badgeWidthHeight}
                      onChange={(e) => setBadgeWidthHeight(e.target.value)}
                      name="badgeWidthHeight"
                      required
                      disabled={disabled}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Badge Font Size (px)
                    </label>
                    <input
                      type="number"
                      className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={badgeFontSize}
                      onChange={(e) => setBadgeFontSize(e.target.value)}
                      name="badgeFontSize"
                      required
                      disabled={disabled}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Badge Top Distance (px)
                    </label>
                    <input
                      type="number"
                      className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={badgeTop}
                      onChange={(e) => setBadgeTop(e.target.value)}
                      name="badgeTop"
                      required
                      disabled={disabled}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Badge Right Distance (px)
                    </label>
                    <input
                      type="number"
                      className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={badgeRight}
                      onChange={(e) => setBadgeRight(e.target.value)}
                      name="badgeRight"
                      required
                      disabled={disabled}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Dropdown Box List Left Distance (px)
                    </label>
                    <input
                      type="number"
                      className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={listLeft}
                      onChange={(e) => setListLeft(e.target.value)}
                      name="listLeft"
                      required
                      disabled={disabled}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Mobile Top (px)
                    </label>
                    <input
                      type="number"
                      className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={mobileTop}
                      onChange={(e) => setMobileTop(e.target.value)}
                      name="mobileTop"
                      required
                      disabled={disabled}
                    />
                  </div>
                </>
              )}
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Title
                </label>
                <input
                  type="text"
                  className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  name="title"
                  required
                  disabled={disabled}
                />
              </div>
              <div className="relative w-full mb-3">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Bell Icon Color
                </label>
                <ChromePicker
                  color={iconColor ? iconColor.hex : ""}
                  onChangeComplete={(color, event) => setHexToFilter(color.hex)}
                />
              </div>
              <div className="relative w-full mb-3">
                <label
                  htmlFor="color"
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                  Sidebar Text Color & Dropdown Badge Number Color
                </label>
                <input
                  disabled={disabled}
                  type="color"
                  id="color"
                  name="color"
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                />
              </div>
              <div className="relative w-full mb-3">
                <label
                  htmlFor="bgColor"
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                  Sidebar Top Bg Color & Dropdown Badge Bg Color
                </label>
                <input
                  disabled={disabled}
                  type="color"
                  id="bgColor"
                  name="bgColor"
                  value={bgColor}
                  onChange={(e) => setBgColor(e.target.value)}
                />
              </div>
              <div className="relative w-full mb-3">
                <label
                  htmlFor="titleFontSize"
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                  Title Font Size (px)
                </label>
                <input
                  disabled={disabled}
                  className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  type="number"
                  id="titleFontSize"
                  name="titleFontSize"
                  value={titleFontSize}
                  onChange={(e) => setTitleFontSize(e.target.value)}
                />
              </div>
              <div className="relative w-full mb-3">
                <label
                  htmlFor="btnText"
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                  Button Text for Sidebar
                </label>
                <input
                  disabled={disabled}
                  type="text"
                  id="btnText"
                  className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  name="btnText"
                  value={btnText}
                  onChange={(e) => setBtnText(e.target.value)}
                />
              </div>
              <div className="relative w-full mb-3">
                <label
                  htmlFor="btnFontSize"
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                  Button Font Size (px) for Sidebar
                </label>
                <input
                  disabled={disabled}
                  type="number"
                  id="btnFontSize"
                  className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  name="btnFontSize"
                  value={btnFontSize}
                  onChange={(e) => setBtnFontSize(e.target.value)}
                />
              </div>
              <div className="relative w-full mb-3">
                <label
                  htmlFor="btnBgColor"
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                  Button Background Color for Sidebar
                </label>
                <input
                  disabled={disabled}
                  type="color"
                  id="btnBgColor"
                  name="btnBgColor"
                  value={btnBgColor}
                  onChange={(e) => setBtnBgColor(e.target.value)}
                />
              </div>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Button Display for Sidebar
                </label>
                <input
                  type="radio"
                  id="inline-flex"
                  name="location"
                  value="inline-flex"
                  checked={btnDisplay === "inline-flex"}
                  onChange={(e) => setBtnDisplay("inline-flex")}
                />
                <label htmlFor="inline-flex"> Show</label>
                <br />
                <input
                  type="radio"
                  id="none"
                  name="location"
                  value="none"
                  checked={btnDisplay === "none"}
                  onChange={(e) => setBtnDisplay("none")}
                />
                <label htmlFor="none"> Hide</label>
                <br />
              </div>
              {/*
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Icon Bottom Distance (px)
                </label>
                <input
                  type="number"
                  max={100}
                  min={0}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  value={bottomDistance}
                  onChange={(e) => setBottomDistance(e.target.value)}
                  required
                  disabled={disabled}
                />
              </div>
              */}
              {/*
              <div className="relative w-full mb-3">
                <label 
                  htmlFor="closeColor"
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                  Close Button Color
                </label>
                <input disabled={disabled} type="color" id="closeColor" name="closeColor" value={closeColor} onChange={e => setCloseColor(e.target.value)} />
              </div>
              */}
            </div>

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Banner Settings
            </h6>
            <div className="flex flex-wrap">
              {opportunity.map((s, index) => (
                <div
                  className="w-full lg:w-8/12 px-4 card-stories"
                  onDragStart={(e) => dragStart(e, index)}
                  onDragEnter={(e) => dragEnter(e, index)}
                  onDragEnd={drop}
                  key={index}
                  draggable
                >
                  <span className="card-stories-title text-sm">
                    {index + 1}. Opportunity{" "}
                    <i
                      onClick={() => removeFormFields(index)}
                      className="fas fa-minus"
                    ></i>
                  </span>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Title
                    </label>
                    <input
                      type="text"
                      className="border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={s.title}
                      onChange={(e) =>
                        handleChangeOpportunity(
                          e.target.value,
                          e.target.name,
                          index
                        )
                      }
                      name="title"
                      required
                      disabled={disabled}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      URL
                    </label>
                    <input
                      type="text"
                      className="border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={s.url}
                      onChange={(e) =>
                        handleChangeOpportunity(
                          e.target.value,
                          e.target.name,
                          index
                        )
                      }
                      name="url"
                      required
                      disabled={disabled}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Image
                    </label>
                    <input
                      type="text"
                      className="border-0 px-2 py-1 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={s.image}
                      onChange={(e) =>
                        handleChangeOpportunity(
                          e.target.value,
                          e.target.name,
                          index
                        )
                      }
                      name="image"
                      disabled={disabled}
                    />
                    <small>Banner width max 500px.</small>
                  </div>
                </div>
              ))}
            </div>
            <button
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => addFormFields()}
            >
              <i className="fas fa-plus"></i>
            </button>
            <button
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="submit"
              disabled={disabled}
            >
              Save
            </button>
            {message}
          </form>
        </div>
      </div>
    </>
  );
}
