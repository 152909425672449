import api from "./api";

const createWatchedBought = (
  title,
  fontSize,
  min,
  max,
  location,
  popupDelay,
  selector,
  position,
  iconColor,
  textIcon,
  textColor,
  bgColor,
  active,
  owner
) => {
  return api.post("/watchedbought", {
    title,
    fontSize,
    min,
    max,
    location,
    popupDelay,
    selector,
    position,
    iconColor,
    textIcon,
    textColor,
    bgColor,
    active,
    owner,
  });
};

const updateWatchedBought = (
  title,
  fontSize,
  min,
  max,
  location,
  popupDelay,
  selector,
  position,
  iconColor,
  textIcon,
  textColor,
  bgColor,
  active,
  owner,
  id
) => {
  return api.patch(`/watchedbought/${owner}`, {
    title,
    fontSize,
    min,
    max,
    location,
    popupDelay,
    selector,
    position,
    iconColor,
    textIcon,
    textColor,
    bgColor,
    active,
    owner,
    id,
  });
};
const updateActive = (active, owner, id) => {
  return api.patch(`/watchedbought/status/${owner}`, {
    active,
    id,
  });
};
const getWatchedBought = (owner) => {
  return api.get(`/watchedbought/${owner}`);
};
const getAll = (limit, page, sortBy, active, company, isEmailVerified) => {
  return api.get(`/watchedbought`, {
    params: { limit, page, sortBy, active, company, isEmailVerified },
  });
};
const WatchedBoughtService = {
  createWatchedBought,
  updateWatchedBought,
  getWatchedBought,
  getAll,
  updateActive,
};

export default WatchedBoughtService;
