import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const ImageRadioButton = ({
  checked,
  handleToggle,
  onColor,
  disabled,
  name,
  image,
  value,
  size,
  text,
  filter,
}) => {
  return (
    <>
      <input
        checked={checked}
        onChange={handleToggle}
        disabled={disabled}
        value={value}
        className="iyosa-input-hidden"
        id={name}
        type="radio"
      />
      <label
        style={{ background: checked && onColor, textAlign: "center" }}
        htmlFor={name}
      >
        <img
          src={image}
          alt="spinwheel canvas"
          style={{ width: size, height: size, filter: filter }}
        />
        {text}
      </label>
    </>
  );
};

export default ImageRadioButton;
ImageRadioButton.defaultProps = {
  name: "iyosa-emotion",
  size: "150px",
};
ImageRadioButton.propTypes = {
  checked: PropTypes.bool,
  handleToggle: PropTypes.func,
  onColor: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  image: PropTypes.string,
  value: PropTypes.string,
  size: PropTypes.string,
  text: PropTypes.string,
  filter: PropTypes.string,
};
