import api from "./api";

const createExitPopup = (
  url,
  image,
  day,
  type,
  containerBgColor,
  containerBorderRadius,
  sendBtnBgColor,
  sendBtnColor,
  sendBtnBorderRadius,
  sendBtnValue,
  answers,
  question,
  active,
  owner
) => {
  return api.post("/exitpopup", {
    url,
    image,
    day,
    type,
    containerBgColor,
    containerBorderRadius,
    sendBtnBgColor,
    sendBtnColor,
    sendBtnBorderRadius,
    sendBtnValue,
    answers,
    question,
    active,
    owner,
  });
};

const updateExitPopup = (
  url,
  image,
  day,
  type,
  containerBgColor,
  containerBorderRadius,
  sendBtnBgColor,
  sendBtnColor,
  sendBtnBorderRadius,
  sendBtnValue,
  answers,
  question,
  active,
  owner,
  id
) => {
  return api.patch(`/exitpopup/${owner}`, {
    url,
    image,
    day,
    type,
    containerBgColor,
    containerBorderRadius,
    sendBtnBgColor,
    sendBtnColor,
    sendBtnBorderRadius,
    sendBtnValue,
    answers,
    question,
    active,
    owner,
    id,
  });
};
const updateActive = (active, owner, id) => {
  return api.patch(`/exitpopup/status/${owner}`, {
    active,
    id,
  });
};
const getExitPopup = (owner) => {
  return api.get(`/exitpopup/${owner}`);
};
const getAll = (limit, page, sortBy, active, company, isEmailVerified) => {
  return api.get(`/exitpopup`, {
    params: { limit, page, sortBy, active, company, isEmailVerified },
  });
};
const ExitPopupService = {
  createExitPopup,
  updateExitPopup,
  getExitPopup,
  getAll,
  updateActive,
};

export default ExitPopupService;
