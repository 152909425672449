import api from "./api";

const createTitle = (title, active, owner) => {
  return api.post("/titles", {
    title,
    active,
    owner,
  });
};

const updateTitle = (title, active, owner, id) => {
  return api.patch(`/titles/${owner}`, {
    title,
    active,
    owner,
    id,
  });
};
const updateActive = (active, owner, id) => {
  return api.patch(`/titles/status/${owner}`, {
    active,
    id,
  });
};
const getTitle = (owner) => {
  return api.get(`/titles/${owner}`);
};
const getAll = (limit, page, sortBy, active, company, isEmailVerified) => {
  return api.get(`/titles`, {
    params: { limit, page, sortBy, active, company, isEmailVerified },
  });
};
const TitleService = {
  createTitle,
  updateTitle,
  getTitle,
  getAll,
  updateActive,
};

export default TitleService;
