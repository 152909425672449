import ActiveSwitch from "components/Inputs/ActiveSwitch";
import { CardLoading } from "components/Inputs/Loading";
import { trendyolComments } from "components/Scenarios/trendyolcomments";
import { alerts } from "helpers/Alerts";
import React, { useEffect, useRef, useState } from "react";
import TrendyolCommentsService from "services/trendyolcomments.service";
import CardPreview from "components/Cards/CardPreview";

// components viewType,commentContent,commentCustomer,commentDate,comment
import { h6style } from "components/Cards/style";
import { useParams } from "react-router";
export default function CardWatchedBought(props) {
  const [productName, setProductName] = useState("Product");
  const [searchType, setSearchType] = useState("productName");
  const [merchantId, setMerchantId] = useState("0");
  const [starSelector, setStarSelector] = useState("id or classname");
  const [starPosition, setStarPosition] = useState("beforebegin");
  const [commentsSelector, setCommentsSelector] = useState("id or classname");
  const [commentsPosition, setCommentsPosition] = useState("beforebegin");
  const [viewType, setViewType] = useState("seperate");
  const [commentsContent, setCommentsContent] = useState("");
  const [commentContent, setCommentContent] = useState("");
  const [commentCustomer, setCommentCustomer] = useState("");
  const [commentDate, setCommentDate] = useState("");
  const [comment, setComment] = useState("");
  const [trendyolCommentsId, setTrendyolCommentsId] = useState("");
  const [message, setMessage] = useState("");
  const [active, setActive] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const mountedRef = useRef(true);
  const { owner, company } = useParams();

  const [ri, setRi] = useState(0);
  function refreshIframe() {
    let i = ri + 1;
    setRi(i);
    console.log(ri);
  }
  useEffect(() => {
    const doc = document.getElementById("preview");
    const obj = {
      productName,
      searchType,
      merchantId,
      starSelector,
      starPosition,
      commentsSelector,
      commentsPosition,
      viewType,
      commentsContent,
      commentContent,
      commentCustomer,
      commentDate,
      comment,
      active,
    };
    try {
      trendyolComments(doc, obj);
    } catch (error) {
      console.log(error);
    }

    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, [ri]);

  useEffect(() => {
    TrendyolCommentsService.get(owner).then(
      (res) => {
        if (!mountedRef.current) return null;
        setProductName(res.data.productName);
        setSearchType(res.data.searchType);
        setMerchantId(res.data.merchantId);
        setStarSelector(res.data.starSelector);
        setStarPosition(res.data.starPosition);
        setCommentsSelector(res.data.commentsSelector);
        setCommentsPosition(res.data.commentsPosition);
        setViewType(res.data.viewType);
        setCommentsContent(res.data.commentsContent);
        setCommentContent(res.data.commentContent);
        setCommentCustomer(res.data.commentCustomer);
        setCommentDate(res.data.commentDate);
        setComment(res.data.comment);
        setTrendyolCommentsId(res.data.id);
        setActive(res.data.active);
        setDisabled(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setDisabled(false);
        TrendyolCommentsService.create(
          productName,
          searchType,
          merchantId,
          starSelector,
          starPosition,
          commentsSelector,
          commentsPosition,
          viewType,
          commentsContent,
          commentContent,
          commentCustomer,
          commentDate,
          comment,
          false,
          owner
        ).then((res) => {
          setTrendyolCommentsId(res.data.id);
          setDisabled(false);
        });
      }
    );
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);

  function onSubmit(e) {
    e.preventDefault();
    setDisabled(true);
    TrendyolCommentsService.update(
      productName,
      searchType,
      merchantId,
      starSelector,
      starPosition,
      commentsSelector,
      commentsPosition,
      viewType,
      commentsContent,
      commentContent,
      commentCustomer,
      commentDate,
      comment,
      active,
      owner,
      trendyolCommentsId
    ).then(
      (res) => {
        setMessage(alerts.positive);
        setDisabled(false);
        setTimeout(() => {
          setMessage("");
        }, 2000);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setDisabled(false);
      }
    );
  }
  return (
    <>
      <CardLoading isShow={disabled} />
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <h6 className="text-blueGray-700 text-xl font-bold" style={h6style}>
          {company}
        </h6>
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold" style={h6style}>
              <ActiveSwitch
                disabled={disabled}
                onColor="#0ea5e9"
                checked={active}
                handleToggle={(e) => setActive(e.target.checked)}
              />{" "}
              Trendyol Comments
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={onSubmit}>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Settings
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-8/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Search Type
                  </label>
                  <input
                    type="radio"
                    id="productName"
                    name="searchType"
                    value="productName"
                    checked={searchType === "productName"}
                    onChange={(e) => setSearchType("productName")}
                  />
                  <label htmlFor="productName"> Product Name</label>
                  <br />
                  <input
                    type="radio"
                    id="code"
                    name="searchType"
                    value="code"
                    checked={searchType === "code"}
                    onChange={(e) => setSearchType("code")}
                  />
                  <label htmlFor="code"> Code</label>
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Product Selector
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={productName}
                    onChange={(e) => setProductName(e.target.value)}
                    required
                    disabled={disabled}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Merchant ID
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={merchantId}
                    onChange={(e) => setMerchantId(e.target.value)}
                    required
                    disabled={disabled}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Trendyol Comments View Type
                  </label>
                  <input
                    type="radio"
                    id="seperate"
                    name="viewType"
                    value="seperate"
                    checked={viewType === "seperate"}
                    onChange={(e) => setViewType("seperate")}
                  />
                  <label htmlFor="seperate"> Seperate</label>
                  <br />
                  <input
                    type="radio"
                    id="compound"
                    name="viewType"
                    value="compound"
                    checked={viewType === "compound"}
                    onChange={(e) => setViewType("compound")}
                  />
                  <label htmlFor="compound"> Compound</label>
                </div>
                {viewType === "seperate" && (
                  <>
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Star Selector
                      </label>
                      <input
                        type="text"
                        className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={starSelector}
                        onChange={(e) => setStarSelector(e.target.value)}
                        name="selector"
                        required
                        disabled={disabled}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Star Position
                      </label>
                      <select
                        type="text"
                        className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={starPosition}
                        onChange={(e) => setStarPosition(e.target.value)}
                        name="position"
                        required
                        disabled={disabled}
                      >
                        <option value="beforebegin">beforebegin</option>
                        <option value="afterbegin">afterbegin</option>
                        <option value="afterend">afterend</option>
                        <option value="beforeend">beforeend</option>
                      </select>
                    </div>
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Comments Selector
                      </label>
                      <input
                        type="text"
                        className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={commentsSelector}
                        onChange={(e) => setCommentsSelector(e.target.value)}
                        name="selector"
                        required
                        disabled={disabled}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Comments Position
                      </label>
                      <select
                        type="text"
                        className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={commentsPosition}
                        onChange={(e) => setCommentsPosition(e.target.value)}
                        name="position"
                        required
                        disabled={disabled}
                      >
                        <option value="beforebegin">beforebegin</option>
                        <option value="afterbegin">afterbegin</option>
                        <option value="afterend">afterend</option>
                        <option value="beforeend">beforeend</option>
                      </select>
                    </div>
                  </>
                )}
                {viewType === "compound" && (
                  <>
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Comments Content Selector
                      </label>
                      <input
                        type="text"
                        className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={commentsContent}
                        onChange={(e) => setCommentsContent(e.target.value)}
                        name="commentsContent"
                        required
                        disabled={disabled}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Comment Content Selector
                      </label>
                      <input
                        type="text"
                        className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={commentContent}
                        onChange={(e) => setCommentContent(e.target.value)}
                        name="commentContent"
                        required
                        disabled={disabled}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Comment Customer Selector
                      </label>
                      <input
                        type="text"
                        className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={commentCustomer}
                        onChange={(e) => setCommentCustomer(e.target.value)}
                        name="commentCustomer"
                        required
                        disabled={disabled}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Comment Date Selector
                      </label>
                      <input
                        type="text"
                        className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={commentDate}
                        onChange={(e) => setCommentDate(e.target.value)}
                        name="commentDate"
                        required
                        disabled={disabled}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Comment Selector
                      </label>
                      <input
                        type="text"
                        className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        name="comment"
                        required
                        disabled={disabled}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4 py-4">
                <CardPreview refresh={refreshIframe} />
              </div>
            </div>
            <button
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="submit"
              disabled={disabled}
            >
              Save
            </button>
            {message}
          </form>
        </div>
      </div>
    </>
  );
}
