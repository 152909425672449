import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import AuthService from "services/auth.service";
import SurveyService from "services/survey.service";
import Accordion from "components/Dropdowns/Accordion";

// components

export default function CardSurvey() {
  const [survey, setSurvey] = useState([]);
  const [message, setMessage] = useState();
  const currentUser = AuthService.getCurrentUser();
  const mountedRef = useRef(true);

  useEffect(() => {
    SurveyService.get(currentUser.user.id).then(
      (res) => {
        if (!mountedRef.current) return null;

        var out = _.groupBy(res.data, ({ question }) => {
          return question;
        });
        let outKeys = Object.keys(out);
        let result = [];
        for (let i = 0; i < outKeys.length; i++) {
          const key = outKeys[i];
          result[i] = { question: key, answers: [] };
          for (let x = 0; x < out[key].length; x++) {
            const element = out[key][x].answers;
            for (let z = 0; z < element.length; z++) {
              const answ = element[z];
              result[i].answers.push(answ.answer);
            }
          }
        }
        for (let i = 0; i < result.length; i++) {
          const counts = result[i].answers.reduce(
            (acc, value) => ({
              ...acc,
              [value]: (acc[value] || 0) + 1,
            }),
            {}
          );
          result[i].counts = counts;
          result[i].countsKey = Object.keys(counts);
        }

        for (let i = 0; i < result.length; i++) {
          const element = result[i];
          const modifiedCounts = [];
          for (let a = 0; a < element.countsKey.length; a++) {
            const _a = element.countsKey[a];
            modifiedCounts.push({
              answer: _a,
              count: element.counts[_a],
            });
          }
          element.totals = modifiedCounts;
        }
        setSurvey(result);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
      }
    );
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-blueGray-700">
                Survey Answers
              </h3>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          {survey.map((s, index) => (
            <Accordion title={s.question} key={index}>
              <table
                className="items-center w-full bg-transparent border-collapse"
                key={index}
              >
                <thead>
                  <tr>
                    <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                      Answers
                    </th>
                    <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                      Choice
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {s.totals.map((t, i) => (
                    <tr key={i}>
                      <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                        {t.answer}
                      </th>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {t.count}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Accordion>
          ))}
          {message}
        </div>
      </div>
    </>
  );
}
