import api from "./api";

const create = (
  title,
  color,
  backgroundColor,
  selector,
  position,
  selectorMobile,
  positionMobile,
  searchSelector,
  mobileTop,
  active,
  owner
) => {
  return api.post("/popularsearch", {
    title,
    color,
    backgroundColor,
    selector,
    position,
    selectorMobile,
    positionMobile,
    searchSelector,
    mobileTop,
    active,
    owner,
  });
};

const update = (
  title,
  color,
  backgroundColor,
  selector,
  position,
  selectorMobile,
  positionMobile,
  searchSelector,
  mobileTop,
  active,
  owner,
  id
) => {
  return api.patch(`/popularsearch/${owner}`, {
    title,
    color,
    backgroundColor,
    selector,
    position,
    selectorMobile,
    positionMobile,
    searchSelector,
    mobileTop,
    active,
    owner,
    id,
  });
};
const updateActive = (active, owner, id) => {
  return api.patch(`/popularsearch/status/${owner}`, {
    active,
    id,
  });
};
const get = (owner) => {
  return api.get(`/popularsearch/${owner}`);
};
const getAll = (limit, page, sortBy, active, company, isEmailVerified) => {
  return api.get(`/popularsearch`, {
    params: { limit, page, sortBy, active, company, isEmailVerified },
  });
};
const PopularSearchService = {
  create,
  update,
  get,
  getAll,
  updateActive,
};

export default PopularSearchService;
