import React from "react";

// components

import CardTopBannerChanger from "components/Cards/CardTopBannerChanger";

export default function TopBannerChanger() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <CardTopBannerChanger />
        </div>
      </div>
    </>
  );
}
