import ActiveSwitch from "components/Inputs/ActiveSwitch";
import { CardLoading } from "components/Inputs/Loading";
import { alerts } from "helpers/Alerts";
import React, { useEffect, useRef, useState } from "react";
import AuthService from "services/auth.service";
import PopularSearchService from "services/popularsearch.service";

// components
import { h6style } from "./style";

export default function CardPopulerSearches(props) {
  const [titleInputs, setTitleInput] = useState([]);
  const [titleId, setTitleId] = useState("");
  const [color, setColor] = useState("#FFFFFF");
  const [backgroundColor, setBackgroundColor] = useState("#232323");
  const [selector, setSelector] = useState("id or classname");
  const [position, setPosition] = useState("afterend");
  const [selectorMobile, setSelectorMobile] = useState("id or classname");
  const [positionMobile, setPositionMobile] = useState("afterend");
  const [searchSelector, setSearchSelector] = useState("id or classname");
  const [mobileTop, setMobileTop] = useState(0);
  const [message, setMessage] = useState("");
  const [active, setActive] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const currentUser = AuthService.getCurrentUser();
  const mountedRef = useRef(true);

  useEffect(() => {
    PopularSearchService.get(currentUser.user.id).then(
      (res) => {
        if (!mountedRef.current) return null;
        setTitleInput(res.data.title);
        setTitleId(res.data.id);
        setColor(res.data.color);
        setBackgroundColor(res.data.backgroundColor);
        setSelector(res.data.selector);
        setPosition(res.data.position);
        setSelectorMobile(res.data.selectorMobile);
        setPositionMobile(res.data.positionMobile);
        setSearchSelector(res.data.searchSelector);
        setMobileTop(res.data.mobileTop);
        setActive(res.data.active);
        setDisabled(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        PopularSearchService.create(
          titleInputs,
          color,
          backgroundColor,
          selector,
          position,
          selectorMobile,
          positionMobile,
          searchSelector,
          mobileTop,
          false,
          currentUser.user.id
        ).then((res) => {
          setDisabled(false);
        });
      }
    );
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);

  let handleChange = (value, name, index) => {
    let newTitles = [...titleInputs];
    newTitles[index][name] = value;
    setTitleInput(newTitles);
  };

  let addFormFields = () => {
    if (titleInputs.length < 5)
      setTitleInput([...titleInputs, { title: "", url: "" }]);
    else setMessage(alerts.titleChangerMax);
  };

  const removeFormFields = (i) => {
    const newTitles = [...titleInputs];
    newTitles.splice(i, 1);
    setTitleInput(newTitles);
  };

  function titleChangerSubmit(e) {
    e.preventDefault();
    if (titleInputs.length < 2) {
      setMessage(alerts.titleChangerMin);
    } else {
      setDisabled(true);
      PopularSearchService.update(
        titleInputs,
        color,
        backgroundColor,
        selector,
        position,
        selectorMobile,
        positionMobile,
        searchSelector,
        mobileTop,
        active,
        currentUser.user.id,
        titleId
      ).then(
        (res) => {
          setMessage(alerts.positive);
          setDisabled(false);
          setTimeout(() => {
            setMessage("");
          }, 2000);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setMessage(resMessage);
          setDisabled(false);
        }
      );
    }
  }
  return (
    <>
      <CardLoading isShow={disabled} />
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold" style={h6style}>
              <ActiveSwitch
                disabled={disabled}
                onColor="#0ea5e9"
                checked={active}
                handleToggle={(e) => setActive(e.target.checked)}
              />{" "}
              Popular Searches
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={titleChangerSubmit}>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Settings
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-8/12 px-4 py-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Selector
                  </label>
                  <input
                    type="text"
                    className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={selector}
                    onChange={(e) => setSelector(e.target.value)}
                    name="selector"
                    required
                    disabled={disabled}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Position
                  </label>
                  <select
                    type="text"
                    className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                    name="position"
                    required
                    disabled={disabled}
                  >
                    <option value="beforebegin">beforebegin</option>
                    <option value="afterbegin">afterbegin</option>
                    <option value="afterend">afterend</option>
                    <option value="beforeend">beforeend</option>
                  </select>
                </div>

                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Selector for Mobile
                  </label>
                  <input
                    type="text"
                    className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={selectorMobile}
                    onChange={(e) => setSelectorMobile(e.target.value)}
                    name="selectorMobile"
                    required
                    disabled={disabled}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Position for Mobile
                  </label>
                  <select
                    type="text"
                    className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={positionMobile}
                    onChange={(e) => setPositionMobile(e.target.value)}
                    name="positionMobile"
                    required
                    disabled={disabled}
                  >
                    <option value="beforebegin">beforebegin</option>
                    <option value="afterbegin">afterbegin</option>
                    <option value="afterend">afterend</option>
                    <option value="beforeend">beforeend</option>
                  </select>
                </div>
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Search Selector for Mobile
                  </label>
                  <input
                    type="text"
                    className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={searchSelector}
                    onChange={(e) => setSearchSelector(e.target.value)}
                    name="searchSelector"
                    required
                    disabled={disabled}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Top for Mobile
                  </label>
                  <input
                    type="number"
                    className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={mobileTop}
                    onChange={(e) => setMobileTop(e.target.value)}
                    name="mobileTop"
                    required
                    disabled={disabled}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    htmlFor="backgroundColor"
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Background Color
                  </label>
                  <input
                    disabled={disabled}
                    type="color"
                    id="backgroundColor"
                    name="backgroundColor"
                    value={backgroundColor}
                    onChange={(e) => setBackgroundColor(e.target.value)}
                  />
                </div>

                <div className="relative w-full mb-3">
                  <label
                    htmlFor="color"
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Color
                  </label>
                  <input
                    disabled={disabled}
                    type="color"
                    id="color"
                    name="color"
                    value={color}
                    onChange={(e) => setColor(e.target.value)}
                  />
                </div>
              </div>
              {titleInputs.map((title, index) => (
                <div key={index} className="w-full lg:w-8/12 px-4 card-stories">
                  <span className="card-stories-title">
                    {index + 1}. Popular Search{" "}
                    <i
                      onClick={() => removeFormFields(index)}
                      className="fas fa-minus"
                    ></i>
                  </span>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Title {index + 1}
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={title.title}
                      name="title"
                      onChange={(e) =>
                        handleChange(e.target.value, e.target.name, index)
                      }
                      required
                      disabled={disabled}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      URL {index + 1}
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={title.url}
                      name="url"
                      onChange={(e) =>
                        handleChange(e.target.value, e.target.name, index)
                      }
                      required
                      disabled={disabled}
                    />
                  </div>
                </div>
              ))}
            </div>

            <button
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => addFormFields()}
            >
              <i className="fas fa-plus"></i>
            </button>
            <button
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="submit"
              disabled={disabled}
            >
              Save
            </button>
            {message}
          </form>
        </div>
      </div>
    </>
  );
}
