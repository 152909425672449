import api from "./api";

const postMail = (name,email,message) => {
  return api.post("/contact", {
    name,email,message
  });
};

const UsersService = {
  postMail
};

export default UsersService;
