import ActiveSwitch from "components/Inputs/ActiveSwitch";
import { CardLoading } from "components/Inputs/Loading";
import { alerts } from "helpers/Alerts";
import React, { useEffect, useRef, useState } from "react";
import AuthService from "services/auth.service";
import OpeningPopupService from "services/openingpopup.service";

// components
import { h6style } from "./style";

export default function CardOpeningPopup(props) {
  const [url, setUrl] = useState("");
  const [image, setImage] = useState("");
  const [time, setTime] = useState("");
  const [visitor, setVisitor] = useState("");
  const [openingPopupId, setOpeningPopupId] = useState("");
  const [message, setMessage] = useState("");
  const [blockedURLInputs, setBlockedURLInputs] = useState([]);
  const [urlAllowOrBlock, setUrlAllowOrBlock] = useState(false);
  const [active, setActive] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const currentUser = AuthService.getCurrentUser();
  const mountedRef = useRef(true);

  useEffect(() => {
    OpeningPopupService.getOpeningPopup(currentUser.user.id).then(
      (res) => {
        if (!mountedRef.current) return null;
        setUrl(res.data.url);
        setImage(res.data.image);
        setTime(res.data.time);
        setVisitor(res.data.visitor);
        setBlockedURLInputs(res.data.blockedURLInputs || []);
        setUrlAllowOrBlock(res.data.urlAllowOrBlock);
        setActive(res.data.active);
        setOpeningPopupId(res.data.id);
        setDisabled(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setDisabled(false);
      }
    );
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);

  function openingPopupSubmit(e) {
    e.preventDefault();
    setDisabled(true);

    OpeningPopupService.updateOpeningPopup(
      url,
      image,
      time,
      visitor,
      blockedURLInputs,
      urlAllowOrBlock,
      active,
      currentUser.user.id,
      openingPopupId
    ).then(
      (res) => {
        setMessage(alerts.positive);
        setDisabled(false);
        setTimeout(() => {
          setMessage("");
        }, 2000);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setDisabled(false);
        OpeningPopupService.createOpeningPopup(
          url,
          image,
          time,
          visitor,
          blockedURLInputs,
          urlAllowOrBlock,
          active,
          currentUser.user.id
        ).then(
          (res) => {
            setMessage(alerts.positive);
            setDisabled(false);
            setOpeningPopupId(res.data.id);
            setTimeout(() => {
              setMessage("");
            }, 2000);
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            setMessage(resMessage);
            setDisabled(false);
          }
        );
      }
    );
  }
  let handleChangeURL = (i, e) => {
    let newURL = [...blockedURLInputs];
    newURL[i].value = e.target.value;
    setBlockedURLInputs(newURL);
  };

  let addFormFieldsURL = () => {
    if (blockedURLInputs.length < 10)
      setBlockedURLInputs([...blockedURLInputs, { value: "" }]);
    else setMessage(alerts.titleChangerMax);
  };

  const removeFormFieldsURL = (i) => {
    const newURL = [...blockedURLInputs];
    newURL.splice(i, 1);
    setBlockedURLInputs(newURL);
  };
  return (
    <>
      <CardLoading isShow={disabled} />
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold" style={h6style}>
              <ActiveSwitch
                disabled={disabled}
                onColor="#0ea5e9"
                checked={active}
                handleToggle={(e) => setActive(e.target.checked)}
              />{" "}
              Opening Popup
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={openingPopupSubmit} encType="multipart/form-data">
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              View Settings
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-8/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    URL
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    required
                    disabled={disabled}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Image
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={(e) => setImage(e.target.value)}
                    disabled={disabled}
                    value={image}
                  />
                  <span>
                    <img src={image} alt="uploaded" width="200px" />
                  </span>
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Visitor Type
                  </label>
                  <input
                    type="radio"
                    id="all"
                    name="visitor"
                    value="all"
                    checked={visitor === "all"}
                    onChange={(e) => setVisitor("all")}
                  />
                  <label htmlFor="all"> All</label>
                  <br />
                  <input
                    type="radio"
                    id="new"
                    name="visitor"
                    value="new"
                    checked={visitor === "new"}
                    onChange={(e) => setVisitor("new")}
                  />
                  <label htmlFor="new"> New</label>
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Opening Time
                  </label>
                  <input
                    type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                    required
                    disabled={disabled}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    {urlAllowOrBlock === true ? "Allow" : "Block"}
                  </label>
                  <input
                    type="checkbox"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                    value={urlAllowOrBlock}
                    checked={urlAllowOrBlock}
                    onChange={(e) => setUrlAllowOrBlock(e.target.checked)}
                    disabled={disabled}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    URL
                  </label>
                  <button
                    className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => addFormFieldsURL()}
                  >
                    <i className="fas fa-plus"></i>
                  </button>
                </div>
                <div className="flex flex-wrap">
                  {blockedURLInputs.map((blockedURL, index) => (
                    <div key={index} className="w-full lg:w-8/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          URL {index + 1}
                        </label>
                        <input
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          value={blockedURL.value}
                          onChange={(e) => handleChangeURL(index, e)}
                          required
                          disabled={disabled}
                        />
                        <span
                          className="z-10 h-full leading-snug font-normal absolute text-center text-red-700 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3"
                          onClick={() => removeFormFieldsURL(index)}
                          style={{ cursor: "pointer", color: "red" }}
                        >
                          <i className="fas fa-minus"></i>
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <button
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="submit"
              disabled={disabled}
            >
              Save
            </button>
            {message}
          </form>
        </div>
      </div>
    </>
  );
}
