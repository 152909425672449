import React from "react";

// components

import CardExitPopup from "components/Cards/CardExitPopup";

export default function ExitPopup() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <CardExitPopup />
        </div>
      </div>
    </>
  );
}
