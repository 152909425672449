import React, { useEffect, useState, useRef } from "react";

// components

import CardLineChart from "components/Cards/CardLineChart.js";
import CardPageVisits from "components/Cards/CardPageVisits.js";
import axios from "axios";
import AuthService from "services/auth.service";
import { DateRangePicker, DateRange } from "react-date-range";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useParams } from "react-router";
import CardStats from "components/Cards/CardStats";
import moment from "moment";
import FreeShippingService from "services/freeshipping.service";

export default function Dashboard() {
  const [stats, setStats] = useState({
    clicks: 0,
    views: 0,
    aClicks: 0,
    purchases: 0,
  });
  const [isShowChart, setIsShowChart] = useState(true);
  const [updateData, setUpdateData] = useState(false);
  const [data, setData] = useState([]);
  const [assist, setAssist] = useState([]);
  const [compDate, setCompDate] = useState(false);
  const [dataComp, setDataComp] = useState([]);
  const [isShowDRP, setIsShowDRP] = useState(false);
  const [lineChartData, setLineChartData] = useState([]);
  const [dateType, setDateType] = useState("day");
  const [currency, setCurrency] = useState("TL");
  const [scn, setScn] = useState([
    {
      key: "Top Banner & Countdown",
      value: ["iyosa-TopBannerCountdown"],
    },
    {
      key: "BusinessUp! Footer",
      value: ["iyosa-buFooter"],
    },
    {
      key: "Exit Intent & Survey",
      value: ["iyosa-ExitIntent"],
    },
    {
      key: "Free Shipping Notice",
      value: ["iyosa-FreeShipping"],
    },
    {
      key: "Opening Popup",
      value: ["iyosa-OpeningPopup"],
    },
    {
      key: "Opportunity of Day",
      value: ["iyosa-Opportunity"],
    },
    {
      key: "Popular Search",
      value: ["iyosa-PopularSearch"],
    },
    {
      key: "Smart App Banner",
      value: ["iyosa-SmartAppBanner"],
    },
    {
      key: "Snow Effect",
      value: ["iyosa-Snow"],
    },
    {
      key: "Social Proof",
      value: ["iyosa-SocialProof"],
    },
    {
      key: "Wheel Of Fortune (Spin Wheel)",
      value: ["iyosa-WheelOfFortune"],
    },
    {
      key: "Story",
      value: ["iyosa-Stories"],
    },
    {
      key: "Trendyol Comments",
      value: ["iyosa-TrendyolComments"],
    },
    {
      key: "Contact via WhatsApp",
      value: ["iyosa-waPopup"],
    },
  ]);
  const [state, setState] = useState({
    selection: {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: "selection",
    },
    compare: {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: "compare",
    },
  });
  const { host, id } = useParams();
  const mountedRef = useRef(true);
  const currentUser = AuthService.getCurrentUser();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function countOccurences(arr, str) {
    let count = 0;
    for (let i = 0; i < arr.length; i++) {
      if (typeof arr[i] === "string" && arr[i].includes(str)) {
        count++;
      }
    }
    return count;
  }
  function currencyFormat(num) {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  useEffect(() => {
    let newStats = {
      clicks: 0,
      views: 0,
      aClicks: 0,
      purchases: 0,
    };
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      newStats.clicks += parseInt(element.clickTotal);
      newStats.views += parseInt(element.viewTotal);
    }
    newStats.aClicks = Object.values(assist).reduce(
      (partialSum, a) => partialSum + a,
      0
    );
    for (let x = 0; x < lineChartData.length; x++) {
      const el = lineChartData[x];
      newStats.purchases += el.purchaseTotal;
    }
    newStats.purchases = currencyFormat(newStats.purchases);
    setStats(newStats);
    let newScn = [...scn];
    for (let s = 0; s < newScn.length; s++) {
      const sc = newScn[s];
      sc.purchases = [];
      let a = data.filter((d) => d.scenario.includes(sc.key));
      if (a.length > 0) {
        sc.clicks = a[0].clickTotal;
        sc.views = a[0].viewTotal;
      }
      let b = Object.entries(assist).filter((d) => d[0].includes(sc.key));
      if (b.length > 0) sc.aClicks = b[0][1];

      if (lineChartData.length > 0) {
        for (let v = 0; v < sc.value.length; v++) {
          const va = sc.value[v];
          for (let l = 0; l < lineChartData.length; l++) {
            const p = lineChartData[l];

            let pt = p.purchaseTotal;
            let le = p.clicksAll
              .replaceAll("[", "")
              .replaceAll("]", "")
              .replaceAll('"', "")
              .split(",")
              .filter(function (el) {
                return el != "";
              }).length;
            let h = countOccurences(
              p.clicksAll
                .replaceAll("[", "")
                .replaceAll("]", "")
                .replaceAll('"', "")
                .split(",")
                .filter(function (el) {
                  return el != "";
                }),
              va
            );
            sc.purchases.push((pt / 100) * ((h * 100) / le));
          }
        }
      }
    }
    setScn(newScn);
  }, [data, assist, lineChartData, state]);
  useEffect(() => {
    if (host) {
      const hostname = host;
      async function fetchData() {
        const result = await axios.get(
          "https://webapi.iyosa.com/public/reports",
          {
            params: {
              hostname: hostname,
              startDate: state.selection.startDate,
              endDate: state.selection.endDate,
            },
          }
        );
        setData(result.data.result);
      }
      async function getPurchase() {
        const purchases = await axios.get(
          "https://webapi.iyosa.com/public/purchase",
          {
            params: {
              hostname: hostname,
              startDate: state.selection.startDate,
              endDate: state.selection.endDate,
              dateType: dateType,
            },
          }
        );
        let assistClicks = [];
        for (let index = 0; index < purchases.data.result.length; index++) {
          const el = purchases.data.result[index];
          let s = el.clicksAll
            .replaceAll("[", "")
            .replaceAll("]", "")
            .replaceAll('"', "")
            .split(",");
          assistClicks = assistClicks.concat(s);
        }
        const scenarios = [
          {
            key: "Top Banner & Countdown",
            value: ["iyosa-TopBannerCountdown"],
          },
          {
            key: "BusinessUp! Footer",
            value: ["iyosa-buFooter"],
          },
          {
            key: "Exit Intent & Survey",
            value: ["iyosa-ExitIntent"],
          },
          {
            key: "Free Shipping Notice",
            value: ["iyosa-FreeShipping"],
          },
          {
            key: "Opening Popup",
            value: ["iyosa-OpeningPopup"],
          },
          {
            key: "Opportunity of Day",
            value: ["iyosa-Opportunity"],
          },
          {
            key: "Popular Search",
            value: ["iyosa-PopularSearch"],
          },
          {
            key: "Smart App Banner",
            value: ["iyosa-SmartAppBanner"],
          },
          {
            key: "Snow Effect",
            value: ["iyosa-Snow"],
          },
          {
            key: "Social Proof",
            value: ["iyosa-SocialProof"],
          },
          {
            key: "Wheel Of Fortune (Spin Wheel)",
            value: ["iyosa-WheelOfFortune"],
          },
          {
            key: "Story",
            value: ["iyosa-Stories"],
          },
          {
            key: "Trendyol Comments",
            value: ["iyosa-TrendyolComments"],
          },
          {
            key: "Contact via WhatsApp",
            value: ["iyosa-waPopup"],
          },
        ];
        const countBy = (arr, fn) =>
          arr
            .map(typeof fn === "function" ? fn : (val) => val[fn])
            .reduce((acc, val) => {
              for (let i = 0; i < scenarios.length; i++) {
                const element = scenarios[i];
                if (
                  val.includes(element.value[0]) ||
                  val.includes(element.value[1])
                ) {
                  acc[element.key] = (acc[element.key] || 0) + 1;
                }
              }

              return acc;
            }, {});
        setAssist(countBy(assistClicks, (a) => a));
        setLineChartData(purchases.data.result);
      }
      getPurchase();
      fetchData();
    }
    return () => {
      setUpdateData(false);
    };
  }, [updateData]);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsShowDRP(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useEffect(() => {
    FreeShippingService.getFreeShipping(id).then(
      (res) => {
        if (!mountedRef.current) return null;
        if (res.data.totalFind === "₺") setCurrency("TL");
        else setCurrency(res.data.totalFind);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              type="button"
              onClick={() => setIsShowDRP(!isShowDRP)}
              style={{
                width: "150px",
                cursor: "pointer",
                height: "2.5em",
                lineHeight: "2.5em",
                border: "0px",
                background: "#fff",
                color: "#849095",
                borderRadius: "5px",
                margin: "0.833em",
              }}
            >
              {moment(state.selection.startDate).format("MMM D, YYYY")}
            </button>
            <button
              type="button"
              onClick={() => setIsShowDRP(!isShowDRP)}
              style={{
                width: "150px",
                cursor: "pointer",
                height: "2.5em",
                lineHeight: "2.5em",
                border: "0px",
                background: "#fff",
                color: "#849095",
                borderRadius: "5px",
                margin: "0.833em",
              }}
            >
              {moment(state.selection.endDate).format("MMM D, YYYY")}
            </button>
            <div
              ref={wrapperRef}
              style={{
                position: "absolute",
                zIndex: "10",
                display: isShowDRP ? "block" : "none",
              }}
            >
              {window.matchMedia("(max-width: 600px)").matches ? (
                <DateRange
                  onChange={(item) => setState({ ...state, ...item })}
                  ranges={
                    compDate
                      ? [state.selection, state.compare]
                      : [state.selection]
                  }
                  scroll={{ enabled: true }}
                  direction="vertical"
                  range
                />
              ) : (
                <DateRangePicker
                  onChange={(item) => setState({ ...state, ...item })}
                  ranges={
                    compDate
                      ? [state.selection, state.compare]
                      : [state.selection]
                  }
                  scroll={{ enabled: true }}
                  direction="vertical"
                />
              )}
              <span
                style={{
                  fontSize: "61px",
                  position: " absolute",
                  right: "-21px",
                  top: "-30px",
                  cursor: "pointer",
                }}
                onClick={() => setIsShowDRP(!isShowDRP)}
              >
                &times;
              </span>
              <button
                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                style={{ position: "absolute", bottom: "0", left: "4px" }}
                onClick={() => setUpdateData(true)}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
        <div className="w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="Views"
            statTitle={stats.views}
            statArrow="up"
            statPercent="3.48"
            statPercentColor="text-emerald-500"
            statDescripiron="Since last month"
            statIconName="far fa-eye"
            statIconColor="bg-teal-500"
          />
        </div>
        <div className="w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="Clicks"
            statTitle={stats.clicks}
            statArrow="up"
            statPercent="3.48"
            statPercentColor="text-emerald-500"
            statDescripiron="Since last month"
            statIconName="fa fa-mouse-pointer"
            statIconColor="bg-indigo-500"
          />
        </div>
        <div className="w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="Assisted Purchase"
            statTitle={stats.aClicks}
            statArrow="up"
            statPercent="3.48"
            statPercentColor="text-emerald-500"
            statDescripiron="Since last month"
            statIconName="fa fa-cart-plus"
            statIconColor="bg-lightBlue-500"
          />
        </div>
        <div className="w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="Assisted Revenue"
            statTitle={stats.purchases}
            statArrow="up"
            statPercent="3.48"
            statPercentColor="text-emerald-500"
            statDescripiron="Since last month"
            statIconName="fas fa-money-bill-wave-alt"
            statIconColor="bg-emerald-500"
            currency={currency}
          />
        </div>
      </div>
      <div className="flex flex-wrap mt-4">
        <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
          <div className="flex flex-wrap mt-4">
            <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
              <button
                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setIsShowChart(!isShowChart)}
              >
                {isShowChart ? (
                  <>
                    <i className="fas fa-eye-slash"></i> Hide
                  </>
                ) : (
                  <>
                    <i className="fas fa-eye"></i> Show
                  </>
                )}
              </button>
              {isShowChart && <CardLineChart data={lineChartData} />}
            </div>
          </div>
          <CardPageVisits
            data={data}
            assist={assist}
            scenarios={scn}
            currency={currency}
          />
        </div>
      </div>
    </>
  );
}
