import React from "react";

// components

import CardOpeningPopups from "components/AdminCards/CardOpeningPopups";

export default function SocialProofs() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <CardOpeningPopups />
        </div>
      </div>
    </>
  );
}
