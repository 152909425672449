/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import NotificationDropdown from "components/Dropdowns/NotificationDropdown.js";
import UserDropdown from "components/Dropdowns/UserDropdown.js";
import AuthService from "services/auth.service";
import "./style.css";

export default function Sidebar() {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const currentUser = AuthService.getCurrentUser();
  console.log(currentUser);
  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
            to="/"
          >
            iyosa
          </Link>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <NotificationDropdown />
            </li>
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    iyosa
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            <form className="mt-6 mb-4 md:hidden">
              <div className="mb-3 pt-0">
                <input
                  type="text"
                  placeholder="Search"
                  className="border-0 px-3 py-2 h-12 border border-solid  border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                />
              </div>
            </form>
            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Reports
            </h6>
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
              <li className="items-center">
                <Link
                  className={
                    "text-sm py-3 font-bold block " +
                    (window.location.href.indexOf(
                      `/admin/reports/${currentUser.user.hostname}/${currentUser.user.id}`
                    ) !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to={`/admin/reports/${currentUser.user.hostname}/${currentUser.user.id}`}
                  onClick={() => setCollapseShow("hidden")}
                >
                  <i
                    className={
                      "fa-solid fa-chart-simple mr-2 text-sm " +
                      (window.location.href.indexOf(
                        `/admin/reports/${currentUser.user.hostname}/${currentUser.user.id}`
                      ) !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Statistics
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-sm py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/survey") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/survey"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <i
                    className={
                      "fas fa-poll mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/survey") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Survey
                </Link>
              </li>
            </ul>

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Scenarios
            </h6>
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
              <li className="items-center">
                <Link
                  className={
                    "text-sm py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/managment") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/managment"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <i
                    className={
                      "fas fa-tasks mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/managment") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Scenario Management
                </Link>
              </li>
              <hr className="my-4 md:min-w-full" />
              <li className="items-center">
                <Link
                  className={
                    "text-sm  py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/title-changer") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/title-changer"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <i
                    className={
                      "fas fa-heading mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/title-changer") !==
                      -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Title Changer
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-sm  py-3 font-bold block " +
                    (window.location.href.indexOf(
                      "/admin/top-banner-changer"
                    ) !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/top-banner-changer"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <i
                    className={
                      "far fa-clock mr-2 text-sm " +
                      (window.location.href.indexOf(
                        "/admin/top-banner-changer"
                      ) !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Top Banner & Countdown
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-sm  py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/free-shipping") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/free-shipping"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <i
                    className={
                      "fas fa-truck-moving mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/free-shipping") !==
                      -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Shipping Free Notice
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-sm  py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/watched-bought") !==
                    -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/watched-bought"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <i
                    className={
                      "far fa-eye mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/watched-bought") !==
                      -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Viewed/Bought
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-sm  py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/opening-popup") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/opening-popup"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <i
                    className={
                      "far fa-window-restore mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/opening-popup") !==
                      -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Opening Popup
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-sm  py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/exit-popup") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/exit-popup"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <i
                    className={
                      "far fa-window-restore mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/exit-popup") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Exit Popup
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-sm  py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/stories") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/stories"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <i
                    className={
                      "far fa-circle mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/stories") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Stories
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-sm  py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/opportunity") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/opportunity"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <i
                    className={
                      "fas fa-bell mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/opportunity") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Opportunity
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-sm py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/spinwheel") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/spinwheel"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <i
                    className={
                      "far fa-life-ring mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/spinwheel") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Spin Wheel
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-sm py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/popularsearches") !==
                    -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/popularsearches"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <i
                    className={
                      "fas fa-fire mr-2 text-sm " +
                      (window.location.href.indexOf(
                        "/admin/popularsearches"
                      ) !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Populer Searches
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-sm py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/trendyolcomments") !==
                    -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/trendyolcomments"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <i
                    className={
                      "far fa-comments mr-2 text-sm " +
                      (window.location.href.indexOf(
                        "/admin/trendyolcomments"
                      ) !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Trendyol Comments
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-sm py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/smart-app-banner") !==
                    -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/smart-app-banner"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <i
                    className={
                      "fas fa-mobile mr-2 text-sm " +
                      (window.location.href.indexOf(
                        "/admin/smart-app-banner"
                      ) !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Smart App Banner
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-sm py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/snow-effect") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/snow-effect"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <i
                    aria-hidden="true"
                    className={
                      "fa fa-snowflake mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/snow-effect") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Snow Effect
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-sm py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/whatsapp") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/whatsapp"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <i
                    aria-hidden="true"
                    className={
                      "fas fa-whatsapp mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/whatsapp") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Order via WhatsApp
                </Link>
              </li>
              {/*
              <li className="items-center">
                <Link
                  className={
                    "text-sm py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/bufooter") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/bufooter"
                  onClick={() => setCollapseShow("hidden")}
                >
                  <i
                    className={
                      "fa fa-window-minimize mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/bufooter") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  BusinessUp! Footer
                </Link>
              </li>
              */}
            </ul>
            {/* Divider */}
            <hr className="my-4 md:min-w-full" />

            {/* Divider 
            <hr className="my-4 md:min-w-full" />

            <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
              <li className="items-center" onClick={() => AuthService.logout()}>
                <Link
                  className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  to="/auth/login"
                >
                  <i className="fas fa-sign-out-alt text-blueGray-400 mr-2 text-sm"></i>{" "}
                  Logout
                </Link>
              </li>
            </ul>

             Divider */}
            <hr className="my-4 md:min-w-full" />
          </div>
        </div>
      </nav>
    </>
  );
}
