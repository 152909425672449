import React from "react";

// components

import CardSnowEffectsEdit from "components/AdminCards/CardSnowEffectsEdit";

export default function SnowEffectsEdit() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <CardSnowEffectsEdit />
        </div>
      </div>
    </>
  );
}
