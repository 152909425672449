import React from "react";

// components

import CardBuFootersEdit from "components/AdminCards/CardBuFootersEdit";

export default function BuFootersEdit() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <CardBuFootersEdit />
        </div>
      </div>
    </>
  );
}
