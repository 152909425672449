import api from "./api";

const create = (data) => {
  return api.post("/whatsapp", data);
};

const update = (data) => {
  return api.patch(`/whatsapp/${data.owner}`, data);
};
const updateActive = (active, owner, id) => {
  return api.patch(`/whatsapp/status/${owner}`, {
    active,
    id,
  });
};
const get = (owner) => {
  return api.get(`/whatsapp/${owner}`);
};
const getAll = (limit, page, sortBy, active, company, isEmailVerified) => {
  return api.get(`/whatsapp`, {
    params: { limit, page, sortBy, active, company, isEmailVerified },
  });
};
const WhatsAppService = {
  create,
  update,
  get,
  getAll,
  updateActive,
};

export default WhatsAppService;
