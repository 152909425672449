import ActiveSwitch from "components/Inputs/ActiveSwitch";
import { alerts } from "helpers/Alerts";
import React, { useEffect, useRef, useState } from "react";
import FreeShippingService from "services/freeshipping.service";

import { freeShipping } from "components/Scenarios/freeShipping";

// components
import { h6style } from "../Cards/style";
import CardPreview from "../Cards/CardPreview";
import { CardLoading } from "components/Inputs/Loading";
import { useParams } from "react-router";
import { freeShippingStyle } from "../previewStyle.js";

export default function CardFreeShipping(props) {
  const [selector, setSelector] = useState("a");
  const [position, setPosition] = useState("");
  const [totalSelector, setTotalSelector] = useState("");
  const [totalFind, setTotalFind] = useState("");
  const [title, setTitle] = useState("");
  const [limit, setLimit] = useState("");
  const [fontSize, setFontSize] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("");
  const [borderRadius, setBorderRadius] = useState("");
  const [color, setColor] = useState("");
  const [textAlign, setTextAlign] = useState("");
  const [barBgColor, setBarBgColor] = useState("");
  const [freeShippingMsg, setFreeShippingMsg] = useState("");
  const [freeShippingMsgs, setFreeShippingMsgs] = useState([
    { freeShippingMsg: "", limit: "", image: "" },
  ]);
  const [freeShippingId, setFreeShippingId] = useState("");
  const [message, setMessage] = useState("");
  const [active, setActive] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const mountedRef = useRef(true);
  const [ri, setRi] = useState(0);

  let { owner, company } = useParams();
  function refreshIframe() {
    let i = ri + 1;
    setRi(i);
    console.log(ri);
  }
  useEffect(() => {
    const doc = document.getElementById("preview");
    const obj = {
      title,
      limit,
      fontSize,
      backgroundColor,
      borderRadius,
      color,
      textAlign,
      barBgColor,
      freeShippingMsg,
      freeShippingMsgs,
      selector,
      position,
      totalSelector,
      totalFind,
      active,
    };
    try {
      freeShipping(doc, obj);
    } catch (error) {
      console.log(error);
    }
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, [ri]);

  useEffect(() => {
    FreeShippingService.getFreeShipping(owner).then(
      (res) => {
        if (!mountedRef.current) return null;
        setTitle(res.data.title);
        setLimit(res.data.limit);
        setFontSize(res.data.fontSize);
        setBackgroundColor(res.data.backgroundColor);
        setBorderRadius(res.data.borderRadius);
        setColor(res.data.color);
        setTextAlign(res.data.textAlign);
        setBarBgColor(res.data.barBgColor);
        setFreeShippingMsg(res.data.freeShippingMsg);
        setFreeShippingMsgs(
          res.data.freeShippingMsgs || [
            { freeShippingMsg: "", limit: "", image: "" },
          ]
        );
        setSelector(res.data.selector);
        setPosition(res.data.position);
        setTotalSelector(res.data.totalSelector);
        setTotalFind(res.data.totalFind);
        setActive(res.data.active);
        setFreeShippingId(res.data.id);
        setDisabled(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setDisabled(false);
      }
    );
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);

  function freeShippingSubmit(e) {
    e.preventDefault();
    setDisabled(true);
    FreeShippingService.updateFreeShipping(
      title,
      limit,
      fontSize,
      backgroundColor,
      borderRadius,
      color,
      textAlign,
      barBgColor,
      freeShippingMsg,
      freeShippingMsgs,
      selector,
      position,
      totalSelector,
      totalFind,
      active,
      owner,
      freeShippingId
    ).then(
      (res) => {
        setMessage(alerts.positive);
        setDisabled(false);
        setTimeout(() => {
          setMessage("");
        }, 2000);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setDisabled(false);
        FreeShippingService.createFreeShipping(
          title,
          limit,
          fontSize,
          backgroundColor,
          borderRadius,
          color,
          textAlign,
          barBgColor,
          freeShippingMsg,
          freeShippingMsgs,
          selector,
          position,
          totalSelector,
          totalFind,
          active,
          owner
        ).then(
          (res) => {
            setMessage(alerts.positive);
            setDisabled(false);
            setFreeShippingId(res.data.id);
            setTimeout(() => {
              setMessage("");
            }, 2000);
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            setMessage(resMessage);
            setDisabled(false);
          }
        );
      }
    );
  }
  let handleChange = (value, name, index) => {
    let newFreeShippingMsgs = [...freeShippingMsgs];
    newFreeShippingMsgs[index][name] = value;
    setFreeShippingMsgs(newFreeShippingMsgs);
  };

  let addFormFields = () => {
    if (freeShippingMsgs.length < 5)
      setFreeShippingMsgs([
        ...freeShippingMsgs,
        { freeShippingMsg: "", limit: "", image: "" },
      ]);
  };

  const removeFormFields = (i) => {
    const newFreeShippingMsgs = [...freeShippingMsgs];
    if (newFreeShippingMsgs.length > 1) {
      newFreeShippingMsgs.splice(i, 1);
      setFreeShippingMsgs(newFreeShippingMsgs);
    }
  };
  const srcDoc = `
  <style>
  ${freeShippingStyle}
  </style>
  <div class="iyosa-freeshipping-bar" style="border-radius: ${borderRadius}px; color: ${color}; font-size: ${fontSize}px; background-color: ${backgroundColor}; font-family:Calibri">
    <div class="iyosa-freeshipping-text">
      ${title}
    </div>
    <div class="iyosa-freeshipping-level" style="background-color: ${barBgColor}; display: block; border-radius: ${borderRadius}px; width: 47.0341%;"></div>
  </div>
  `;
  return (
    <>
      <CardLoading isShow={disabled} />
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <h6 className="text-blueGray-700 text-xl font-bold" style={h6style}>
          {company}
        </h6>
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold" style={h6style}>
              <ActiveSwitch
                disabled={disabled}
                onColor="#0ea5e9"
                checked={active}
                handleToggle={(e) => setActive(e.target.checked)}
              />{" "}
              Shipping Free Notice
            </h6>
          </div>
        </div>

        <div
          className="w-full lg:w-12/12 px-4"
          style={{ position: "sticky", top: 0, zIndex: "999999" }}
        >
          <iframe
            srcDoc={srcDoc}
            title="Preview"
            width={"100%"}
            height={"100px"}
          />
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={freeShippingSubmit}>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Settings
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-8/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Selector
                  </label>
                  <input
                    type="text"
                    className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={selector}
                    onChange={(e) => setSelector(e.target.value)}
                    name="selector"
                    required
                    disabled={disabled}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Position
                  </label>
                  <select
                    type="text"
                    className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                    name="position"
                    required
                    disabled={disabled}
                  >
                    <option value="beforebegin">beforebegin</option>
                    <option value="afterbegin">afterbegin</option>
                    <option value="afterend">afterend</option>
                    <option value="beforeend">beforeend</option>
                  </select>
                </div>
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Total Amount Selector
                  </label>
                  <input
                    type="text"
                    className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={totalSelector}
                    onChange={(e) => setTotalSelector(e.target.value)}
                    name="totalSelector"
                    required
                    disabled={disabled}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Total Amount Currency
                  </label>
                  <input
                    type="text"
                    className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={totalFind}
                    onChange={(e) => setTotalFind(e.target.value)}
                    name="totalFind"
                    required
                    disabled={disabled}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Text
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                    maxLength={60}
                    disabled={disabled}
                  />
                  <span className="input-description">
                    "%b" is random number.
                  </span>
                </div>
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Completed Message
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={freeShippingMsg}
                    onChange={(e) => setFreeShippingMsg(e.target.value)}
                    required
                    maxLength={60}
                    disabled={disabled}
                  />
                  <span className="input-description">
                    "%b" is random number.
                  </span>
                </div>
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Add Limit
                  </label>
                  <button
                    className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => addFormFields()}
                  >
                    <i className="fas fa-plus"></i>
                  </button>
                </div>
                {freeShippingMsgs.map((el, index) => (
                  <div className="relative w-full mb-3" key={index}>
                    {index > 0 && (
                      <span
                        className="card-stories-title"
                        style={{
                          top: "-5px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {index + 1}
                        <i
                          onClick={() => removeFormFields(index)}
                          className="fas fa-minus"
                        ></i>
                      </span>
                    )}

                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        {index + 1}. Free Shipping Message
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={el.freeShippingMsg}
                        name="freeShippingMsg"
                        onChange={(e) =>
                          handleChange(e.target.value, e.target.name, index)
                        }
                        required
                        maxLength={60}
                        disabled={disabled}
                      />
                      <span className="input-description"></span>
                    </div>
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        {index + 1}. Image
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={el.image}
                        name="image"
                        onChange={(e) =>
                          handleChange(e.target.value, e.target.name, index)
                        }
                        required
                        disabled={disabled}
                      />
                      <span className="input-description"></span>
                    </div>
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        {index + 1}. Minimum Amount
                      </label>
                      <input
                        type="number"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={el.limit}
                        name="limit"
                        onChange={(e) =>
                          handleChange(e.target.value, e.target.name, index)
                        }
                        required
                        disabled={disabled}
                      />
                    </div>
                  </div>
                ))}

                <div className="relative w-full mb-3">
                  <label
                    htmlFor="fontSize"
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Font Size
                  </label>
                  <input
                    disabled={disabled}
                    type="number"
                    id="fontSize"
                    name="fontSize"
                    value={fontSize}
                    onChange={(e) => setFontSize(e.target.value)}
                  />
                  <span>px</span>
                </div>
                <div className="relative w-full mb-3">
                  <label
                    htmlFor="backgroundColor"
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Background Color
                  </label>
                  <input
                    disabled={disabled}
                    type="color"
                    id="backgroundColor"
                    name="backgroundColor"
                    value={backgroundColor}
                    onChange={(e) => setBackgroundColor(e.target.value)}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    htmlFor="barBgColor"
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Bar Background Color
                  </label>
                  <input
                    disabled={disabled}
                    type="color"
                    id="barBgColor"
                    name="barBgColor"
                    value={barBgColor}
                    onChange={(e) => setBarBgColor(e.target.value)}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    htmlFor="borderRadius"
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Border Radius
                  </label>
                  <input
                    disabled={disabled}
                    type="number"
                    id="borderRadius"
                    name="borderRadius"
                    value={borderRadius}
                    onChange={(e) => setBorderRadius(e.target.value)}
                  />
                  <span>px</span>
                </div>
                <div className="relative w-full mb-3">
                  <label
                    htmlFor="color"
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Text Color
                  </label>
                  <input
                    disabled={disabled}
                    type="color"
                    id="color"
                    name="color"
                    value={color}
                    onChange={(e) => setColor(e.target.value)}
                  />
                </div>
                {/* 
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Text Location
                  </label>
                  <input
                    type="radio"
                    id="left"
                    name="location"
                    value="left"
                    checked={textAlign === "left"}
                    onChange={(e) => setTextAlign("left")}
                  />
                  <label htmlFor="left"> Left</label>
                  <br />
                  <input
                    type="radio"
                    id="center"
                    name="location"
                    value="center"
                    checked={textAlign === "center"}
                    onChange={(e) => setTextAlign("center")}
                  />
                  <label htmlFor="center"> Center</label>
                  <br />
                </div>
                 */}
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4 py-4">
                <CardPreview refresh={refreshIframe} />
              </div>
            </div>
            <button
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="submit"
              disabled={disabled}
            >
              Save
            </button>
            {message}
          </form>
        </div>
      </div>
    </>
  );
}
