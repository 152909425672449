import "./style.css";
export const Loading = `
<style>
#content{
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    bottom:0;
    right:0;
    left:0;
    display:flex;
    align-items: center;
    justify-content: center;
}
.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ripple div {
    position: absolute;
    border: 4px solid #0ea5e9;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
</style>
<div id="content">
    <div class="lds-ripple">
      <div></div>
      <div></div>
    </div></div>`;
export const CardLoading = ({ isShow }) => {
  return (
    <div
      style={{
        backgroundColor: "#fff9",
        position: "fixed",
        zIndex: "99",
        bottom: "0",
        top: "0",
        left: "0",
        right: "0",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        display: isShow ? "flex" : "none",
      }}
    >
      <div className="lds-facebook">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
