import api from "./api";

const createSpinWheel = (
  segments,
  bgColor,
  title,
  titleColor,
  description,
  descriptionColor,
  btnText,
  btnBgColor,
  btnColor,
  spinWheelSize,
  spinWheelTextColor,
  countdownLimitless,
  countdown,
  bootTime,
  closeCount,
  days,
  blockedURLInputs,
  visitor,
  bottomDistance,
  rightDistance,
  visibilityStart,
  visibilityEnd,
  cbNextDay,
  alwaysShow,
  urlAllowOrBlock,
  location,
  swFrame,
  spinWheelBorderColor,
  spinWheelCenterText,
  pinsNumber,
  active,
  owner
) => {
  return api.post("/spinwheel", {
    segments,
    bgColor,
    title,
    titleColor,
    description,
    descriptionColor,
    btnText,
    btnBgColor,
    btnColor,
    spinWheelSize,
    spinWheelTextColor,
    countdownLimitless,
    countdown,
    bootTime,
    closeCount,
    days,
    blockedURLInputs,
    visitor,
    bottomDistance,
    rightDistance,
    visibilityStart,
    visibilityEnd,
    cbNextDay,
    alwaysShow,
    urlAllowOrBlock,
    location,
    swFrame,
    spinWheelBorderColor,
    spinWheelCenterText,
    pinsNumber,
    active,
    owner,
  });
};

const updateSpinWheel = (
  segments,
  bgColor,
  title,
  titleColor,
  description,
  descriptionColor,
  btnText,
  btnBgColor,
  btnColor,
  spinWheelSize,
  spinWheelTextColor,
  countdownLimitless,
  countdown,
  bootTime,
  closeCount,
  days,
  blockedURLInputs,
  visitor,
  bottomDistance,
  rightDistance,
  visibilityStart,
  visibilityEnd,
  cbNextDay,
  alwaysShow,
  urlAllowOrBlock,
  location,
  swFrame,
  spinWheelBorderColor,
  spinWheelCenterText,
  pinsNumber,
  active,
  owner,
  id
) => {
  return api.patch(`/spinwheel/${owner}`, {
    segments,
    bgColor,
    title,
    titleColor,
    description,
    descriptionColor,
    btnText,
    btnBgColor,
    btnColor,
    spinWheelSize,
    spinWheelTextColor,
    countdownLimitless,
    countdown,
    bootTime,
    closeCount,
    days,
    blockedURLInputs,
    visitor,
    bottomDistance,
    rightDistance,
    visibilityStart,
    visibilityEnd,
    cbNextDay,
    alwaysShow,
    urlAllowOrBlock,
    location,
    swFrame,
    spinWheelBorderColor,
    spinWheelCenterText,
    pinsNumber,
    active,
    owner,
    id,
  });
};
const updateActive = (active, owner, id) => {
  return api.patch(`/spinwheel/status/${owner}`, {
    active,
    id,
  });
};
const getSpinWheel = (owner) => {
  return api.get(`/spinwheel/${owner}`);
};
const getAll = (limit, page, sortBy, active, company, isEmailVerified) => {
  return api.get(`/spinwheel`, {
    params: { limit, page, sortBy, active, company, isEmailVerified },
  });
};
const SpinWheelService = {
  createSpinWheel,
  updateSpinWheel,
  getSpinWheel,
  getAll,
  updateActive,
};

export default SpinWheelService;
