import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import AuthService from "services/auth.service";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import AdminSidebar from "components/Sidebar/AdminSidebar";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Dashboard from "views/admin/Dashboard.js";
import Settings from "views/admin/Settings.js";
import Tables from "views/admin/Tables.js";
import TitleChanger from "views/admin/TitleChanger.js";
import TopBannerChanger from "views/admin/TopBanerChanger";
import FreeShipping from "views/admin/FreeShipping";
import WatchedBought from "views/admin/WatchedBought";
import OpeningPopup from "views/admin/OpeningPopup";
import ExitPopup from "views/admin/ExitPopup";
import Stories from "views/admin/Stories";
import StoriesNew from "views/admin/StoriesNew";
import Opportunity from "views/admin/Opportunity";
import Managment from "views/admin/Managment";
import Users from "views/admin/Users";
import AddUser from "views/admin/AddUser";
import EditUser from "views/admin/EditUser";
import SpinWheel from "views/admin/SpinWheel";
import PopularSearches from "views/admin/PopulerSearches";
import BuFooter from "views/admin/BuFooter";
import Survey from "views/admin/Survey";
import TrendyolComments from "views/admin/TrendyolComments";
import SmartAppBanner from "views/admin/SmartAppBanner";
import StoriesTable from "views/admin/StoriesTable";
import SnowEffect from "views/admin/SnowEffect";
import PreviewOptions from "views/admin/PreviewOptions";
import Titles from "views/admin/Titles";
import TitlesEdit from "views/admin/TitlesEdit";
import TopBanners from "views/admin/TopBanners";
import TopBannersEdit from "views/admin/TopBannersEdit";
import FreeShippings from "views/admin/FreeShippings";
import FreeShippingsEdit from "views/admin/FreeShippingsEdit";
import SocialProofs from "views/admin/SocialProofs";
import SocialProofsEdit from "views/admin/SocialProofsEdit";
import OpeningPopups from "views/admin/OpeningPopups";
import OpeningPopupsEdit from "views/admin/OpeningPopupsEdit";
import ExitPopups from "views/admin/ExitPopups";
import ExitPopupsEdit from "views/admin/ExitPopupsEdit";
import StoriesList from "views/admin/StoriesList";
import Opportunities from "views/admin/Opportunities";
import OpportunitiesEdit from "views/admin/OpportunitiesEdit";
import SpinWheels from "views/admin/SpinWheels";
import SpinWheelsEdit from "views/admin/SpinWheelsEdit";
import PopularSearchesEdit from "views/admin/PopularSearchesEdit";
import PopularSearchesList from "views/admin/PopularSearchesList";
import TrendyolCommentsList from "views/admin/TrendyolCommentsList";
import TrendyolCommentsEdit from "views/admin/TrendyolCommentsListEdit";
import SmartAppBanners from "views/admin/SmartAppBanners";
import SmartAppBannersEdit from "views/admin/SmartAppBannersEdit";
import SnowEffects from "views/admin/SnowEffects";
import SnowEffectsEdit from "views/admin/SnowEffectsEdit";
import BuFooters from "views/admin/BuFooters";
import BuFootersEdit from "views/admin/BuFootersEdit";
import PreviewOptionsList from "views/admin/PreviewOptionsList";
import PreviewOptionsEdit from "views/admin/PreviewOptionsListEdit";
import SurveyList from "views/admin/SurveyList";
import SurveyDetail from "views/admin/SurveyDetail";
import WhatsApp from "views/admin/WhatsApp";
import WhatsAppList from "views/admin/WhatsAppList";
import WhatsAppEdit from "views/admin/WhatsAppEdit";

export default function Admin() {
  const currentUser = AuthService.getCurrentUser();
  return (
    <>
      {currentUser.user.role === "adminisko" ? <AdminSidebar /> : <Sidebar />}

      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        <div className="px-1 md:px-10 mx-auto w-full mt-4">
          <Switch>
            {/* Admin Route Start*/}
            <Route path="/admin/users" exact component={Users} />
            <Route path="/admin/add-user" exact component={AddUser} />
            <Route path="/admin/users/:id" exact component={EditUser} />
            <Route path="/admin/settings" exact component={Settings} />
            <Route path="/admin/tables" exact component={Tables} />
            <Route path="/admin/title-changer-list" exact component={Titles} />
            <Route
              path="/admin/title-changer-list/:owner/:company"
              exact
              component={TitlesEdit}
            />
            <Route
              path="/admin/top-banner-changer-list"
              exact
              component={TopBanners}
            />
            <Route
              path="/admin/top-banner-changer-list/:owner/:company"
              exact
              component={TopBannersEdit}
            />
            <Route
              path="/admin/free-shipping-list"
              exact
              component={FreeShippings}
            />
            <Route
              path="/admin/free-shipping-list/:owner/:company"
              exact
              component={FreeShippingsEdit}
            />
            <Route
              path="/admin/watched-bought-list"
              exact
              component={SocialProofs}
            />
            <Route
              path="/admin/watched-bought-list/:owner/:company"
              exact
              component={SocialProofsEdit}
            />
            <Route
              path="/admin/opening-popup-list"
              exact
              component={OpeningPopups}
            />
            <Route
              path="/admin/opening-popup-list/:owner/:company"
              exact
              component={OpeningPopupsEdit}
            />
            <Route path="/admin/exit-popup-list" exact component={ExitPopups} />
            <Route
              path="/admin/exit-popup-list/:owner/:company"
              exact
              component={ExitPopupsEdit}
            />
            <Route path="/admin/stories-list" exact component={StoriesList} />
            <Route
              path="/admin/edit-story/:id/:owner/:company"
              exact
              component={Stories}
            />
            <Route
              path="/admin/opportunity-list"
              exact
              component={Opportunities}
            />
            <Route
              path="/admin/opportunity-list/:owner/:company"
              exact
              component={OpportunitiesEdit}
            />
            <Route path="/admin/spinwheel-list" exact component={SpinWheels} />
            <Route
              path="/admin/spinwheel-list/:owner/:company"
              exact
              component={SpinWheelsEdit}
            />
            <Route path="/admin/survey-list" exact component={SurveyList} />
            <Route
              path="/admin/survey-list/:owner/:company"
              exact
              component={SurveyDetail}
            />
            <Route
              path="/admin/popularsearches-list"
              exact
              component={PopularSearchesList}
            />
            <Route
              path="/admin/popularsearches-list/:owner/:company"
              exact
              component={PopularSearchesEdit}
            />
            <Route
              path="/admin/trendyolcomments-list"
              exact
              component={TrendyolCommentsList}
            />
            <Route
              path="/admin/trendyolcomments-list/:owner/:company"
              exact
              component={TrendyolCommentsEdit}
            />
            <Route
              path="/admin/smart-app-banner-list"
              exact
              component={SmartAppBanners}
            />
            <Route
              path="/admin/smart-app-banner-list/:owner/:company"
              exact
              component={SmartAppBannersEdit}
            />
            <Route path="/admin/bufooter-list" exact component={BuFooters} />
            <Route
              path="/admin/bufooter-list/:owner/:company"
              exact
              component={BuFootersEdit}
            />
            <Route
              path="/admin/snow-effect-list"
              exact
              component={SnowEffects}
            />
            <Route
              path="/admin/snow-effect-list/:owner/:company"
              exact
              component={SnowEffectsEdit}
            />
            <Route
              path="/admin/preview-options-list"
              exact
              component={PreviewOptionsList}
            />
            <Route
              path="/admin/preview-options-list/:owner/:company"
              exact
              component={PreviewOptionsEdit}
            />
            <Route path="/admin/whatsapp-list" exact component={WhatsAppList} />
            <Route
              path="/admin/whatsapp-list/:owner/:company"
              exact
              component={WhatsAppEdit}
            />
            {/* Admin Route End*/}

            <Route path="/admin/title-changer" exact component={TitleChanger} />
            <Route
              path="/admin/top-banner-changer"
              exact
              component={TopBannerChanger}
            />
            <Route path="/admin/free-shipping" exact component={FreeShipping} />
            <Route
              path="/admin/watched-bought"
              exact
              component={WatchedBought}
            />
            <Route path="/admin/opening-popup" exact component={OpeningPopup} />
            <Route path="/admin/exit-popup" exact component={ExitPopup} />
            <Route path="/admin/stories" exact component={StoriesTable} />
            <Route path="/admin/edit-story/:id" exact component={Stories} />
            <Route path="/admin/new-story" exact component={StoriesNew} />
            <Route path="/admin/managment" exact component={Managment} />
            <Route path="/admin/opportunity" exact component={Opportunity} />
            <Route path="/admin/spinwheel" exact component={SpinWheel} />
            <Route path="/admin/survey" exact component={Survey} />
            <Route
              path="/admin/popularsearches"
              exact
              component={PopularSearches}
            />
            <Route
              path="/admin/trendyolcomments"
              exact
              component={TrendyolComments}
            />
            <Route
              path="/admin/smart-app-banner"
              exact
              component={SmartAppBanner}
            />
            <Route path="/admin/whatsapp" exact component={WhatsApp} />
            <Route path="/admin/bufooter" exact component={BuFooter} />
            <Route path="/admin/snow-effect" exact component={SnowEffect} />
            <Route
              path="/admin/preview-options"
              exact
              component={PreviewOptions}
            />
            <Route path="/admin/reports" exact component={Dashboard} />
            <Route
              path="/admin/reports/:host/:id"
              exact
              component={Dashboard}
            />

            {currentUser.user.role === "adminisko" ? (
              <React.Fragment>
                <Route path="/admin/dashboard" exact component={Dashboard} />
                <Redirect from="/admin" to="/admin/dashboard" />
              </React.Fragment>
            ) : (
              <Redirect from="/admin" to="/admin/managment" />
            )}
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
