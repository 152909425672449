import React, { useState } from "react";
import PropTypes from "prop-types";

// components
import UsersService from "services/users.service";
import TitleService from "services/title.service";
import TopBannerService from "services/topbanner.service";
import FreeShippingService from "services/freeshipping.service";
import WatchedBoughtService from "services/watchedbougth.service";
import OpeningPopupService from "services/openingpopup.service";
import ExitPopupService from "services/exitpopup.service";
import StoriesService from "services/stories.service";
import OpportunityService from "services/opportunity.service";
import SpinWheelService from "services/spinwheel.service";
import { alerts } from "helpers/Alerts";

const initialValues = {
  titles: {
    title: [
      {
        value: "Hemen Gel 🙃",
      },
      {
        value: "Çok Özledim 😍",
      },
      {
        value: "Bekliyorum 😭",
      },
    ],
  },

  topbanners: {
    title: "%10 İndirimin Bitmesine",
    countdown: new Date(),
    color: "#ffffff",
    bgColor: "#333333",
    countColor: "#333333",
    countBgColor: "#ffffff",
    countBorderRadius: 5,
    countdownMsgLocation: "center",
    url: "http://localhost:8080/iyosa/product/beanie/",
    btnText: "Alışverişe Başla",
    btnLocation: "center",
    btnDistance: 3,
    btnBorderColor: "#ffffff",
    btnBgColor: "#ffffff",
    btnColor: "#171717",
    active: false,
    countdownFontSize: 15,
    titleFontSize: 20,
    btnDisplay: "flex",
    btnBorderRadius: 5,
    btnFontSize: 15,
    countdownDisplay: "flex",
    dayBox: "show",
    visitor: "all",
    cbNextDay: false,
    visibilityEnd: "23:46",
    visibilityStart: "14:00",
    alwaysShow: false,
    position: "afterend",
    selector: "#topmenu",
    showBodyTop: true,
  },
  freeshipping: {
    title: "Kargo Bedava kampanyası için sepetinize %b ürün ekleyin!",
    limit: 100,
    fontSize: "16",
    backgroundColor: "#f0f0f0",
    borderRadius: "5",
    color: "#333333",
    textAlign: "center",
    barBgColor: "#4545af",
  },
  watchedbought: {
    title: "Bu ürüne şuanda %b kişi bakıyor!",
    fontSize: 15,
    min: 2,
    max: 10,
    location: true,
    popupDelay: 2,
  },
  openingpopup: {
    url: "url",
    image: "image",
    time: 10,
    visitor: "all",
  },
  exitpopup: {
    url: "url",
    image: "image",
    day: 1,
  },
  stories: {
    story: [
      {
        title: "title",
        url: "url",
        image: "image",
      },
      {
        title: "title",
        url: "url",
        image: "image",
      },
      {
        title: "title",
        url: "url",
        image: "image",
      },
      {
        title: "title",
        url: "url",
        image: "image",
      },
      {
        title: "title",
        url: "url",
        image: "image",
      },
      {
        title: "title",
        url: "url",
        image: "image",
      },
      {
        title: "title",
        url: "url",
        image: "image",
      },
      {
        title: "title",
        url: "url",
        image: "image",
      },
      {
        title: "title",
        url: "url",
        image: "image",
      },
      {
        title: "title",
        url: "url",
        image: "image",
      },
    ],
  },
  opportunity: {
    opportunity: [
      {
        title: "title",
        url: "url",
        image: "image",
      },
      {
        title: "title",
        url: "url",
        image: "image",
      },
      {
        title: "title",
        url: "url",
        image: "image",
      },
    ],
    title: "Fırsat Kategoriler",
    bgColor: "#333333",
    closeColor: "#dddddd",
    color: "#ffffff",
    titleFontSize: "20",
    btnBgColor: "#ffffff",
    btnDisplay: "inline-flex",
    btnText: "Alışverişe Başla",
    btnFontSize: "16",
  },
  spinwheel: {
    segments: [
      {
        text: "",
        fillStyle: "",
        description: "",
        probability: "",
        code: "",
      },
      {
        text: "",
        fillStyle: "",
        description: "",
        probability: "",
        code: "",
      },
      {
        text: "",
        fillStyle: "",
        description: "",
        probability: "",
        code: "",
      },
    ],
    bgColor: "#333333",
    title: "Title",
    titleColor: "#ffffff",
    description: "Description",
    descriptionColor: "#ffffff",
    btnText: "Button",
    btnBgColor: "#ffffff",
    btnColor: "#333333",
    spinWheelSize: 15,
    spinWheelTextColor: "#232323",
    countdownLimitless: false,
    countdown: 60,
    bootTime: 1,
    closeCount: 1,
    days: 1,
    blockedURLInputs: [],
    visitor: "all",
  },
};

export default function CardUsers({ color }) {
  const [user, setUser] = useState({
    address: "",
    city: "",
    company: "",
    district: "",
    hostname: "",
    email: "",
    name: "",
    password: "",
    passwordR: "",
    phone: "",
    surname: "",
    role: "user",
    platform: "",
    url: "",
  });
  const [message, setMessage] = useState("");

  function handleChange(e) {
    let newObj = { ...user };
    newObj[e.target.name] = e.target.value;
    setUser(newObj);
  }
  function handleSubmit(e) {
    e.preventDefault();
    if (user.password === user.passwordR) {
      delete user.passwordR;
      UsersService.createUsers(user).then(
        (res) => {
          let owner = res.data.id;
          let titles = initialValues.titles;
          let topbanners = initialValues.topbanners;
          let freeshipping = initialValues.freeshipping;
          let watchedbought = initialValues.watchedbought;
          let openingpopup = initialValues.openingpopup;
          let exitpopup = initialValues.exitpopup;
          let stories = initialValues.stories;
          let opportunity = initialValues.opportunity;
          let spinwheel = initialValues.spinwheel;

          TitleService.createTitle(titles.title, false, owner);
          TopBannerService.createTopBanner(
            topbanners.title,
            topbanners.countdown,
            topbanners.color,
            topbanners.bgColor,
            topbanners.countColor,
            topbanners.countBgColor,
            topbanners.countBorderRadius,
            topbanners.countdownMsgLocation,
            topbanners.url,
            topbanners.btnText,
            topbanners.btnLocation,
            topbanners.btnDistance,
            topbanners.btnBorderColor,
            topbanners.btnBgColor,
            topbanners.btnColor,
            topbanners.titleFontSize,
            topbanners.countdownFontSize,
            topbanners.btnDisplay,
            topbanners.btnBorderRadius,
            topbanners.btnFontSize,
            topbanners.countdownDisplay,
            topbanners.dayBox,
            topbanners.visitor,
            false,
            owner
          );
          FreeShippingService.createFreeShipping(
            freeshipping.title,
            freeshipping.limit,
            freeshipping.fontSize,
            freeshipping.backgroundColor,
            freeshipping.borderRadius,
            freeshipping.color,
            freeshipping.textAlign,
            false,
            owner
          );
          WatchedBoughtService.createWatchedBought(
            watchedbought.title,
            watchedbought.fontSize,
            watchedbought.min,
            watchedbought.max,
            watchedbought.location,
            false,
            owner
          );
          OpeningPopupService.createOpeningPopup(
            openingpopup.url,
            openingpopup.image,
            openingpopup.time,
            openingpopup.visitor,
            false,
            owner
          );
          ExitPopupService.createExitPopup(
            exitpopup.url,
            exitpopup.image,
            exitpopup.day,
            false,
            owner
          );
          StoriesService.createStories(stories.story, false, owner);
          OpportunityService.createOpportunity(
            opportunity.opportunity,
            opportunity.title,
            opportunity.color,
            opportunity.bgColor,
            opportunity.closeColor,
            opportunity.titleFontSize,
            opportunity.btnBgColor,
            opportunity.btnDisplay,
            opportunity.btnText,
            opportunity.btnFontSize,
            false,
            owner
          );
          SpinWheelService.createSpinWheel(
            spinwheel.segments,
            spinwheel.bgColor,
            spinwheel.title,
            spinwheel.titleColor,
            spinwheel.description,
            spinwheel.descriptionColor,
            spinwheel.btnText,
            spinwheel.btnBgColor,
            spinwheel.btnColor,
            spinwheel.spinWheelSize,
            spinwheel.spinWheelTextColor,
            spinwheel.countdownLimitless,
            spinwheel.countdown,
            spinwheel.bootTime,
            spinwheel.closeCount,
            spinwheel.days,
            spinwheel.blockedURLInputs,
            spinwheel.visitor,
            false,
            owner
          );
          setMessage(alerts.addedUser);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setMessage(resMessage);
        }
      );
    } else setMessage(alerts.password);
    setTimeout(() => {
      setMessage("");
    }, 2000);
  }
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">
              Add Customer
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={handleSubmit}>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Customer Information
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Name
                  </label>
                  <input
                    onChange={handleChange}
                    required
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={user.name}
                    name="name"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Surname
                  </label>
                  <input
                    onChange={handleChange}
                    required
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={user.surname}
                    name="surname"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Hostname
                  </label>
                  <input
                    onChange={handleChange}
                    required
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={user.hostname}
                    name="hostname"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    E-mail
                  </label>
                  <input
                    onChange={handleChange}
                    required
                    type="email"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={user.email}
                    name="email"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Phone
                  </label>
                  <input
                    onChange={handleChange}
                    required
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={user.phone}
                    name="phone"
                  />
                </div>
              </div>
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Address Information
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Company Name
                  </label>
                  <input
                    onChange={handleChange}
                    required
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={user.company}
                    name="company"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    City
                  </label>
                  <input
                    onChange={handleChange}
                    required
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={user.city}
                    name="city"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    District
                  </label>
                  <input
                    onChange={handleChange}
                    required
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={user.district}
                    name="district"
                  />
                </div>
              </div>
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Address
                  </label>
                  <input
                    onChange={handleChange}
                    required
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={user.address}
                    name="address"
                  />
                </div>
              </div>
            </div>
            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Password
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Password
                  </label>
                  <input
                    onChange={handleChange}
                    required
                    type="password"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={user.password}
                    name="password"
                  />
                </div>
              </div>

              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Password Again
                  </label>
                  <input
                    onChange={handleChange}
                    required
                    type="password"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={user.passwordR}
                    name="passwordR"
                  />
                </div>
              </div>
            </div>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Feed Options
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Platform
                  </label>
                  <select
                    onChange={handleChange}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={user.platform}
                    name="platform"
                  >
                    <option value="">Seçiniz...</option>
                    <option value="ticimax">Ticimax</option>
                    <option value="ikas">ikas</option>
                    <option value="tsoft">T-Soft</option>
                  </select>
                </div>
              </div>

              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Feed URL
                  </label>
                  <input
                    onChange={handleChange}
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={user.url}
                    name="url"
                  />
                </div>
              </div>
            </div>
            <button
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="submit"
            >
              Add User
            </button>
            {message}
          </form>
        </div>
      </div>
    </>
  );
}

CardUsers.defaultProps = {
  color: "light",
};

CardUsers.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
