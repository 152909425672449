import api from "./api";

const create = (
  selector,
  position,
  textColor,
  bgColor,
  fontSize,
  text,
  viewStyle,
  url,
  active,
  owner
) => {
  return api.post("/bufooter", {
    selector,
    position,
    textColor,
    bgColor,
    fontSize,
    text,
    viewStyle,
    url,
    active,
    owner,
  });
};

const update = (
  selector,
  position,
  textColor,
  bgColor,
  fontSize,
  text,
  viewStyle,
  url,
  active,
  owner,
  id
) => {
  return api.patch(`/bufooter/${owner}`, {
    selector,
    position,
    textColor,
    bgColor,
    fontSize,
    text,
    viewStyle,
    url,
    active,
    owner,
    id,
  });
};
const updateActive = (active, owner, id) => {
  return api.patch(`/bufooter/status/${owner}`, {
    active,
    id,
  });
};
const get = (owner) => {
  return api.get(`/bufooter/${owner}`);
};
const getAll = (limit, page, sortBy, active, company, isEmailVerified) => {
  return api.get(`/bufooter`, {
    params: { limit, page, sortBy, active, company, isEmailVerified },
  });
};
const Services = {
  create,
  update,
  get,
  getAll,
  updateActive,
};

export default Services;
