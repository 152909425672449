import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

// components

import TableDropdown from "components/Dropdowns/TableDropdown.js";
import WatchedBoughtService from "services/watchedbougth.service";
import TablePagination from "components/Inputs/TablePagination";

export default function CardSocialProofs({ color }) {
  const [options, setOptions] = useState({
    limit: 10,
    page: 1,
    sortBy: "updatedAt:desc",
    active: null,
    company: null,
    isEmailVerified: null,
  });
  const [pagination, setPagination] = useState({ limit: 1, totalResults: 1 });
  const [datas, setDatas] = useState([]);
  const mountedRef = useRef(true);

  useEffect(() => {
    const { limit, page, sortBy, active, company, isEmailVerified } = options;
    WatchedBoughtService.getAll(
      limit,
      page,
      sortBy,
      active,
      company,
      isEmailVerified
    ).then(
      (res) => {
        let newObj = { ...pagination };
        newObj.limit = res.data.limit;
        newObj.totalResults =
          res.data.totalResults.length > 0
            ? res.data.totalResults[0].totalResults
            : 1;
        setPagination(newObj);
        setDatas(res.data.results);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(resMessage);
      }
    );
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, [options]);
  function setCurrentPage(page) {
    let newObj = { ...options };
    newObj.page = page;
    setOptions(newObj);
  }
  function setSearch(value) {
    let updated = { ...options };
    updated.company = value.length === 0 ? null : value;
    setOptions(updated);
  }
  function setStatus(value) {
    let updated = { ...options };
    updated.active = value === "null" ? null : value;
    setOptions(updated);
  }
  function setVerified(value) {
    let updated = { ...options };
    updated.isEmailVerified = value === "null" ? null : value;
    setOptions(updated);
  }

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1 flex justify-between">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                Social Proof List
              </h3>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          <div className="w-full lg:w-12/12 px-4 py-4 flex">
            <div className="relative w-full lg:w-6/12 mb-3 ml-3 mr-3">
              <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Search
              </label>
              <input
                type="search"
                className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                value={options.name}
                onChange={(e) => setSearch(e.target.value)}
                name="search"
                required
              />
            </div>

            <div className="relative w-full lg:w-6/12 mb-3 ml-3 mr-3">
              <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Status
              </label>

              <select
                onChange={(e) => setStatus(e.target.value)}
                className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              >
                <option value={"null"}>All</option>
                <option value={true}>Active</option>
                <option value={false}>Passive</option>
              </select>
            </div>
            <div className="relative w-full lg:w-6/12 mb-3 ml-3 mr-3">
              <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Company Status
              </label>

              <select
                onChange={(e) => setVerified(e.target.value)}
                className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              >
                <option value={"null"}>All</option>
                <option value={true}>Active</option>
                <option value={false}>Passive</option>
              </select>
            </div>
          </div>

          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Company
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Status
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Company Status
                </th>

                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                ></th>
              </tr>
            </thead>
            <tbody>
              {datas.map((data, index) => (
                <tr key={index}>
                  <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold " +
                        +(color === "light"
                          ? "text-blueGray-600"
                          : "text-white")
                      }
                    >
                      {data.company}
                    </span>
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {data.active ? (
                      <>
                        <i className="fas fa-circle text-emerald-500 mr-2"></i>{" "}
                        Active
                      </>
                    ) : (
                      <>
                        <i className="fas fa-circle text-red-500 mr-2"></i>{" "}
                        Passive
                      </>
                    )}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {data.isEmailVerified ? (
                      <>
                        <i className="fas fa-circle text-emerald-500 mr-2"></i>{" "}
                        Active
                      </>
                    ) : (
                      <>
                        <i className="fas fa-circle text-red-500 mr-2"></i>{" "}
                        Passive
                      </>
                    )}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                    <TableDropdown
                      to={`/admin/watched-bought-list/${data.owner}/${data.company}`}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <TablePagination
          className="pagination-bar"
          currentPage={options.page}
          totalCount={pagination.totalResults}
          pageSize={pagination.limit}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
}

CardSocialProofs.defaultProps = {
  color: "light",
};

CardSocialProofs.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
