import React from 'react';
import PropTypes from 'prop-types';
import './style.css'

const ActiveSwitch = ({ checked, handleToggle, onColor, disabled, name }) => {
    return (
      <>
        <input
          checked={checked}
          onChange={handleToggle}
          disabled={disabled}
          className="react-switch-checkbox"
          id={name}
          type="checkbox"
        />
        <label
          style={{ background: checked && onColor }}
          className="react-switch-label"
          htmlFor={name}
        >
          <span className={`react-switch-button`} />
        </label>
      </>
    );
  };

export default ActiveSwitch;
ActiveSwitch.defaultProps = {
  name : "react-switch-new"
}
ActiveSwitch.propTypes = {
    checked : PropTypes.bool,
    handleToggle : PropTypes.func,
    onColor: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string
}