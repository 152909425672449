import React from "react";

// components

import CardFreeShipping from "components/Cards/CardFreeShipping";

export default function FreeShipping() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <CardFreeShipping />
        </div>
      </div>
    </>
  );
}
