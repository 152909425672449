import api from "./api";

const create = (question, answers, owner) => {
  return api.post("/surveyanswers", {
    question,
    answers,
    owner,
  });
};

const update = (question, answers, owner, id) => {
  return api.patch(`/surveyanswers/${owner}`, {
    question,
    answers,
    owner,
    id,
  });
};
const updateActive = (active, owner, id) => {
  return api.patch(`/surveyanswers/status/${owner}`, {
    active,
    id,
  });
};
const get = (owner) => {
  return api.get(`/surveyanswers/${owner}`);
};
const getAll = (limit, page, sortBy, active, company, isEmailVerified) => {
  return api.get(`/surveyanswers`, {
    params: { limit, page, sortBy, active, company, isEmailVerified },
  });
};
const SurveyService = {
  create,
  update,
  get,
  getAll,
  updateActive,
};

export default SurveyService;
