import React from "react";

import UserDropdown from "components/Dropdowns/UserDropdown.js";
import { Link } from "react-router-dom";
import AuthService from "services/auth.service";

export default function Navbar() {
  return (
    <>
      {/* Navbar */}
      <nav className="bg-lightBlue-600 top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
        <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
          {/* Brand */}
          <a
            className="text-white text-sm uppercase hidden lg:inline-block font-semibold"
            href="!#"
            onClick={(e) => e.preventDefault()}
          >
            Panel
          </a>
          {/* User */}
          <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
            <UserDropdown />
            <li
              className="items-center"
              onClick={() => AuthService.logout()}
              style={{ marginLeft: "20px" }}
            >
              <Link
                className="text-blueGray-100 hover:text-blueGray-100 text-sm  py-3 font-bold block"
                to="/auth/login"
              >
                <i className="fas fa-sign-out-alt text-blueGray-100 mr-2 text-sm"></i>{" "}
                Logout
              </Link>
            </li>
          </ul>
        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
}
