import React from "react";

// components

import CardBuFooters from "components/AdminCards/CardBuFooters";

export default function BuFooters() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <CardBuFooters />
        </div>
      </div>
    </>
  );
}
