import api from "./api";

const createStories = (
  storyName,
  allowURLInputs,
  story,
  selector,
  position,
  active,
  owner
) => {
  return api.post("/stories", {
    storyName,
    allowURLInputs,
    story,
    selector,
    position,
    active,
    owner,
  });
};

const updateStories = (
  storyName,
  allowURLInputs,
  story,
  selector,
  position,
  active,
  owner,
  id
) => {
  return api.patch(`/stories/${owner}`, {
    storyName,
    allowURLInputs,
    story,
    selector,
    position,
    active,
    owner,
    id,
  });
};
const updateActive = (active, owner, id) => {
  return api.patch(`/stories/status/${owner}`, {
    active,
    id,
  });
};
const getStories = (owner) => {
  return api.get(`/stories/${owner}`);
};
const getStoriesByID = (id) => {
  return api.get(`/stories/id/${id}`);
};
const getAll = (limit, page, sortBy, active, company, isEmailVerified) => {
  return api.get(`/stories`, {
    params: { limit, page, sortBy, active, company, isEmailVerified },
  });
};
const StoriesService = {
  createStories,
  updateStories,
  getStories,
  getAll,
  updateActive,
  getStoriesByID,
};

export default StoriesService;
