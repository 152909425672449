import React from "react";

// components

import CardSurveyList from "components/AdminCards/CardSurveyList";

export default function SurveyList() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <CardSurveyList />
        </div>
      </div>
    </>
  );
}
