import ActiveSwitch from "components/Inputs/ActiveSwitch";
import { CardLoading } from "components/Inputs/Loading";
import { smartAppBannerStyle } from "components/previewStyle";
import { alerts } from "helpers/Alerts";
import React, { useEffect, useRef, useState } from "react";
import AuthService from "services/auth.service";
import SmartAppBannerService from "services/smartappbanner.service";

// components
import { h6style } from "./style";

export default function CardSmartAppBanner(props) {
  const [logo, setLogo] = useState("logo url");
  const [backgroundColor, setBackgroundColor] = useState("#f2f2f2");
  const [description, setDescription] = useState(
    "Uygulamayı yükle, fırsatlardan haberdar ol!"
  );
  const [buttonText, setButtonText] = useState("Yükle");
  const [starsShow, setStarsShow] = useState(true);
  const [title, setTitle] = useState("Uygulama Adı");
  const [iosURL, setIosURL] = useState("app store url");
  const [androidURL, setAndroidURL] = useState("google play store url");
  const [color, setColor] = useState("#232323");
  const [btnBgColor, setBtnBgColor] = useState("#0000ff");
  const [btnColor, setBtnColor] = useState("#ffffff");
  const [expiredDay, setExpiredDay] = useState(3);
  const [id, setID] = useState("");
  const [message, setMessage] = useState("");
  const [active, setActive] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const currentUser = AuthService.getCurrentUser();
  const mountedRef = useRef(true);

  useEffect(() => {
    SmartAppBannerService.get(currentUser.user.id).then(
      (res) => {
        if (!mountedRef.current) return null;
        setTitle(res.data.title);
        setIosURL(res.data.iosURL);
        setAndroidURL(res.data.androidURL);
        setColor(res.data.color);
        setExpiredDay(res.data.expiredDay);
        setLogo(res.data.logo);
        setStarsShow(res.data.starsShow);
        setBackgroundColor(res.data.backgroundColor);
        setBtnBgColor(res.data.btnBgColor);
        setBtnColor(res.data.btnColor);
        setDescription(res.data.description);
        setButtonText(res.data.buttonText);
        setActive(res.data.active);
        setID(res.data.id);
        setDisabled(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        SmartAppBannerService.create(
          logo,
          title,
          description,
          buttonText,
          androidURL,
          iosURL,
          expiredDay,
          starsShow,
          backgroundColor,
          color,
          btnColor,
          btnBgColor,
          false,
          currentUser.user.id
        ).then((res) => {
          setDisabled(false);
          setID(res.data.id);
        });
      }
    );
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);

  function submit(e) {
    e.preventDefault();
    setDisabled(true);
    SmartAppBannerService.update(
      logo,
      title,
      description,
      buttonText,
      androidURL,
      iosURL,
      expiredDay,
      starsShow,
      backgroundColor,
      color,
      btnColor,
      btnBgColor,
      active,
      currentUser.user.id,
      id
    ).then(
      (res) => {
        setMessage(alerts.positive);
        setDisabled(false);
        setTimeout(() => {
          setMessage("");
        }, 2000);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setDisabled(false);
      }
    );
  }

  const srcDoc = `
  <style>
  ${smartAppBannerStyle}
  </style>
  <div class="iyosa-smart-app-banner-container" style="background-color: ${backgroundColor}; color: ${color};">
    <div class="iyosa-smart-app-close">
        ×
    </div>
    <div class="iyosa-smart-app-logo">
        <img src="${logo}" alt="mobile-app-logo">
    </div>
    <div class="iyosa-smart-app-content">
        <div class="iyosa-smart-app-title">
        ${title}
        </div>
        ${
          starsShow
            ? `<div class="iyosa-stars" style="display: inline-block;"></div>`
            : ""
        }
        
        <div class="iyosa-smart-app-desc">
        ${description}
        </div>
    </div>
    <div class="iyosa-smart-app-button">
        <a href="${iosURL}" class="iyosa-smart-app-banner-btn" style="background-color: ${btnBgColor}; color: ${btnColor};">${buttonText}</a>
    </div>
  </div>
  `;
  return (
    <>
      <CardLoading isShow={disabled} />
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold" style={h6style}>
              <ActiveSwitch
                disabled={disabled}
                onColor="#0ea5e9"
                checked={active}
                handleToggle={(e) => setActive(e.target.checked)}
              />{" "}
              Smart App Banner
            </h6>
          </div>
        </div>

        <div
          className="w-full lg:w-12/12 px-4"
          style={{ position: "sticky", top: 0, zIndex: "999999" }}
        >
          <iframe
            srcDoc={srcDoc}
            title="Preview"
            width={"100%"}
            height={"150px"}
            style={{
              maxWidth: "500px",
            }}
          />
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={submit}>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Settings
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-8/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Logo
                  </label>
                  <input
                    type="text"
                    className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={logo}
                    onChange={(e) => setLogo(e.target.value)}
                    name="logo"
                    required
                    disabled={disabled}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Title
                  </label>
                  <input
                    type="text"
                    className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    name="title"
                    required
                    disabled={disabled}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Description
                  </label>
                  <input
                    type="text"
                    className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    name="description"
                    required
                    disabled={disabled}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Button Text
                  </label>
                  <input
                    type="text"
                    className="border-0 px-4 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={buttonText}
                    onChange={(e) => setButtonText(e.target.value)}
                    name="buttonText"
                    required
                    disabled={disabled}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Google Play Store URL
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={androidURL}
                    name="androidURL"
                    onChange={(e) => setAndroidURL(e.target.value)}
                    required
                    disabled={disabled}
                  />
                </div>

                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    App Store URL
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={iosURL}
                    name="iosURL"
                    onChange={(e) => setIosURL(e.target.value)}
                    required
                    disabled={disabled}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Expired Day
                  </label>
                  <input
                    type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={expiredDay}
                    name="expiredDay"
                    onChange={(e) => setExpiredDay(e.target.value)}
                    required
                    disabled={disabled}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Stars Show/Hide
                  </label>
                  <input
                    type="checkbox"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                    value={starsShow}
                    checked={starsShow}
                    onChange={(e) => setStarsShow(e.target.checked)}
                    disabled={disabled}
                  />
                  <span> {starsShow ? "Show" : "Hide"}</span>
                </div>
                <div className="relative w-full mb-3">
                  <label
                    htmlFor="expiredDay"
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Background Color
                  </label>
                  <input
                    disabled={disabled}
                    type="color"
                    id="backgroundColor"
                    name="backgroundColor"
                    value={backgroundColor}
                    onChange={(e) => setBackgroundColor(e.target.value)}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    htmlFor="color"
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Text Color
                  </label>
                  <input
                    disabled={disabled}
                    type="color"
                    id="color"
                    name="color"
                    value={color}
                    onChange={(e) => setColor(e.target.value)}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    htmlFor="color"
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Button Text Color
                  </label>
                  <input
                    disabled={disabled}
                    type="color"
                    id="btnColor"
                    name="btnColor"
                    value={btnColor}
                    onChange={(e) => setBtnColor(e.target.value)}
                  />
                </div>
                <div className="relative w-full mb-3">
                  <label
                    htmlFor="color"
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Button Background Color
                  </label>
                  <input
                    disabled={disabled}
                    type="color"
                    id="btnBgColor"
                    name="btnBgColor"
                    value={btnBgColor}
                    onChange={(e) => setBtnBgColor(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <button
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="submit"
              disabled={disabled}
            >
              Save
            </button>
            {message}
          </form>
        </div>
      </div>
    </>
  );
}
