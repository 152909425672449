const snowEffect = (doc, props) => {
  if (props.urls.length === 0) {
    if (doc.contentWindow.document.querySelector("#iyosa-snow")) return;
    snowElements(doc);
  } else if (
    props.urls.some((e) => e.value === window.location.href) ===
    props.urlAllowOrBlock
  ) {
    if (doc.contentWindow.document.querySelector("#iyosa-snow")) return;
    snowElements(doc);
  } else {
    if (doc.contentWindow.document.querySelector("#iyosa-snow"))
      doc.contentWindow.document.querySelector("#iyosa-snow").remove();
  }
};

const snowElements = (doc) => {
  const snowDiv = doc.contentWindow.document.createElement("div");
  snowDiv.id = "iyosa-snow";
  const snowStyle = doc.contentWindow.document.createElement("style");
  const style = `#iyosa-snow {
    display:block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.01);
    background-image: url(https://cdn.iyosa.com/src/assets/img/snow.png), 
                      url(https://cdn.iyosa.com/src/assets/img/snow1.png), 
                      url(https://cdn.iyosa.com/src/assets/img/snow2.png);
    background-repeat: repeat;
    z-index: 100000;
    pointer-events: none;
    -moz-animation: snow_ef_t4 10s linear infinite;
    -webkit-animation: snow_ef_t4 10s linear infinite;
    animation: snow_ef_t4 10s linear infinite;
  }
  @-moz-keyframes snow_ef_t4 {
    0% {
      background-position: 0 0, 0 0, 0 0;
    }
    100% {
      background-position: 500px 3000px, 0 400px, 0 300px;
    }
  }
  @-webkit-keyframes snow_ef_t4 {
    0% {
      background-position: 0 0, 0 0, 0 0;
    }
    100% {
      background-position: 500px 3000px, 0 400px, 0 300px;
    }
  }
  @keyframes snow_ef_t4 {
    0% {
      background-position: 0 0, 0 0, 0 0;
    }
    100% {
      background-position: 500px 3000px, 0 400px, 0 300px;
    }
  }`;
  snowStyle.textContent = style;
  doc.contentWindow.document.head.appendChild(snowStyle);
  doc.contentWindow.document.body.appendChild(snowDiv);
};
export default snowEffect;
