import api from "./api";

const create = (
  logo,
  title,
  description,
  buttonText,
  androidURL,
  iosURL,
  expiredDay,
  starsShow,
  backgroundColor,
  color,
  btnColor,
  btnBgColor,
  active,
  owner
) => {
  return api.post("/smart-app-banner", {
    logo,
    title,
    description,
    buttonText,
    androidURL,
    iosURL,
    expiredDay,
    starsShow,
    backgroundColor,
    color,
    btnColor,
    btnBgColor,
    active,
    owner,
  });
};

const update = (
  logo,
  title,
  description,
  buttonText,
  androidURL,
  iosURL,
  expiredDay,
  starsShow,
  backgroundColor,
  color,
  btnColor,
  btnBgColor,
  active,
  owner,
  id
) => {
  return api.patch(`/smart-app-banner/${owner}`, {
    logo,
    title,
    description,
    buttonText,
    androidURL,
    iosURL,
    expiredDay,
    starsShow,
    backgroundColor,
    color,
    btnColor,
    btnBgColor,
    active,
    owner,
    id,
  });
};
const updateActive = (active, owner, id) => {
  return api.patch(`/smart-app-banner/status/${owner}`, {
    active,
    id,
  });
};
const get = (owner) => {
  return api.get(`/smart-app-banner/${owner}`);
};
const getAll = (limit, page, sortBy, active, company, isEmailVerified) => {
  return api.get(`/smart-app-banner`, {
    params: { limit, page, sortBy, active, company, isEmailVerified },
  });
};
const SmartAppBannerService = {
  create,
  update,
  get,
  getAll,
  updateActive,
};

export default SmartAppBannerService;
