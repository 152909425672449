import React, { useEffect, useRef, useState } from "react";
import Services from "services/previewoptions.service";
import AuthService from "services/auth.service";
import decodeHTMLEntities from "helpers/htmlDecode";
import { Loading } from "components/Inputs/Loading";
import { useParams } from "react-router";

function CardPreview(props) {
  const [homepage, setHomePage] = useState("Preview");
  const [collection, setCollection] = useState("Preview");
  const [productDetail, setProductDetail] = useState("Preview");
  const [cart, setCart] = useState("Preview");
  const [selected, setSelected] = useState("");
  const [showModal, setShowModal] = useState(false);
  const currentUser = AuthService.getCurrentUser();
  const mountedRef = useRef(true);

  let { owner } = useParams();

  useEffect(() => {
    setSelected(Loading);
    if (owner) {
      Services.get(owner).then((res) => {
        if (!mountedRef.current) return null;
        setHomePage(res.data.iframeHtmlHome);
        setSelected(res.data.iframeHtmlHome);
        setCollection(res.data.iframeHtmlCollection);
        setProductDetail(res.data.iframeHtmlProductDetail);
        setCart(res.data.iframeHtmlCart);
      });
    } else {
      Services.get(currentUser.user.id).then((res) => {
        if (!mountedRef.current) return null;
        setHomePage(res.data.iframeHtmlHome);
        setSelected(res.data.iframeHtmlHome);
        setCollection(res.data.iframeHtmlCollection);
        setProductDetail(res.data.iframeHtmlProductDetail);
        setCart(res.data.iframeHtmlCart);
      });
    }

    return () => {
      // cancel the subscription
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <button
        type="button"
        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        onClick={() => setShowModal(true)}
      >
        Preview
      </button>
      <div className={showModal ? "modal-show modal" : "modal"}>
        <div className="modal__content">
          <h1>Preview</h1>
          <div className="flex">
            <button
              type="button"
              className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              onClick={() => props.refresh()}
            >
              Refresh
            </button>
            <button
              type="button"
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              onClick={() => setSelected(homepage)}
            >
              Home Page
            </button>
            <button
              type="button"
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              onClick={() => setSelected(collection)}
            >
              Collection
            </button>
            <button
              type="button"
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              onClick={() => setSelected(productDetail)}
            >
              Product Detail
            </button>
            <button
              type="button"
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              onClick={() => setSelected(cart)}
            >
              Cart
            </button>
          </div>
          <div>
            <iframe
              className="preview"
              id="preview"
              width={"100%"}
              height={"650px"}
              srcDoc={decodeHTMLEntities(selected)}
              title="preview"
              loading="lazy"
            ></iframe>
          </div>

          <div className="modal__footer"></div>

          <button
            type="button"
            onClick={() => setShowModal(false)}
            className="modal__close"
          >
            &times;
          </button>
        </div>
      </div>
    </>
  );
}
export default CardPreview;
