import api from "./api";

const createUsers = (user) => {
  return api.post("/users", user);
};

const updateUsers = (user, id) => {
  return api.patch(`/users/${id}`, user);
};

const getUsers = (limit, page, sortBy, role, isEmailVerified, company) => {
  return api.get(`/users`, {
    params: { limit, page, sortBy, role, isEmailVerified, company },
  });
};
const getUser = (id) => {
  return api.get(`/users/${id}`);
};
const createFeed = (hostname) => {
  return api.get(`/feeds/${hostname}`);
};
const UsersService = {
  createUsers,
  updateUsers,
  getUsers,
  getUser,
  createFeed,
};

export default UsersService;
