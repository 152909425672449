import React from "react";

// components

import CardSocialProofs from "components/AdminCards/CardSocialProofs";

export default function SocialProofs() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <CardSocialProofs />
        </div>
      </div>
    </>
  );
}
