import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// components

export default function CardPageVisits(props) {
  const [sc, setSc] = useState([]);
  const [sorting, setSorting] = useState({
    purchases: {
      sort: true,
      symbol: "",
    },
    key: {
      sort: true,
      symbol: "",
    },
    clicks: {
      sort: true,
      symbol: "",
    },
    views: {
      sort: true,
      symbol: "",
    },
    aClicks: {
      sort: true,
      symbol: "",
    },
  });
  function currencyFormat(num) {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  function sortPurchase() {
    props.scenarios.sort(function (a, b) {
      let aPurchase = 0;
      let bPurchase = 0;
      if (a.purchases)
        aPurchase = a.purchases.reduce((partialSum, t) => partialSum + t, 0);

      if (b.purchases)
        bPurchase = b.purchases.reduce((partialSum, t) => partialSum + t, 0);
      if (sorting.purchases.sort) {
        if (parseFloat(aPurchase) < parseFloat(bPurchase)) {
          return -1;
        }
      } else {
        if (parseFloat(aPurchase) > parseFloat(bPurchase)) {
          return -1;
        }
      }
    });
    let newSort = { ...sorting };
    Object.keys(newSort).forEach((element) => {
      newSort[element].symbol = "";
    });
    newSort.purchases.sort = !sorting.purchases.sort;
    newSort.purchases.symbol = sorting.purchases.sort ? "↑" : "↓";
    setSorting(newSort);
    setSc(props.scenarios);
  }
  function sortOther(key) {
    props.scenarios.sort(function (a, b) {
      if (!parseInt(a[key])) a[key] = 0;
      if (!parseInt(b[key])) b[key] = 0;
      if (sorting[key].sort) {
        if (parseInt(a[key]) < parseInt(b[key])) {
          return -1;
        }
      } else {
        if (parseInt(a[key]) > parseInt(b[key])) {
          return -1;
        }
      }
    }, key);

    let newSort = { ...sorting };
    Object.keys(newSort).forEach((element) => {
      newSort[element].symbol = "";
    });
    newSort[key].sort = !sorting[key].sort;
    newSort[key].symbol = sorting[key].sort ? "↑" : "↓";
    setSorting(newSort);
    setSc(props.scenarios);
  }
  function sortString() {
    props.scenarios.sort(function (a, b) {
      if (sorting.key.sort) {
        if (a.key < b.key) {
          return -1;
        }
      } else {
        if (a.key > b.key) {
          return -1;
        }
      }
    });

    let newSort = { ...sorting };
    Object.keys(newSort).forEach((element) => {
      newSort[element].symbol = "";
    });
    newSort.key.sort = !sorting.key.sort;
    newSort.key.symbol = sorting.key.sort ? "↑" : "↓";
    setSorting(newSort);
    setSc(props.scenarios);
  }
  useEffect(() => {
    setSc(props.scenarios);
    /*
    props.scenarios.sort(function (a, b) {
      let aPurchase = 0;
      let bPurchase = 0;
      if (a.purchases)
        aPurchase = a.purchases.reduce((partialSum, t) => partialSum + t, 0);

      if (b.purchases)
        bPurchase = b.purchases.reduce((partialSum, t) => partialSum + t, 0);
      if (!parseFloat(a.views)) a.views = 0;
      if (!parseFloat(b.views)) b.views = 0;
      if (parseFloat(aPurchase) < parseFloat(bPurchase)) {
        return -1;
      }
    });

    console.log(props.scenarios);
    setSc(props.scenarios);
    props.scenarios.sort(function (a, b) {
      if (a.key < b.key) {
        return -1;
      }
    });
    console.log(props.scenarios);
    setSc(props.scenarios);
    */
  }, [props.scenarios]);
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-blueGray-700">
                Clicks & Views
              </h3>
            </div>
            <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
              <Link to="/admin/users">
                <button
                  className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                >
                  Back to Users
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  onClick={() => sortString()}
                  className="pointer px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                >
                  Scenario name {sorting.key.symbol}
                </th>
                <th
                  onClick={() => sortOther("views")}
                  className="pointer px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                >
                  Views {sorting.views.symbol}
                </th>
                <th
                  onClick={() => sortOther("clicks")}
                  className="pointer px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                >
                  Clicks {sorting.clicks.symbol}
                </th>
                <th
                  onClick={() => sortOther("aClicks")}
                  className="pointer px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                >
                  Assisted Purchase {sorting.aClicks.symbol}
                </th>
                <th
                  onClick={() => sortPurchase()}
                  className="pointer px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                >
                  Assisted Revenue {sorting.purchases.symbol}
                </th>
              </tr>
            </thead>
            <tbody>
              {sc.length > 0 &&
                sc.map((d, index) => (
                  <tr key={index}>
                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                      {d.key}
                    </th>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {parseInt(d.views || 0).toLocaleString() || 0}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {parseInt(d.clicks || 0).toLocaleString() || 0}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {parseInt(d.aClicks || 0).toLocaleString() || 0}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {d.purchases &&
                        currencyFormat(
                          d.purchases.reduce(
                            (partialSum, a) => partialSum + a,
                            0
                          )
                        )}
                      &nbsp;{props.currency}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
