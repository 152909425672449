import api from "./api";

const create = (
  productName,
  searchType,
  merchantId,
  starSelector,
  starPosition,
  commentsSelector,
  commentsPosition,
  viewType,
  commentsContent,
  commentContent,
  commentCustomer,
  commentDate,
  comment,
  active,
  owner
) => {
  return api.post("/trendyol-comments", {
    productName,
    searchType,
    merchantId,
    starSelector,
    starPosition,
    commentsSelector,
    commentsPosition,
    viewType,
    commentsContent,
    commentContent,
    commentCustomer,
    commentDate,
    comment,
    active,
    owner,
  });
};

const update = (
  productName,
  searchType,
  merchantId,
  starSelector,
  starPosition,
  commentsSelector,
  commentsPosition,
  viewType,
  commentsContent,
  commentContent,
  commentCustomer,
  commentDate,
  comment,
  active,
  owner,
  id
) => {
  return api.patch(`/trendyol-comments/${owner}`, {
    productName,
    searchType,
    merchantId,
    starSelector,
    starPosition,
    commentsSelector,
    commentsPosition,
    viewType,
    commentsContent,
    commentContent,
    commentCustomer,
    commentDate,
    comment,
    active,
    owner,
    id,
  });
};
const updateActive = (active, owner, id) => {
  return api.patch(`/trendyol-comments/status/${owner}`, {
    active,
    id,
  });
};
const get = (owner) => {
  return api.get(`/trendyol-comments/${owner}`);
};
const getAll = (limit, page, sortBy, active, company, isEmailVerified) => {
  return api.get(`/trendyol-comments`, {
    params: { limit, page, sortBy, active, company, isEmailVerified },
  });
};
const TrendyolCommentsService = {
  create,
  update,
  get,
  getAll,
  updateActive,
};

export default TrendyolCommentsService;
