import api from "./api";

const create = (urls, urlAllowOrBlock, active, owner) => {
  return api.post("/snow-effect", {
    urls,
    urlAllowOrBlock,
    active,
    owner,
  });
};

const update = (urls, urlAllowOrBlock, active, owner, id) => {
  return api.patch(`/snow-effect/${owner}`, {
    urls,
    urlAllowOrBlock,
    active,
    owner,
    id,
  });
};
const updateActive = (active, owner, id) => {
  return api.patch(`/snow-effect/status/${owner}`, {
    active,
    id,
  });
};
const get = (owner) => {
  return api.get(`/snow-effect/${owner}`);
};
const getAll = (limit, page, sortBy, active, company, isEmailVerified) => {
  return api.get(`/snow-effect`, {
    params: { limit, page, sortBy, active, company, isEmailVerified },
  });
};
const Services = {
  create,
  update,
  get,
  getAll,
  updateActive,
};

export default Services;
