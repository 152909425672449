import { API, CDN } from "./assets/services.js";
//import css from "./style.css" assert { type: "css" };
export const trendyolComments = (doc, props) => {
  //doc.contentWindow.document.adoptedStyleSheets = [...doc.contentWindow.document.adoptedStyleSheets, css];

  const css = doc.contentWindow.document.createElement("link");
  css.href = CDN + "/src/scenarios/trendyol-comment/style.css";
  css.rel = "stylesheet";
  css.type = "text/css";
  css.media = "all";
  doc.contentWindow.document.head.appendChild(css);
  if (
    doc.contentWindow.document.querySelector(
      ".iyosa-stars,.iyosa-comments-container"
    )
  ) {
    doc.contentWindow.document.querySelector(".iyosa-stars").remove();
    doc.contentWindow.document
      .querySelector(".iyosa-comments-container")
      .remove();
  }

  const productName = doc.contentWindow.document.querySelector(
    props.productName
  )
    ? doc.contentWindow.document
        .querySelector(props.productName)
        .innerText.trim()
    : "";
  const url = new URL(
    API +
      "/trendyolcomments/" +
      props.merchantId +
      "?" +
      new URLSearchParams({
        productName: productName,
        searchType: props.searchType || "",
      })
  );
  if (productName.length > 0) {
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        if (parseInt(data.ratingCount) > 0) commentsCreator(data, props, doc);
      });
  }
};

const commentsCreator = (comments, props, doc) => {
  if (comments.length === 0) return false;
  let selectorForComments = doc.contentWindow.document.querySelector(
    props.commentsSelector
  );
  let selectorForStars = doc.contentWindow.document.querySelector(
    props.starSelector
  );
  let rating = doc.contentWindow.document.createElement("div");
  rating.className = "iyosa-stars";
  rating.style.cssText = `--rating: ${comments.rating}; --star-size: 20px;`;
  let ratingCount = doc.contentWindow.document.createElement("small");
  ratingCount.textContent = `(${comments.ratingCount}) Değerlendirme`;
  ratingCount.style.fontSize = "12px";
  rating.appendChild(ratingCount);
  let anchorRating = doc.contentWindow.document.createElement("a");
  anchorRating.href = "#iyosa-comments";
  anchorRating.appendChild(rating);

  let container = doc.contentWindow.document.createElement("div");
  container.className = "iyosa-comments-container";
  container.id = "iyosa-comments";

  let containerTitle = doc.contentWindow.document.createElement("div");
  containerTitle.className = "iyosa-comments-title";
  containerTitle.textContent = "Ürün Yorumları";

  let containerHeader = `
        <div class="iyosa-comments-header">
          <div class="iyosa-comments-title">Ürün Yorumları</div>
          <div class="iyosa-comments-alt">
            <div class="iyosa-alt-stars" style="--rating: ${
              comments.rating
            } ; --star-size: 25px;">
              <div class="iyosa-alt-stars-rating">${parseFloat(
                comments.rating
              ).toFixed(1)}</div>
            </div>
            <div class="iyosa-comments-counts">
              ${comments.ratingCount} Değerlendirme |${" "}
              ${comments.commentCount} Yorum
            </div>
            <div style="width: 100%;">
            </div>
          </div>
        </div>
        `;

  //container.appendChild(containerTitle);
  container.insertAdjacentHTML("afterbegin", containerHeader);
  const comments_article = doc.contentWindow.document.createElement("div");
  comments_article.style.maxHeight = "350px";
  comments_article.style.overflowY = "scroll";
  if (props.viewType === "seperate") {
    selectorForStars.insertAdjacentElement(props.starPosition, anchorRating);
    for (let i = 0; i < comments.comments.length; i++) {
      const element = comments.comments[i];

      let article = doc.contentWindow.document.createElement("article");
      article.className = "iyosa-comments-article";

      let star = doc.contentWindow.document.createElement("div");
      star.className = "iyosa-stars";
      star.style.cssText = `--rating: ${element.rate}; --star-size: 20px;`;

      let comment = doc.contentWindow.document.createElement("div");
      comment.className = "iyosa-comments-comment";
      comment.textContent = element.comment;

      let nameAndDate = doc.contentWindow.document.createElement("div");
      nameAndDate.className = "iyosa-comments-bottom";
      nameAndDate.textContent = element.customer + " | " + element.date;

      let seperator = doc.contentWindow.document.createElement("div");
      seperator.className = "iyosa-comments-seperator";
      article.appendChild(star);
      article.appendChild(comment);
      article.appendChild(nameAndDate);
      if (element.rate >= 4) {
        comments_article.appendChild(article);
        comments_article.appendChild(seperator);
      }
    }
    container.appendChild(comments_article);
    selectorForComments.insertAdjacentElement(
      props.commentsPosition,
      container
    );
  } else if (props.viewType === "compound") {
    const webSiteCommentsContent = doc.contentWindow.document.querySelector(
      props.commentsContent
    ); //"#root > div > div:nth-child(1) > div > div > div.col-md-6.col-md-6.py-5 > div.comments-content"
    for (let i = 0; i < comments.comments.length; i++) {
      const element = comments.comments[i];
      const webSiteCommentContent = webSiteCommentsContent
        .querySelector(props.commentContent)
        .cloneNode(true); //".comment-content"
      webSiteCommentContent.querySelector(props.commentCustomer).textContent =
        element.customer; //"div:nth-child(1) > div:nth-child(1) > div:nth-child(1)"
      webSiteCommentContent.querySelector(props.commentDate).textContent =
        element.date; //"div:nth-child(1) > div:nth-child(1) > div:nth-child(2)"
      webSiteCommentContent.querySelector(props.comment).textContent =
        element.comment; //"div:nth-child(1) > div:nth-child(3)"
      webSiteCommentsContent.appendChild(webSiteCommentContent);
    }
  }
};
