import React from "react";

// components

import CardOpportunities from "components/AdminCards/CardOpportunities";

export default function Opportunities() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <CardOpportunities />
        </div>
      </div>
    </>
  );
}
