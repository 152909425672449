import React, { useState } from "react";

const Accordion = ({ title, children }) => {
  const [isActive, setIsActive] = useState(true);

  return (
    <div className="accordion-item  ">
      <div
        className="cursor-pointer flex justify-between px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
        onClick={() => setIsActive(!isActive)}
      >
        <div>Question -> {title}</div>
        <div>{isActive ? "-" : "+"}</div>
      </div>
      {isActive && <div className="accordion-content">{children}</div>}
    </div>
  );
};

export default Accordion;
