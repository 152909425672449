import ActiveSwitch from "components/Inputs/ActiveSwitch";
import { CardLoading } from "components/Inputs/Loading";
import PreviewIframe from "components/Inputs/PreviewIframe";
import { alerts } from "helpers/Alerts";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import Services from "services/previewoptions.service";

// components
import { h6style } from "components/Cards/style";

export default function CardPreviewOptions(props) {
  const [id, setId] = useState("");
  const [iframeHtmlHome, setIframeHtmlHome] = useState("Home Page");
  const [iframeHtmlCollection, setIframeHtmlCollection] =
    useState("Collection");
  const [iframeHtmlProductDetail, setIframeHtmlProductDetail] =
    useState("Product Detail");
  const [iframeHtmlCart, setIframeHtmlCart] = useState("Cart");
  const [message, setMessage] = useState("");
  const [active, setActive] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const mountedRef = useRef(true);
  const { owner, company } = useParams();

  useEffect(() => {
    Services.get(owner).then(
      (res) => {
        if (!mountedRef.current) return null;
        setId(res.data.id);
        setIframeHtmlHome(res.data.iframeHtmlHome);
        setIframeHtmlCollection(res.data.iframeHtmlCollection);
        setIframeHtmlProductDetail(res.data.iframeHtmlProductDetail);
        setIframeHtmlCart(res.data.iframeHtmlCart);
        setActive(res.data.active);
        setDisabled(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        Services.create(
          iframeHtmlHome,
          iframeHtmlCollection,
          iframeHtmlProductDetail,
          iframeHtmlCart,
          false,
          owner
        ).then((res) => {
          setDisabled(false);
          setMessage("Created!");
          setId(res.data.id);
        });
      }
    );
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);

  function submit(e) {
    e.preventDefault();
    setDisabled(true);
    Services.update(
      iframeHtmlHome,
      iframeHtmlCollection,
      iframeHtmlProductDetail,
      iframeHtmlCart,
      active,
      owner,
      id
    ).then(
      (res) => {
        setDisabled(false);
        setMessage(alerts.positive);
        setTimeout(() => {
          setMessage("");
        }, 2000);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setDisabled(false);
      }
    );
  }
  return (
    <>
      <CardLoading isShow={disabled} />
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <h6 className="text-blueGray-700 text-xl font-bold" style={h6style}>
          {company}
        </h6>
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold" style={h6style}>
              <ActiveSwitch
                disabled={disabled}
                onColor="#0ea5e9"
                checked={active}
                handleToggle={(e) => setActive(e.target.checked)}
              />{" "}
              Preview Options
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={submit}>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Settings
            </h6>

            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4 py-4">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Home Page
                </label>
                <PreviewIframe
                  onChange={(e) => setIframeHtmlHome(e.target.value)}
                  srcDoc={iframeHtmlHome}
                />
              </div>
              <div className="w-full lg:w-12/12 px-4 py-4">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Collection
                </label>
                <PreviewIframe
                  onChange={(e) => setIframeHtmlCollection(e.target.value)}
                  srcDoc={iframeHtmlCollection}
                />
              </div>
              <div className="w-full lg:w-12/12 px-4 py-4">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Product Detail
                </label>
                <PreviewIframe
                  onChange={(e) => setIframeHtmlProductDetail(e.target.value)}
                  srcDoc={iframeHtmlProductDetail}
                />
              </div>
              <div className="w-full lg:w-12/12 px-4 py-4">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Cart
                </label>
                <PreviewIframe
                  onChange={(e) => setIframeHtmlCart(e.target.value)}
                  srcDoc={iframeHtmlCart}
                />
              </div>
            </div>

            <button
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="submit"
              disabled={disabled}
            >
              Save
            </button>
            {message}
          </form>
        </div>
      </div>
    </>
  );
}
