import React from "react";

// components

import CardOpeningPopup from "components/Cards/CardOpeningPopup";

export default function OpeningPopup() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <CardOpeningPopup />
        </div>
      </div>
    </>
  );
}
