import api from "./api";

const createOpportunity = (
  opportunity,
  menuType,
  title,
  color,
  bgColor,
  closeColor,
  titleFontSize,
  btnBgColor,
  btnDisplay,
  btnText,
  btnFontSize,
  bottomDistance,
  selector,
  position,
  mobileSelector,
  mobilePosition,
  iconsWidth,
  badgeWidthHeight,
  badgeFontSize,
  badgeTop,
  badgeRight,
  listLeft,
  margin,
  marginForMobile,
  mobileTop,
  iconColor,
  active,
  owner
) => {
  return api.post("/opportunity", {
    opportunity,
    menuType,
    title,
    color,
    bgColor,
    closeColor,
    titleFontSize,
    btnBgColor,
    btnDisplay,
    btnText,
    btnFontSize,
    bottomDistance,
    selector,
    position,
    mobileSelector,
    mobilePosition,
    iconsWidth,
    badgeWidthHeight,
    badgeFontSize,
    badgeTop,
    badgeRight,
    listLeft,
    margin,
    marginForMobile,
    mobileTop,
    iconColor,
    active,
    owner,
  });
};

const updateOpportunity = (
  opportunity,
  menuType,
  title,
  color,
  bgColor,
  closeColor,
  titleFontSize,
  btnBgColor,
  btnDisplay,
  btnText,
  btnFontSize,
  bottomDistance,
  selector,
  position,
  mobileSelector,
  mobilePosition,
  iconsWidth,
  badgeWidthHeight,
  badgeFontSize,
  badgeTop,
  badgeRight,
  listLeft,
  margin,
  marginForMobile,
  mobileTop,
  iconColor,
  active,
  owner,
  id
) => {
  return api.patch(`/opportunity/${owner}`, {
    opportunity,
    menuType,
    title,
    color,
    bgColor,
    closeColor,
    titleFontSize,
    btnBgColor,
    btnDisplay,
    btnText,
    btnFontSize,
    bottomDistance,
    selector,
    position,
    mobileSelector,
    mobilePosition,
    iconsWidth,
    badgeWidthHeight,
    badgeFontSize,
    badgeTop,
    badgeRight,
    listLeft,
    margin,
    marginForMobile,
    mobileTop,
    iconColor,
    active,
    owner,
    id,
  });
};
const updateActive = (active, owner, id) => {
  return api.patch(`/opportunity/status/${owner}`, {
    active,
    id,
  });
};
const getOpportunity = (owner) => {
  return api.get(`/opportunity/${owner}`);
};
const getAll = (limit, page, sortBy, active, company, isEmailVerified) => {
  return api.get(`/opportunity`, {
    params: { limit, page, sortBy, active, company, isEmailVerified },
  });
};
const OpportunityService = {
  createOpportunity,
  updateOpportunity,
  getOpportunity,
  getAll,
  updateActive,
};

export default OpportunityService;
